import React, { useState } from 'react';
import styles from '../components.module.scss';
import clsx from 'clsx';
import { IconButton, Button } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddSignaturePopup from '../AddSignaturePopup/AddSignaturePopup';
import { useSelector } from 'react-redux';
import { selectCurrentBillOfLadingData } from '../../../selectors';
import { useUserConfig } from '../../../../../../hooks/useUserConfig';
import { isElementAvailable } from '../../helpers';

export default function SignaturePicture({ onUpdate, onDelete, popupPreData, signature }) {
  const currentBillOfLading = useSelector(selectCurrentBillOfLadingData());
  const { isAdminUser, isOperationsManagerUser, isFieldTechUser, isEntityCreatorUser } =
    useUserConfig();

  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState({});

  const handleEditClick = () => {
    setPopupData(popupPreData);
    setOpen(true);
  };

  const handleDeleteClick = () => onDelete(signature.param);

  const handleApply = (data) => onUpdate(data);

  const isCustomBoL = !currentBillOfLading?.picklist?.id && !currentBillOfLading?.leavelist?.id;

  const isRemoveAvailable = isElementAvailable(currentBillOfLading, {
    isAdminUser,
    isOperationsManagerUser,
    isFieldTechUser,
    isEntityCreatorUser
  });

  const isEditAvailable = isCustomBoL ? true : isRemoveAvailable;

  return (
    <>
      {open && (
        <AddSignaturePopup data={popupData} open={open} setOpen={setOpen} onApply={handleApply} />
      )}
      <div className={clsx(styles.details__block_item, styles.sign_vertical)}>
        {signature?.value && (
          <div className={styles.image}>
            {isRemoveAvailable && (
              <IconButton onClick={handleDeleteClick} className={styles.cross}>
                <CancelOutlinedIcon />
              </IconButton>
            )}
            <img src={signature.value.link} alt="image" />
          </div>
        )}

        {isEditAvailable && (
          <Button className={styles.signature} onClick={handleEditClick}>
            <span>{signature?.value?.id ? 'Edit signature' : 'Add signature'}</span>
          </Button>
        )}
      </div>
    </>
  );
}
