import React, { useEffect } from 'react';

import AssetDetailsWrapper from './components/AssetDetailsWrapper';
import AssetDetailsHeader from './components/Header/Header';
import AssetDetails from './components/AssetDetails/AssetDetails';
import AssetTables from './components/AssetTables/AssetTables';

import { useUserConfig } from 'hooks/useUserConfig';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useParams } from 'react-router-dom';

import {
  ASSET_CLOSED_TICKETS_TABLE_PARAMETER,
  ASSET_INSPECTION_HISTORY_TABLE_PARAMETER,
  ASSET_LOCATION_HISTORY_TABLE_PARAMETER,
  ASSET_LOOSE_ITEMS_TABLE_PARAMETER,
  ASSET_OPENED_TICKETS_TABLE_PARAMETER,
  ASSET_VERIFICATION_HISTORY_TABLE_PARAMETER
} from 'constants/configTableConstants';

export default function AssetDetailed() {
  const { id } = useParams();

  const { isConfigReceived, getTableLimit } = useUserConfig();

  const selector = useAssetSelector();
  const { currentAsset } = selector;
  const {
    getLocationHistory,
    getInspectionHistory,
    getVerificationHistory,
    getOpenedTickets,
    getClosedTickets,
    getLooseItems
  } = useAssetActions();

  const getTicketQuery = (tableParam, key) => ({
    filters: { assetIds: [+id], shouldIncludeDeletedAssets: !!currentAsset.deleted },
    pagination: { ...selector[key].pagination, limit: getTableLimit(tableParam) },
    isDataReceived: false,
    sortRules: []
  });

  const getHistoryQuery = (tableParam, key) => ({
    filters: { assetId: [+id] },
    pagination: { ...selector[key].pagination, limit: getTableLimit(tableParam) },
    isDataReceived: false,
    sortRules: []
  });

  const getLooseItemsQuery = (tableParam, key) => ({
    filters: { assetIds: [+id] },
    pagination: { ...selector[key].pagination, limit: getTableLimit(tableParam) },
    isDataReceived: false,
    sortRules: []
  });

  useEffect(() => {
    if (!isConfigReceived) return;
    if (!currentAsset?.id) return;
    getLocationHistory(
      getHistoryQuery(ASSET_LOCATION_HISTORY_TABLE_PARAMETER, 'locationHistoryFilter')
    );
    getInspectionHistory(
      getHistoryQuery(ASSET_INSPECTION_HISTORY_TABLE_PARAMETER, 'inspectionHistoryFilter')
    );
    getVerificationHistory(
      getHistoryQuery(ASSET_VERIFICATION_HISTORY_TABLE_PARAMETER, 'verificationHistoryFilter')
    );
    getOpenedTickets(getTicketQuery(ASSET_OPENED_TICKETS_TABLE_PARAMETER, 'openedTicketsFilter'));
    getClosedTickets(getTicketQuery(ASSET_CLOSED_TICKETS_TABLE_PARAMETER, 'closedTicketsFilter'));
    getLooseItems(getLooseItemsQuery(ASSET_LOOSE_ITEMS_TABLE_PARAMETER, 'looseItemsFilter'));
  }, [currentAsset, isConfigReceived]);

  return (
    <AssetDetailsWrapper>
      <AssetDetailsHeader />
      <AssetDetails />
      <AssetTables />
    </AssetDetailsWrapper>
  );
}
