import React, { useEffect } from 'react';

import { useBillOfLadingLogsActions } from 'hooks/BillOfLadingLogs';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useHistory } from 'react-router-dom';

import { ROOT_PATH } from 'constants/routeConstants';

import Wrapper from './Wrapper';
import FormManager from './FormManager';
import { defaultForm } from './helpers';

export function BillOfLadingLogEdit() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const { id } = useParams();

  const methods = useForm({
    defaultValues: {
      items: [{ ...defaultForm }]
    },
    mode: 'onChange'
  });
  const { reset } = methods;

  const { getCreationFormAction, getLogAction, clearStateAction } = useBillOfLadingLogsActions();

  useEffect(() => {
    if (id) {
      getLogAction({ id }).then((res) => reset({ items: [res] }));
    }
  }, [id]);

  useEffect(() => {
    if (isMobile) {
      history.push(ROOT_PATH);
    }
  }, [isMobile]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <FormManager />
      </Wrapper>
    </FormProvider>
  );
}
