import api from '../api';
import {
  CLEAR_BATCH_ITEMS_UPDATES_STATE,
  SET_BATCH_ITEMS_UPDATES_SELECTED_TAB,
  SET_BATCH_ITEMS_UPDATES_CREATION_FORM
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { SUCCESS_MOVED_LOOSE_ITEMS } from 'constants/infoSnackbarData';

export const getCategories = () => () =>
  api.batchItemUpdates
    .getCategoriesApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getCustomFields = () => () =>
  api.batchItemUpdates
    .getCustomFieldsApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLooseItems = () => () =>
  api.batchItemUpdates
    .getLooseItemsApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLooseItemsLocations = (query) => () =>
  api.batchItemUpdates
    .getLooseItemsLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLocationsWithSublocations = (q) => () =>
  api.batchItemUpdates
    .getLocationsWithSublocationsApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const updateLooseItems = (data) => () =>
  api.batchItemUpdates
    .updateLooseItemsApi(data)
    .then((res) => res)
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const moveLooseItems = (data) => () =>
  api.batchItemUpdates
    .moveLooseItemsApi(data)
    .then((res) => {
      enqueueSuccessSnackbar(SUCCESS_MOVED_LOOSE_ITEMS);
      return res;
    })
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    });

export const setCreationForm = (payload) => (dispatch) => {
  dispatch({ type: SET_BATCH_ITEMS_UPDATES_CREATION_FORM, payload });
};

export const setSelectedTab = (payload) => (dispatch) => {
  dispatch({ type: SET_BATCH_ITEMS_UPDATES_SELECTED_TAB, payload });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_BATCH_ITEMS_UPDATES_STATE });
};
