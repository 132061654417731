import React from 'react';
import styles from './LooseItemCounting.module.scss';

export function LooseItemCounting({ counts }) {
  if (!counts?.total) return null;

  const { total, slicedLooseItems } = counts;

  const statuses = [
    { key: 'Available', label: 'Available' },
    { key: 'Rented', label: 'Rented' },
    { key: 'Used in assembly', label: 'Used in assembly' },
    { key: 'In Picklist', label: 'In picklist' },
    { key: 'In Leavelist', label: 'In leavelist' },
    { key: 'Pending Approval', label: 'Pending approval' }
  ];

  const getCountByStatus = (status) =>
    slicedLooseItems?.find((el) => el?.status?.displayName === status)?.count || 0;

  return (
    <div className={styles.counts}>
      {statuses.map(({ key, label }) => (
        <div className={styles.counts__row} key={key}>
          <span>{label}:</span>
          <span className={styles.counts__number}>{getCountByStatus(key)}</span>
        </div>
      ))}
      <div className={styles.counts__row}>
        <span className={styles.counts__total}>Total:</span>
        <span className={styles.counts__number}>{total}</span>
      </div>
    </div>
  );
}
