import React, { useState } from 'react';
import styles from './Form.module.scss';

import FormSearchWithCreation from 'components/FormComponents/FormSearchWithCreation/FormSearchWithCreation';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import LooseItemFieldsContainer from 'components/FormComponents/LooseItemFieldsContainer';
import EditLooseItemCategoryPopup from 'components/ParameterPopups/EditLooseItemCategoryPopup';
import CategoryOption from 'components/ParameterPopups/EditLooseItemCategoryPopup/CategoryOption';

import { useUserConfig } from 'hooks/useUserConfig';
import { useBatchItemUpdatesActions, useBatchItemUpdatesSelector } from 'hooks/BatchItemUpdates';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import { AT_LEAST_ONE_FIELD_ERROR_NAME, CONNECTED_FIELD_ERROR_NAMES } from '../../../helpers';

export function Form() {
  const isMobile = useMobileViewport();
  const { formState, clearErrors, setValue, getValues } = useFormContext();
  const { errors } = formState;

  const { isAdminUser, isOperationsManagerUser } = useUserConfig();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const { getCreationFormAction } = useBatchItemUpdatesActions();
  const { creationForm } = useBatchItemUpdatesSelector();
  const { looseItems, categories, customFields } = creationForm;

  const checkMandatoryFields = () => {
    if (errors?.[AT_LEAST_ONE_FIELD_ERROR_NAME]?.message) {
      CONNECTED_FIELD_ERROR_NAMES.forEach((name) => clearErrors(name));
    }
  };

  const getLabel = (option) => option.name;

  const handleEditOption = (option) => {
    setData(option);
    setOpen(true);
  };

  const getOptionComponent = (option, selected) => (
    <CategoryOption option={option} selected={selected} onEdit={handleEditOption} />
  );

  const handleUpdateCategory = ({ action, category }) => {
    getCreationFormAction();
    const isCategorySelected = !!getValues('looseItemCategories').find(
      ({ id }) => id === category.id
    )?.id;
    if (!isCategorySelected) return;
    const newCategoryValues = getValues('looseItemCategories').filter(
      ({ id }) => id !== category.id
    );
    action === 'UPDATE' && newCategoryValues.push(category);
    setValue('looseItemCategories', newCategoryValues);
  };

  return (
    <div className={styles.form}>
      {open && (
        <EditLooseItemCategoryPopup
          open={open}
          setOpen={setOpen}
          data={data}
          onUpdate={handleUpdateCategory}
        />
      )}
      <div className={styles.form__row}>
        <label>Loose Items*</label>
        <FormAutoMultiSelect
          name="looseItems"
          menuItems={looseItems || []}
          options={{
            label: 'name',
            disableByObjectTracker: false,
            disableLabel: true,
            hideTags: true
          }}
        />
      </div>
      <FormChipsContainer fieldName="looseItems" />
      <div className={clsx(styles.form__row, styles.selector)}>
        <label>Add Category to Loose items</label>
        <FormSearchWithCreation
          name="looseItemCategories"
          menuItems={categories || []}
          options={{
            customLabel: 'name',
            max: 100,
            getLabel: getLabel,
            tipLabel: 'Select from the list or write to add a new category',
            disableErrorMessage: true,
            extraAction: checkMandatoryFields,
            OptionComponent: getOptionComponent,
            duplicateErrorMessage: 'The category with such name already exists',
            disableCreation: !isAdminUser && !isOperationsManagerUser
          }}
        />
      </div>
      <FormChipsContainer fieldName="looseItemCategories" />
      <div className={clsx(styles.form__row, styles.selector)}>
        <label>Add Field to Loose items</label>
        <FormSearchWithCreation
          name="customFields"
          menuItems={customFields || []}
          options={{
            customLabel: 'name',
            max: 50,
            getLabel: getLabel,
            tipLabel: 'Select from the list or write to add a new field',
            disableErrorMessage: true,
            extraAction: checkMandatoryFields
          }}
        />
      </div>
      <div className={styles.form__row}>
        {!isMobile && <label></label>}
        <LooseItemFieldsContainer />
      </div>
    </div>
  );
}
