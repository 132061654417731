import React from 'react';
import styles from './StatusButton.module.scss';
import { Button } from '@material-ui/core';
import {
  isDecisionMakerUser,
  isOperationsManagerUser,
  isResponsibleForAccountUser
} from 'helpers/AppHelpers';
import clsx from 'clsx';

const statuses = [
  { name: 'Pending', displayName: 'Pending', buttonName: 'Approve request' },
  { name: 'Approved', displayName: 'Approved', buttonName: 'In delivery' },
  {
    name: 'InDelivery',
    displayName: 'In delivery',
    buttonName: 'Close request'
  },
  { name: 'Closed', displayName: 'Closed', buttonName: '' },
  {
    name: 'PendingСancellation',
    displayName: 'Pending cancellation',
    buttonName: 'Deny cancelling'
  }
];

function StatusButton({
  statusName,
  beforeCancelStatus,
  updateStatus,
  userRole,
  requestOrderRole,
  onApproveCancellingClick,
  onReopenClick,
  isRequestOrderDeleted,
  shouldDisable
}) {
  const getButtonName = () => statuses.find(({ name }) => name === statusName).buttonName;

  const onChangeStatus = () => {
    const currentStatusIndex = statuses.findIndex(({ name }) => name === statusName);
    const status = statuses[currentStatusIndex + 1] || beforeCancelStatus;
    updateStatus(status);
  };
  const handleStatus = (index) => updateStatus(statuses[index]);
  const handleApproveCancelling = () => onApproveCancellingClick(statuses[3]);
  const reOpenClick = () => onReopenClick(statuses[0]);

  const isButtonAvailable = () => {
    switch (statusName) {
      case 'Pending':
        return isDecisionMakerUser(requestOrderRole);
      case 'Approved':
        return (
          isDecisionMakerUser(requestOrderRole) || isResponsibleForAccountUser(requestOrderRole)
        );
      case 'InDelivery':
        return (
          isDecisionMakerUser(requestOrderRole) ||
          isResponsibleForAccountUser(requestOrderRole) ||
          isOperationsManagerUser(userRole)
        );
      case 'PendingСancellation':
        return isDecisionMakerUser(requestOrderRole);
      case 'Closed':
      default:
        return false;
    }
  };

  const isApproveAvailable = () =>
    isRequestOrderDeleted()
      ? false
      : statusName === 'PendingСancellation' && isDecisionMakerUser(requestOrderRole);
  const isRequestForApprovalAvailable = () =>
    isRequestOrderDeleted()
      ? false
      : statusName === 'Pending' && isResponsibleForAccountUser(requestOrderRole);

  return (
    <section className={styles.buttonSection}>
      {isApproveAvailable() && (
        <Button className={styles.approveButton} onClick={handleApproveCancelling}>
          <span>Approve cancelling</span>
        </Button>
      )}
      {!isRequestOrderDeleted() && (
        <>
          {isButtonAvailable() && (
            <Button
              className={clsx(
                styles.statusButton,
                shouldDisable && statusName === 'InDelivery' && styles.disabled
              )}
              onClick={onChangeStatus}>
              <span>{getButtonName()}</span>
            </Button>
          )}
        </>
      )}
      {isRequestForApprovalAvailable() && (
        <Button className={styles.requestForApprovalButton} onClick={() => handleStatus(1)}>
          <span>Request for approval</span>
        </Button>
      )}

      {isDecisionMakerUser(requestOrderRole) && statusName === 'Closed' && (
        <Button className={styles.reopen} onClick={reOpenClick}>
          <span>Reopen</span>
        </Button>
      )}
    </section>
  );
}

export default StatusButton;
