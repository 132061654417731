import * as routes from 'constants/routeConstants';
import DashboardIcon from 'assets/images/dashboards.svg';
import AssetSummaryIcon from 'assets/images/summaryAsset.svg';
import LooseItemsIcon from 'assets/images/loostItems.svg';
import BatchUpdatesIcon from 'assets/images/batchUpdates.svg';
import AssetTicketsIcon from 'assets/images/ticketsAsset.svg';
import ReportsIcon from 'assets/images/reports.svg';
import LocationManagementIcon from 'assets/images/locationManagement.svg';
import PrefixListIcon from 'assets/images/prefixList.svg';
import UserManagementIcon from 'assets/images/userManagement.svg';
import PicklistIcon from 'assets/images/picklist.svg';
import TeamMemberSummaryIcon from 'assets/images/teamMembers.svg';
import RequestOrderIcon from 'assets/images/requestOrderIcon.svg';

import { checkSidebarCollapseAccess, SIDEBAR_TITLES } from 'helpers/AccessCheck';

const displayingOrder = [
  SIDEBAR_TITLES.ASSET_DASHBOARDS.MAIN,
  SIDEBAR_TITLES.ASSET.MAIN,
  SIDEBAR_TITLES.LOOSE_ITEMS.MAIN,
  SIDEBAR_TITLES.REQUEST_ORDERS.MAIN,
  SIDEBAR_TITLES.BATCH_UPDATES.MAIN,
  SIDEBAR_TITLES.ASSET_TICKETS.MAIN,
  SIDEBAR_TITLES.REPORTS.MAIN,
  SIDEBAR_TITLES.TEAM_SCHEDULE.MAIN,
  SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.MAIN,
  SIDEBAR_TITLES.LOCATION_MANAGEMENT.MAIN,
  SIDEBAR_TITLES.PREFIX_LIST.MAIN,
  SIDEBAR_TITLES.USER_MANAGEMENT.MAIN
];

const listItems = [
  {
    displayName: SIDEBAR_TITLES.ASSET_DASHBOARDS.MAIN,
    icon: DashboardIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.ASSET_DASHBOARDS.GENERAL,
        to: routes.DASHBOARD_PATH,
        paths: [routes.DASHBOARD_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.ASSET_DASHBOARDS.VERIFICATION,
        to: routes.ASSETS_VERIFICATION_AND_INSPECTION_DASHBOARDS_PATH,
        paths: [routes.ASSETS_VERIFICATION_AND_INSPECTION_DASHBOARDS_PATH]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.ASSET.MAIN,
    icon: AssetSummaryIcon,
    to: routes.ASSETS_LIST_PATH,
    paths: [routes.ASSETS_LIST_PATH, routes.ASSET_DETAILED_PATH, routes.ASSET_EDIT_PATH]
  },
  {
    displayName: SIDEBAR_TITLES.LOOSE_ITEMS.MAIN,
    icon: LooseItemsIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.LOOSE_ITEMS.DASHBOARD,
        to: routes.LOOSE_ITEM_DASHBOARD,
        paths: [routes.LOOSE_ITEM_DASHBOARD]
      },
      {
        displayName: SIDEBAR_TITLES.LOOSE_ITEMS.LIST,
        to: routes.LOOSE_ITEMS_LIST_PATH,
        paths: [
          routes.LOOSE_ITEMS_LIST_PATH,
          routes.LOOSE_ITEM_DETAILED_PATH,
          routes.LOOSE_ITEM_EDIT_PATH
        ]
      },
      {
        displayName: SIDEBAR_TITLES.LOOSE_ITEMS.BOM_CONFIGURATION,
        to: routes.BOM_CONFIGURATIONS_LIST_PATH,
        paths: [
          routes.BOM_CONFIGURATIONS_LIST_PATH,
          routes.BOM_CONFIGURATION_DETAILED_PATH,
          routes.BOM_CONFIGURATION_EDIT_PATH
        ]
      },
      {
        displayName: SIDEBAR_TITLES.LOOSE_ITEMS.ASSEMBLED_BOM,
        to: routes.ASSEMBLED_BOM_LIST_PATH,
        paths: [
          routes.ASSEMBLED_BOM_LIST_PATH,
          routes.ASSEMBLED_BOM_DETAILED_PATH,
          routes.ASSEMBLE_EDIT_PATH
        ]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.REQUEST_ORDERS.MAIN,
    icon: RequestOrderIcon,
    to: routes.REQUEST_ORDER_SUMMARY_PATH,
    paths: [
      routes.REQUEST_ORDER_SUMMARY_PATH,
      routes.REQUEST_ORDER_EDIT_PATH,
      routes.REQUEST_ORDER_DETAILED_PATH
    ]
  },
  {
    displayName: SIDEBAR_TITLES.BATCH_UPDATES.MAIN,
    icon: BatchUpdatesIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.BATCH_UPDATES.ASSET,
        to: routes.BATCH_ASSET_UPDATES_PATH,
        paths: [routes.BATCH_ASSET_UPDATES_PATH, routes.ADD_ASSET_TICKET_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.BATCH_UPDATES.LOOSE_ITEM,
        to: routes.BATCH_LOOSE_ITEM_UPDATES_PATH,
        paths: [routes.BATCH_LOOSE_ITEM_UPDATES_PATH]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.ASSET_TICKETS.MAIN,
    icon: AssetTicketsIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.ASSET_TICKETS.SUMMARY,
        to: routes.TICKETS_LIST_PATH,
        paths: [routes.TICKETS_LIST_PATH, routes.TICKET_DETAILED_PATH, routes.TICKET_EDIT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.ASSET_TICKETS.ADD,
        to: routes.ADD_ASSET_TICKET_PATH,
        paths: []
      },
      {
        displayName: SIDEBAR_TITLES.ASSET_TICKETS.TYPES,
        to: routes.TICKET_TYPES_PATH,
        paths: [routes.TICKET_TYPES_PATH, routes.TICKET_TYPE_EDIT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.ASSET_TICKETS.ROOT_CAUSES,
        to: routes.TICKET_ROOT_CAUSES_PATH,
        paths: [routes.TICKET_ROOT_CAUSES_PATH, routes.TICKET_ROOT_CAUSE_EDIT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.ASSET_TICKETS.DASHBOARD,
        to: routes.TICKETS_DASHBOARD_PATH,
        paths: [routes.TICKETS_DASHBOARD_PATH]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.REPORTS.MAIN,
    icon: ReportsIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.REPORTS.LOCATION,
        to: routes.LOCATION_REPORT_LIST_PATH,
        paths: [routes.LOCATION_REPORT_LIST_PATH, routes.LOCATION_REPORT_EDIT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.REPORTS.ASSET,
        to: routes.ASSET_REPORT_LIST_PATH,
        paths: [routes.ASSET_REPORT_LIST_PATH, routes.ASSET_REPORT_EDIT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.REPORTS.GENERAL,
        to: routes.GENERAL_REPORT_LIST_PATH,
        paths: [routes.GENERAL_REPORT_LIST_PATH, routes.GENERAL_REPORT_EDIT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.REPORTS.OVERVIEW,
        to: routes.OVERVIEW_REPORT_PATH,
        paths: [routes.OVERVIEW_REPORT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.REPORTS.COMPARE,
        to: routes.COMPARE_REPORTS_PATH,
        paths: [routes.COMPARE_REPORTS_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.REPORTS.TEMPLATE,
        to: routes.REPORT_TEMPLATE_LIST_PATH,
        paths: [routes.REPORT_TEMPLATE_LIST_PATH, routes.REPORT_TEMPLATE_EDITOR]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.TEAM_SCHEDULE.MAIN,
    icon: TeamMemberSummaryIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.TEAM_SCHEDULE.PERSONAL_PROFILE,
        to: routes.PERSONNEL_PROFILE_PATH,
        paths: [routes.PERSONNEL_PROFILE_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.TEAM_SCHEDULE.TEAM_LIST,
        to: routes.TEAM_MEMBER_LIST_PATH,
        paths: [
          routes.TEAM_MEMBER_LIST_PATH,
          routes.TEAM_MEMBER_DETAILED_PATH,
          routes.TEAM_MEMBER_EDIT_PATH
        ]
      },
      {
        displayName: SIDEBAR_TITLES.TEAM_SCHEDULE.PROJECT_SCHEDULE,
        to: routes.TEAM_MEMBER_TRACKER_PATH,
        paths: [routes.TEAM_MEMBER_TRACKER_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.TEAM_SCHEDULE.MEMBER_DASHBOARD,
        to: routes.TEAM_MEMBER_DASHBOARD_PATH,
        paths: [routes.TEAM_MEMBER_DASHBOARD_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.TEAM_SCHEDULE.MEMBER_HISTORY,
        to: routes.TEAM_MEMBER_HISTORY_PATH,
        paths: [routes.TEAM_MEMBER_HISTORY_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.TEAM_SCHEDULE.MEMBER_LICENSES,
        to: routes.TRAININGS_AND_CERTIFICATIONS_PATH,
        paths: [routes.TRAININGS_AND_CERTIFICATIONS_PATH]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.MAIN,
    icon: PicklistIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.PICKLIST_SUMMARY,
        to: routes.PICKLIST_SUMMARY_PATH,
        paths: [
          routes.PICKLIST_SUMMARY_PATH,
          routes.PICKLIST_DETAILED_PATH,
          routes.PICKLIST_EDIT_PATH,
          routes.ADD_BOM_TO_PICKLIST_PATH
        ]
      },
      {
        displayName: SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.LEAVELIST_SUMMARY,
        to: routes.LEAVELIST_SUMMARY_PATH,
        paths: [
          routes.LEAVELIST_SUMMARY_PATH,
          routes.LEAVELIST_EDIT_PATH,
          routes.LEAVELIST_DETAILED_PATH
        ]
      },
      {
        displayName: SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.BILL_OF_LADING_SUMMARY,
        to: routes.BILL_OF_LADING_SUMMARY_PATH,
        paths: [
          routes.BILL_OF_LADING_SUMMARY_PATH,
          routes.BILL_OF_LADING_EDIT_PATH,
          routes.BILL_OF_LADING_DETAILS_PATH
        ]
      },
      {
        displayName: SIDEBAR_TITLES.PICKLIST_AND_LEAVELIST.BILL_OF_LADING_LOGS,
        to: routes.BILL_OF_LADING_LOGS_PATH,
        paths: [routes.BILL_OF_LADING_LOGS_PATH, routes.BILL_OF_LADING_LOG_EDIT_PATH]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.LOCATION_MANAGEMENT.MAIN,
    icon: LocationManagementIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.LOCATION_MANAGEMENT.ACTIVE_LOCATIONS_MAP,
        to: routes.ACTIVE_LOCATIONS_MAP_PATH,
        paths: [routes.ACTIVE_LOCATIONS_MAP_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.LOCATION_MANAGEMENT.BUILD_ROUTE_MAP,
        to: routes.BUILD_ROUTE_MAP_PATH,
        paths: [routes.BUILD_ROUTE_MAP_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.LOCATION_MANAGEMENT.SUMMARY,
        to: routes.LOCATIONS_LIST_PATH,
        paths: [
          routes.LOCATIONS_LIST_PATH,
          routes.LOCATION_DETAILED_PATH,
          routes.LOCATION_EDIT_PATH
        ]
      },
      {
        displayName: SIDEBAR_TITLES.LOCATION_MANAGEMENT.PROJECT_SUMMARY,
        to: routes.PROJECT_LIST_PATH,
        paths: [routes.PROJECT_LIST_PATH, routes.PROJECT_DETAILED_PATH, routes.PROJECT_EDIT_PATH]
      }
    ]
  },
  {
    displayName: SIDEBAR_TITLES.PREFIX_LIST.MAIN,
    icon: PrefixListIcon,
    to: routes.CATEGORY_LIST_PATH,
    paths: [
      routes.CATEGORY_LIST_PATH,
      routes.CATEGORY_DETAILED_PATH,
      routes.CATEGORY_EDIT_PATH,
      routes.PREFIX_EDIT_PATH,
      routes.PREFIX_DETAILED_PATH
    ]
  },
  {
    displayName: SIDEBAR_TITLES.USER_MANAGEMENT.MAIN,
    icon: UserManagementIcon,
    collapseItems: [
      {
        displayName: SIDEBAR_TITLES.USER_MANAGEMENT.LIST,
        to: routes.USER_LIST_PATH,
        paths: [routes.USER_LIST_PATH, routes.USER_DETAILED_PATH, routes.USER_EDIT_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.USER_MANAGEMENT.LOGS,
        to: routes.USER_LOGS_PATH,
        paths: [routes.USER_LOGS_PATH]
      },
      {
        displayName: SIDEBAR_TITLES.USER_MANAGEMENT.NOTIFICATION_CENTER,
        to: routes.NOTIFICATION_CENTER_PATH,
        paths: [routes.NOTIFICATION_CENTER_PATH]
      }
    ]
  }
];

const applyConfig = (item, conditions) => {
  const filterCollapseItems = (rules) => {
    return item.collapseItems.filter(({ displayName }) => {
      if (displayName in rules) {
        return rules[displayName];
      }
      return true;
    });
  };

  return conditions[item.displayName]
    ? {
        ...item,
        collapseItems: filterCollapseItems(conditions[item.displayName])
      }
    : item;
};

const addUniqueIds = (items) => {
  return items.map((item, index) => {
    const id = `item-${index + 1}`;

    let collapseItemsWithIds = [];
    if (item.collapseItems) {
      collapseItemsWithIds = item.collapseItems.map((collapseItem, collapseIndex) => ({
        ...collapseItem,
        id: `${id}-collapse-${collapseIndex + 1}`
      }));
    }

    return {
      ...item,
      id,
      collapseItems: collapseItemsWithIds.length ? collapseItemsWithIds : item.collapseItems
    };
  });
};

const buildBar = (userConfig, isMobile) => {
  const sorted = displayingOrder.map((name) =>
    listItems.find(({ displayName }) => displayName === name)
  );

  const collapseConditions = checkSidebarCollapseAccess({ userConfig, isMobile });

  const transformed = sorted.map((item) => applyConfig(item, collapseConditions));

  const cleared = transformed.filter((item) =>
    'collapseItems' in item ? !!item.collapseItems?.length : true
  );

  const identified = addUniqueIds(cleared);

  return identified;
};

export { buildBar, listItems };
