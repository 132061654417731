import React, { useEffect, useState } from 'react';
import styles from './UserEditForm.module.scss';

import ToggleBar from 'components/ToggleBars/ToggleBar';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormSelectInput from 'components/FormComponents/FormSelectInput/FormSelectInput';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import Separator from 'components/Separator/Separator';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';

import { useUserActions, useUserSelector } from 'hooks/UserManagement';
import { useUserConfig } from 'hooks/useUserConfig';
import { useFormContext } from 'react-hook-form';

import { enrichNameToId } from 'helpers/AppHelpers';

import SectionTitle from '../../../UserDetailed/components/Details/Title';

export default function UserEditForm() {
  const { watch, setValue, clearErrors, getValues, formState } = useFormContext();
  const { errors } = formState;

  const [requestOrderRoles, setRequestOrderRoles] = useState([]);

  const { currentUser, userRoles, locations, departments, prefixList, userEditTab } =
    useUserSelector();
  const { getRequestOrderRolesAction, setUserEditTabAction } = useUserActions();

  const { userEmail } = useUserConfig();

  const roleWatcher = watch('userRole');
  const isTeamMemberWatcher = watch('isTeamMember');
  const requestOrderRoleWatcher = watch('requestOrderRole');
  const locationsWatcher = watch('locations');

  const isAdminRoleSelected = roleWatcher?.role === 'Admin';
  const isPersonnelManagerRoleSelected = roleWatcher?.role === 'Personnel manager';
  const isSuperUserRoleSelected = roleWatcher?.role === 'Super user';
  const isFieldTechRoleSelected = roleWatcher?.role === 'Field tech';

  const TOGGLE_BUTTONS = [
    { id: 1, value: 'general', label: 'General' },
    { id: 2, value: 'schedule', label: 'Team Schedule' },
    {
      id: 3,
      value: 'requestOrder',
      label: 'Request Order',
      isDisabled: isSuperUserRoleSelected
    },
    { id: 4, value: 'notifications', label: 'Notifications' }
  ];

  useEffect(() => {
    getRequestOrderRolesAction().then((res) => setRequestOrderRoles(res));
  }, []);

  const selectRole = ({ name, value }) => {
    switch (value.role) {
      case 'Admin':
        setValue('locations', []);
        break;
      case 'Personnel manager':
        setValue('departments', departments);
        break;
      case 'Super user':
        setValue('requestOrderRole', null);
        setValue('notificationRequestOrderLocations', []);
        setValue('isSelectedAllRequestOrderLocations', false);
        break;
      default:
        setValue('departments', []);
        break;
    }

    setValue(name, value);
    errors?.locations?.message && clearErrors('locations');
    errors?.['departments']?.message && clearErrors('departments');
  };

  const clickTab = (tab) => setUserEditTabAction(tab);

  const selectRequestRole = (selectedItem) => setValue(selectedItem.name, selectedItem.value);

  const selectLocation = (name, value) => {
    errors?.locations?.message && clearErrors('locations');
    setValue(name, value);
  };

  const selectItem = (name, value) => {
    errors?.[name]?.message && clearErrors(name);
    setValue(name, value);
  };

  useEffect(() => {
    if (isTeamMemberWatcher && !isPersonnelManagerRoleSelected) {
      setValue('departments', []);
    }
  }, [isTeamMemberWatcher]);

  useEffect(() => {
    if (!('items' in locations)) return;
    setValue('isSelectedAllLocations', getValues('locations')?.length === locations?.items?.length);
  }, [locationsWatcher]);

  const updateSelectAll = (value, list, key) => {
    const isSelectedAll = value?.length === list.length;
    setValue(`isSelectedAll${key}`, isSelectedAll);
  };

  const checkSelectAll = (name, value) => {
    switch (name) {
      case 'notificationAssetReportLocations': {
        updateSelectAll(value, locations.items, 'AssetReportLocations');
        break;
      }
      case 'notificationAssetReportPrefixes': {
        updateSelectAll(value, prefixList, 'AssetReportPrefixes');
        break;
      }
      case 'notificationReorderRecipientLocations': {
        updateSelectAll(value, locations.items, 'ReorderRecipientLocations');
        break;
      }
      case 'notificationVerificationRecipientLocations': {
        updateSelectAll(value, locations.items, 'VerificationRecipientLocations');
        break;
      }
      case 'notificationLocationReportLocations': {
        updateSelectAll(value, locations.items, 'LocationReportLocations');
        break;
      }
      case 'notificationRequestOrderLocations': {
        updateSelectAll(value, locations.items, 'RequestOrderLocations');
        break;
      }
      case 'notificationTicketAndAssetUpdateLocations': {
        updateSelectAll(value, locations.items, 'TicketAndAssetUpdateLocations');
        break;
      }
      case 'notificationTicketAndAssetUpdatePrefixes': {
        updateSelectAll(value, prefixList, 'TicketAndAssetUpdatePrefixes');
        break;
      }
    }
  };

  return (
    <div className={styles.form}>
      <ToggleBar buttons={TOGGLE_BUTTONS} onChange={clickTab} selectedTab={userEditTab} />

      {userEditTab === 'general' && (
        <div className={styles.form__block}>
          <div className={styles.form__block_email}>
            <label>E-mail address</label>
            <b>{currentUser.email}</b>
          </div>
          <div className={styles.form__block_row}>
            <label>First Name*</label>
            <FormInputText name="firstName" options={{ max: 40 }} />
          </div>
          <div className={styles.form__block_row}>
            <label>Last Name*</label>
            <FormInputText name="lastName" options={{ max: 40 }} />
          </div>
          <div className={styles.form__block_row}>
            <label>Role*</label>
            <FormSelectInput
              name="userRole"
              options={userRoles || []}
              onSelect={selectRole}
              isDisabled={userEmail === currentUser.email}
            />
          </div>
          {!isAdminRoleSelected && (
            <div className={styles.form__block_row}>
              <label>Locations*</label>
              <FormMultiSelect
                name="locations"
                options={locations?.items || []}
                onSelect={selectLocation}
                selectAll
              />
            </div>
          )}
          <FormChipsContainer fieldName="locations" label="siteCode" deleteParameter="id" />
        </div>
      )}

      {userEditTab === 'schedule' && (
        <div className={styles.form__block}>
          {isPersonnelManagerRoleSelected && (
            <>
              <div className={styles.form__block_row}>
                <label>Department*</label>
                <FormMultiSelect
                  name="departments"
                  options={departments || []}
                  onSelect={selectItem}
                  selectAll
                />
              </div>
              <FormChipsContainer fieldName="departments" deleteParameter="id" />
            </>
          )}
          <div className={styles.form__block_checkbox}>
            <label>Is a team member</label>
            <FormCheckbox name="isTeamMember" />
          </div>
          {!isAdminRoleSelected && !isPersonnelManagerRoleSelected && (
            <>
              <div className={styles.form__block_row}>
                <label>Read-only Project Schedules</label>
                <FormAutoMultiSelect
                  name="departments"
                  options={{
                    label: 'name',
                    selectAll: true,
                    disableByObjectTracker: false,
                    disableLabel: true,
                    hideTags: true,
                    isDisabled: !!isTeamMemberWatcher
                  }}
                  menuItems={departments || ''}
                />
              </div>
              <FormChipsContainer fieldName="departments" deleteParameter="id" />
            </>
          )}
        </div>
      )}

      {userEditTab === 'requestOrder' && (
        <div className={styles.form__block}>
          <div className={styles.form__block_row}>
            <label>Request order role</label>
            <FormSelectInput
              clearable
              name="requestOrderRole"
              options={enrichNameToId(requestOrderRoles) || []}
              onSelect={selectRequestRole}
            />
          </div>
          {requestOrderRoleWatcher?.name && (
            <div className={styles.form__block_row}>
              <label>Notify by Location</label>
              <FormAutoMultiSelect
                name="notificationRequestOrderLocations"
                options={{
                  label: 'siteCode',
                  selectAll: true,
                  disableByObjectTracker: false,
                  hideTags: false,
                  disableLabel: true,
                  extraAction: checkSelectAll
                }}
                menuItems={locations?.items || []}
              />
            </div>
          )}
        </div>
      )}

      {userEditTab === 'notifications' && (
        <div className={styles.form__block}>
          <SectionTitle>
            Asset Report
            <InfoTooltip text="To set User as notification recipient, select both location and prefix parameters" />
          </SectionTitle>
          <div className={styles.flexed}>
            <div className={styles.form__block_row}>
              <label>Location</label>
              <FormAutoMultiSelect
                name="notificationAssetReportLocations"
                options={{
                  label: 'siteCode',
                  selectAll: true,
                  disableByObjectTracker: false,
                  hideTags: false,
                  disableLabel: true,
                  extraAction: checkSelectAll
                }}
                menuItems={locations?.items || []}
              />
            </div>
            <div className={styles.form__block_row}>
              <label>Prefix</label>
              <FormAutoMultiSelect
                name="notificationAssetReportPrefixes"
                options={{
                  label: 'prefix',
                  selectAll: true,
                  disableByObjectTracker: false,
                  hideTags: false,
                  disableLabel: true,
                  extraAction: checkSelectAll
                }}
                menuItems={prefixList || []}
              />
            </div>
          </div>

          <Separator />
          <SectionTitle>Location Report</SectionTitle>
          <div className={styles.flexed}>
            <div className={styles.form__block_row}>
              <label>Location</label>
              <FormAutoMultiSelect
                name="notificationLocationReportLocations"
                options={{
                  label: 'siteCode',
                  selectAll: true,
                  disableByObjectTracker: false,
                  hideTags: false,
                  disableLabel: true,
                  extraAction: checkSelectAll
                }}
                menuItems={locations?.items || []}
              />
            </div>
          </div>

          <Separator />
          <SectionTitle>General Report</SectionTitle>
          <div className={styles.form__block_checkbox}>
            <label>Is general report recipient</label>
            <FormCheckbox name="isGeneralReportRecipient" />
          </div>

          <Separator />
          <SectionTitle>
            Ticket & Asset Update
            <InfoTooltip text="To set User as notification recipient, select both parameters" />
          </SectionTitle>
          <div className={styles.flexed}>
            <div className={styles.form__block_row}>
              <label>Location</label>
              <FormAutoMultiSelect
                name="notificationTicketAndAssetUpdateLocations"
                options={{
                  label: 'siteCode',
                  selectAll: true,
                  disableByObjectTracker: false,
                  hideTags: false,
                  disableLabel: true,
                  extraAction: checkSelectAll
                }}
                menuItems={locations?.items || []}
              />
            </div>
            <div className={styles.form__block_row}>
              <label>Prefix</label>
              <FormAutoMultiSelect
                name="notificationTicketAndAssetUpdatePrefixes"
                options={{
                  label: 'prefix',
                  selectAll: true,
                  disableByObjectTracker: false,
                  hideTags: false,
                  disableLabel: true,
                  extraAction: checkSelectAll
                }}
                menuItems={prefixList || []}
              />
            </div>
          </div>

          <Separator />
          <SectionTitle>Loose Items</SectionTitle>
          <div className={styles.flexed}>
            <div className={styles.flexed__cell}>
              <div className={styles.form__block_checkbox}>
                <label>Is Buy Ahead Account recipient</label>
                <FormCheckbox name="isBAARecipient" />
              </div>
              {(isAdminRoleSelected || isSuperUserRoleSelected) && (
                <div className={styles.form__block_checkbox}>
                  <label>Notify on new loose item creation</label>
                  <FormCheckbox name="isLooseItemApproveRecipient" />
                </div>
              )}
            </div>
            <div className={styles.flexed__cell}>
              <div className={styles.form__block_row}>
                <label>Re-order Recipient</label>
                <FormAutoMultiSelect
                  name="notificationReorderRecipientLocations"
                  options={{
                    label: 'siteCode',
                    selectAll: true,
                    disableByObjectTracker: false,
                    hideTags: false,
                    disableLabel: true,
                    extraAction: checkSelectAll
                  }}
                  menuItems={locations?.items || []}
                />
              </div>
              {(isAdminRoleSelected || isSuperUserRoleSelected || isFieldTechRoleSelected) && (
                <div className={styles.form__block_row}>
                  <label>Verification Recipient</label>
                  <FormAutoMultiSelect
                    name="notificationVerificationRecipientLocations"
                    options={{
                      label: 'siteCode',
                      selectAll: true,
                      disableByObjectTracker: false,
                      hideTags: false,
                      disableLabel: true,
                      extraAction: checkSelectAll
                    }}
                    menuItems={locations?.items || []}
                  />
                </div>
              )}
            </div>
          </div>

          {(isPersonnelManagerRoleSelected || isAdminRoleSelected) && (
            <>
              <Separator />
              <SectionTitle>Team Members</SectionTitle>
              <div className={styles.form__block_checkbox}>
                <label>Is Team member status recipient</label>
                <FormCheckbox name="isTeamMemberStatusesRecipient" />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
