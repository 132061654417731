import { pluck } from 'helpers/AppHelpers';
import { useState, useCallback } from 'react';

export const useRowSelection = ({ items, filterCondition }) => {
  const [selectedRows, setSelectedRows] = useState(new Set());

  const getRowId = (index) => items?.[index]?.id || null;
  const getTableRowIds = () => {
    const rows = filterCondition ? items.filter(filterCondition) : items;
    return pluck(rows, 'id');
  };

  const isRowSelected = useCallback(
    (index) => selectedRows.has(getRowId(index)),
    [selectedRows, getRowId]
  );

  const toggleRowSelection = useCallback(
    (index, isSelected) => {
      const rowId = getRowId(index);
      if (!rowId) return;

      setSelectedRows((prev) => {
        const updated = new Set(prev);
        isSelected ? updated.add(rowId) : updated.delete(rowId);
        return updated;
      });
    },
    [getRowId]
  );

  const isAllSelected = useCallback(() => {
    const rowIds = getTableRowIds();
    return rowIds.length > 0 && rowIds.every((id) => selectedRows.has(id));
  }, [selectedRows, getTableRowIds]);

  const toggleAllSelection = useCallback(
    (isSelected) => {
      setSelectedRows(isSelected ? new Set(getTableRowIds()) : new Set());
    },
    [getTableRowIds]
  );

  return {
    selectedRows,
    isRowSelected,
    toggleRowSelection,
    isAllSelected,
    toggleAllSelection
  };
};
