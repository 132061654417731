import React from 'react';
import { TableHead, TableRow } from '@material-ui/core';
import SortableBlock from '../../SortableBlock/SortableBlock';
import { StyledTableCell } from '../../StyledComponents';
import styles from './TableHeadComponent.module.scss';
import clsx from 'clsx';

function TableHeadComponent({ config, sortRules = [], onSortApply }) {
  return (
    <TableHead>
      <TableRow>
        {config.map((el) => (
          <React.Fragment key={el.id}>
            {(el.isColumnAvailable === undefined || el.isColumnAvailable) && (
              <StyledTableCell className={clsx(el?.wrapText && styles.wrapText, styles.cell)}>
                {el.isSortable ? (
                  <SortableBlock
                    label={el.label}
                    parameter={el.parameter}
                    sortRules={sortRules}
                    onSortApply={onSortApply}
                  />
                ) : el?.renderContent ? (
                  <>{el.renderContent()}</>
                ) : (
                  <>
                    {el.label}
                    {el.footnote && <span className={styles.footnote}>{el.footnote}</span>}
                  </>
                )}
              </StyledTableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadComponent;
