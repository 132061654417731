import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import LooseItemCreateWrapper from './components/LooseItemCreateWrapper';
import CreationForm from './components/CreationForm';

import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

export default function LooseItemEdit() {
  const { id } = useParams();

  const { getItemAction, getCreationFormAction, clearStateAction } = useLooseItemActions();
  const { currentLooseItem } = useLooseItemSelector();
  const { setUnsavedFormDataAction } = useCommonActions();
  const { unsavedFormData } = useCommonSelector();

  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      location: {},
      sublocation: null,
      quantity: 0,
      byAheadAccount: 0,
      minimumQuantity: 0,
      pricePerUnit: 0,
      looseItemCategories: [],
      resources: [],
      customFields: [],
      verificationInterval: null
    },
    mode: 'onChange'
  });
  const { reset, formState } = methods;
  const { isDirty } = formState;

  useEffect(() => {
    if (id) {
      getItemAction(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && currentLooseItem?.id) {
      reset(currentLooseItem);
    }
  }, [currentLooseItem]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <LooseItemCreateWrapper>
        <CreationForm />
      </LooseItemCreateWrapper>
    </FormProvider>
  );
}
