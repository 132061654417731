import React, { useCallback, useState } from 'react';
import styles from './HeaderButtons.module.scss';

import { BlueButton, StyledButton, TransparentButton, DownloadCSVButton } from 'components/Buttons';
import ImportFilesPopup from 'components/ImportFilesPopup/ImportFilesPopup';

import { useHistory } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';
import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';
import { useMobileViewport } from 'hooks/useMobileViewport';
import useScrollManager from 'hooks/useScrollManager';

import AddIcon from '@material-ui/icons/Add';
import ImportIcon from 'assets/images/importFile.svg';

import { LOOSE_ITEM_EDIT_PATH, MOVE_LOOSE_ITEMS_PATH } from 'constants/routeConstants';
import { LOOSE_ITEMS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { fileDownload, pluck } from 'helpers/AppHelpers';

export function HeaderButtons() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const { removeScrollPosition } = useScrollManager({
    tableKey: LOOSE_ITEMS_TABLE_PARAMETER
  });

  const [openImportPopup, setOpenImportPopup] = useState(false);

  const { filter, customColumns } = useLooseItemSelector();
  const { getSummaryCsvResourceAction } = useLooseItemActions();
  const { isAdminUser, isBasicRoleUser, isOperationsManagerUser } = useUserConfig();

  const createLooseItem = useCallback(() => {
    removeScrollPosition();
    history.push(LOOSE_ITEM_EDIT_PATH);
  }, [history]);

  const openImportModal = useCallback(() => setOpenImportPopup(true), [setOpenImportPopup]);

  const moveLooseItems = useCallback(() => {
    removeScrollPosition();
    history.push(MOVE_LOOSE_ITEMS_PATH);
  }, [history]);

  const downloadCsv = () =>
    getSummaryCsvResourceAction({
      ...filter,
      filters: { ...filter.filters, looseItemCustomFieldIds: pluck(customColumns, 'id') }
    }).then((file) => file?.link && fileDownload(file));

  return (
    <div className={styles.headerButtons}>
      {!isMobile && (
        <TransparentButton
          label="Add loose items from CSV"
          onClick={openImportModal}
          className={styles.headerButtons__button}>
          <img src={ImportIcon} alt="import_file" />
        </TransparentButton>
      )}

      {!isMobile && <DownloadCSVButton onClick={downloadCsv} />}

      {!isBasicRoleUser && (
        <BlueButton
          label="Add item"
          onClick={createLooseItem}
          className={styles.headerButtons__button}>
          <AddIcon />
        </BlueButton>
      )}

      {(isAdminUser || isOperationsManagerUser) && (
        <StyledButton
          label="Move loose items"
          onClick={moveLooseItems}
          classes={styles.headerButtons__button}
        />
      )}

      {openImportPopup && <ImportFilesPopup open={openImportPopup} setOpen={setOpenImportPopup} />}
    </div>
  );
}
