import React, { useEffect, useState } from 'react';
import styles from './SetSchedule.module.scss';
import PropTypes from 'prop-types';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import { FormDayPicker, FormLabel, FormSearchInput } from 'components/FormComponents';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { CheckClearanceContent, RewriteStatusContent } from 'components/ModalContents/TeamMember';

import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

import {
  getCreationForm,
  deleteEvent,
  checkClearance,
  createEvent,
  updateEvent,
  createEvents
} from 'actions/setSchedulePopupActions';

import { formatDate, isSuccessfulStatus, pluck } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import { dateFormat, addDaysToDate } from 'helpers/AppHelpers';

import DeleteIcon from '@material-ui/icons/Delete';

import {
  calculateDayCount,
  CHECK_CLEARANCE_TYPE,
  PROJECT_NAME,
  SCREENS,
  STATUS_REWRITE_TYPE
} from './helpers';

/**
 * @typedef {Object} SetSchedulePopupProps
 * @property {boolean} open
 * @property {() => void} setOpen
 * @property {'Team Member Details Calendar' | 'Project Schedule'} screen
 * @property {() => void} onEventUpdate
 */

/**
 * @param {SetSchedulePopupProps} props
 */

function SetSchedulePopup({ open, setOpen, screen, data, onEventUpdate }) {
  const isMobile = useMobileViewport();
  const dispatch = useDispatch();

  const { isAdminUser, isPersonnelManagerUser, isTeamMemberUser, teamMemberId } = useUserConfig();

  const [options, setOptions] = useState({});
  const [modalData, setModalData] = useState({});

  const methods = useForm({
    defaultValues: {
      dayCount: 0,
      employmentType: {},
      end: null,
      isSendNotification: false,
      note: '',
      project: {},
      start: null,
      teamMember: {},
      teamMembers: []
    },
    mode: 'onChange'
  });
  const { setValue, getValues, formState, setError, clearErrors, watch, reset } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const { teamMembers, employmentTypes, projects } = options;

  useEffect(() => {
    dispatch(getCreationForm()).then((res) => setOptions(res));
  }, []);

  useEffect(() => {
    if (open) {
      reset({ ...data, dayCount: calculateDayCount(data?.start, data?.end) });
    }
  }, [open]);

  const statusWatcher = watch('employmentType');

  const conditions = {
    fields: {
      disable: {
        [SCREENS.CALENDAR]: !(isAdminUser || isPersonnelManagerUser || isTeamMemberUser),
        [SCREENS.SCHEDULE]: !(isAdminUser || isPersonnelManagerUser)
      }
    },
    teamMember: {
      display: screen === SCREENS.SCHEDULE && teamMembers?.length,
      disable: screen === SCREENS.SCHEDULE && !('teamMembers' in data),
      multiselect: 'teamMembers' in data
    },
    project: {
      display: statusWatcher?.name === PROJECT_NAME
    },
    note: {
      disable: !isAdminUser && !isPersonnelManagerUser
    },
    checkbox: {
      display: teamMemberId !== data?.teamMember?.id
    }
  };

  const validateForm = () => {
    const { teamMember, teamMembers, employmentType, project, start, end } = getValues();

    const fieldsToValidate = [
      {
        name: 'teamMember',
        errorMessage: 'Team member field is required',
        isInvalid: !teamMember?.id,
        shouldCheck: !conditions.teamMember.multiselect
      },
      {
        name: 'teamMembers',
        errorMessage: 'Team member field is required',
        isInvalid: !teamMembers?.length,
        shouldCheck: conditions.teamMember.multiselect
      },
      {
        name: 'employmentType',
        errorMessage: 'Status field is required',
        isInvalid: !employmentType?.id
      },
      {
        name: 'project',
        errorMessage: 'Project field is required',
        isInvalid: !project?.id,
        shouldCheck: employmentType?.name === PROJECT_NAME
      },
      {
        name: 'start',
        errorMessage: 'Start Date is required',
        isInvalid: !start
      },
      {
        name: 'end',
        errorMessage: 'End Date is required',
        isInvalid: !end
      },
      {
        name: 'start',
        errorMessage: `Start date can't be greater than End date`,
        isInvalid: formatDate(start) > formatDate(end),
        shouldCheck: start && end
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid, shouldCheck = true }) => {
      if (isInvalid && shouldCheck) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const clearError = (name) => errors?.[name]?.message && clearErrors(name);

  const selectOption = (name, value) => {
    clearError(name);
    setValue(name, value);
  };

  const selectStatus = (name, value) => {
    clearError('employmentType');
    clearError('project');
    setValue(name, value);
    setValue('project', {});
  };

  const selectDate = (pickerName, value) => {
    clearError('start');
    clearError('end');

    const { start: storedStart, end: storedEnd } = getValues();
    const start = pickerName === 'start' ? value : storedStart;
    const end = pickerName === 'end' ? value : storedEnd;

    setValue('dayCount', calculateDayCount(start, end));
  };
  const handleDayCountInput = (_name, value) => {
    const numberValue = Number(value);

    if (isNaN(numberValue) || numberValue === 0) {
      setValue('end', null);
      return;
    }

    const start = getValues('start');
    const endDate = addDaysToDate(start, numberValue - 1, dateFormat);
    setValue('end', endDate);
  };

  const closePopup = () => setOpen(false);
  const applyPopup = () => {
    if (!validateForm()) return;

    verifyClearance();
  };

  const verifyClearance = async () => {
    const { start: startDate, end: endDate, employmentType, teamMembers, teamMember } = getValues();
    const teamMemberIds = conditions.teamMember.multiselect
      ? pluck(teamMembers, 'id')
      : [teamMember.id];

    const result = await dispatch(checkClearance({ teamMemberIds, startDate, endDate }));

    const {
      displayWarningPopups = false,
      teamMembersWithEmptyClearance = [],
      teamMembersWithInactiveClearance = [],
      teamMembersWithExistedStatus = []
    } = result;

    if (!displayWarningPopups) {
      return processEventCreation();
    }

    const hasEmptyOrInactiveClearance =
      teamMembersWithEmptyClearance?.length || teamMembersWithInactiveClearance?.length;

    if (employmentType.name === PROJECT_NAME && hasEmptyOrInactiveClearance) {
      return showClearanceModal(
        [...teamMembersWithEmptyClearance, ...teamMembersWithInactiveClearance],
        teamMembersWithExistedStatus
      );
    }

    if (teamMembersWithExistedStatus?.length && conditions.teamMember.multiselect) {
      return showStatusModal(teamMembersWithExistedStatus);
    }

    processEventCreation();
  };

  const showClearanceModal = (clearanceMembers = [], statusMembers = []) => {
    const shouldDisplayList = screen === SCREENS.SCHEDULE;

    setModalData({
      isOpened: true,
      type: CHECK_CLEARANCE_TYPE,
      clearanceMembers,
      statusMembers,
      agreeText: 'Yes',
      cancelText: 'No',
      content: (
        <CheckClearanceContent teamMembers={clearanceMembers} displayList={shouldDisplayList} />
      )
    });
  };

  const showStatusModal = (statusMembers = []) => {
    const shouldDisplayList = screen === SCREENS.SCHEDULE;

    setModalData({
      isOpened: true,
      type: STATUS_REWRITE_TYPE,
      statusMembers,
      agreeText: 'Yes',
      cancelText: 'No',
      content: <RewriteStatusContent teamMembers={statusMembers} displayList={shouldDisplayList} />
    });
  };

  const processEventCreation = (excludedIds = []) => {
    if (conditions.teamMember.multiselect) {
      const allowedMembers =
        getValues('teamMembers')?.filter(({ id }) => !excludedIds.includes(id)) || [];

      if (!allowedMembers.length) {
        closeModal();
        return;
      }

      const payload = { ...getValues(), teamMembers: allowedMembers };
      dispatch(createEvents(payload)).then(handleResponse);
    } else {
      handleEventCreation(getValues());
    }

    closeModal();
  };

  const handleEventCreation = (data) => {
    const action = data?.id ? updateEvent : createEvent;
    return dispatch(action(data)).then(handleResponse);
  };

  const handleDeleteEvent = () => {
    const { id, start, end } = getValues();
    const query = { id, startDate: start, endDate: end };
    dispatch(deleteEvent(query)).then(handleResponse);
  };

  const handleResponse = (res) => {
    if (isSuccessfulStatus(res.status) && onEventUpdate) {
      closePopup();
      onEventUpdate();
    }
  };

  const agreeModal = () => {
    const { type, statusMembers } = modalData;

    if (type === CHECK_CLEARANCE_TYPE && conditions.teamMember.multiselect) {
      const hasExistedStatus = statusMembers?.length;

      if (hasExistedStatus) {
        showStatusModal(statusMembers);
        return;
      }
    }

    processEventCreation();
  };

  const dismissModal = () => {
    const { type, statusMembers } = modalData;

    if (type === STATUS_REWRITE_TYPE && conditions.teamMember.multiselect) {
      const excludedIds = pluck(statusMembers, 'id');
      processEventCreation(excludedIds);
      return;
    }

    closeModal();
  };

  const closeModal = () => setModalData({});

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader title="Set schedule" onClose={closePopup}>
          {isMobile && data?.id && (
            <HeaderButton onClick={handleDeleteEvent}>
              <DeleteIcon />
            </HeaderButton>
          )}
        </DialogHeader>
        <FormProvider {...methods}>
          <div className={styles.form}>
            {conditions.teamMember.display && (
              <div className={styles.form__row}>
                <FormLabel required>Team member</FormLabel>
                {conditions.teamMember.multiselect ? (
                  <FormAutoMultiSelect
                    name="teamMembers"
                    menuItems={teamMembers || []}
                    options={{
                      labelType: 'teamMember',
                      selectAll: false,
                      disableByObjectTracker: false,
                      disableLabel: true,
                      isDisabled: conditions.fields.disable[screen]
                    }}
                  />
                ) : (
                  <FormSearchInput
                    name="teamMember"
                    options={teamMembers || []}
                    onSelect={selectOption}
                    optionType="teamMember"
                    isDisabled={conditions.fields.disable[screen] || conditions.teamMember.disable}
                  />
                )}
              </div>
            )}
            <div className={styles.form__row}>
              <FormLabel required>Status</FormLabel>
              <FormSearchInput
                includeMissingOption
                name="employmentType"
                options={employmentTypes || []}
                onSelect={selectStatus}
                isDisabled={conditions.fields.disable[screen]}
              />
            </div>
            {conditions.project.display && (
              <div className={styles.form__row}>
                <FormLabel required>Project</FormLabel>
                <FormSearchInput
                  includeMissingOption
                  name="project"
                  options={projects || []}
                  onSelect={selectOption}
                  isDisabled={conditions.fields.disable[screen]}
                />
              </div>
            )}
            <div className={styles.form__row}>
              <FormLabel required>Start Date</FormLabel>
              <FormDayPicker
                clearable={false}
                name="start"
                disabled={conditions.fields.disable[screen]}
                onSelectTriggered={selectDate}
              />
            </div>
            <div className={styles.form__row}>
              <FormLabel required>End Date</FormLabel>
              <FormDayPicker
                clearable={false}
                name="end"
                disabled={conditions.fields.disable[screen]}
                onSelectTriggered={selectDate}
              />
            </div>
            <div className={styles.form__row}>
              <FormLabel>Day Count</FormLabel>
              <FormInputText
                name="dayCount"
                options={{ max: 50, type: 'quantity', focus: true, isDisabled: conditions.fields.disable[screen] }}
                classes={styles.form__row_day_count}
                onInputTriggered={handleDayCountInput}
              />
            </div>
            <div className={styles.form__notes}>
              <FormLabel>Note</FormLabel>
              <FormTextArea
                name="note"
                options={{
                  max: 255,
                  disabled: conditions.fields.disable[screen] || conditions.note.disable
                }}
              />
            </div>
            {conditions.checkbox.display && (
              <div className={styles.form__checkbox}>
                <FormLabel>Send notification</FormLabel>
                <FormCheckbox
                  name="isSendNotification"
                  disabled={conditions.fields.disable[screen]}
                />
              </div>
            )}
          </div>
        </FormProvider>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          {!conditions.fields.disable[screen] && (
            <MainButton
              text="Apply"
              action={applyPopup}
              type="primary"
              isDisabled={isFormInvalid}
            />
          )}
          {!isMobile && data?.id && !conditions.fields.disable[screen] && (
            <MainButton text="Delete" action={handleDeleteEvent} type="extra" />
          )}
        </div>
      </div>

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={dismissModal} />
    </DialogWrapper>
  );
}

SetSchedulePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
  data: PropTypes.object,
  onEventUpdate: PropTypes.func
};

SetSchedulePopup.defaultProps = {
  data: {}
};

export { SetSchedulePopup };
