import React from 'react';

import UserEditWrapper from './components/UserEditWrapper';
import { FormProvider, useForm } from 'react-hook-form';
import UserEditForm from './components/Form/UserEditForm';

export default function UserEdit() {
  const methods = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      isNew: true,
      lastName: '',
      locked: true,
      userRole: {},
      departments: [],
      isBAARecipient: true,
      isLooseItemApproveRecipient: false,
      isGeneralReportRecipient: true,
      isSelectedAllLocations: true,
      isTeamMember: true,
      isTeamMemberStatusesRecipient: false,
      locations: [],
      requestOrderRole: {},
      notificationAssetReportLocations: [],
      notificationAssetReportPrefixes: [],
      notificationTicketAndAssetUpdateLocations: [],
      notificationTicketAndAssetUpdatePrefixes: [],
      notificationLocationReportLocations: [],
      notificationRequestOrderLocations: [],
      notificationReorderRecipientLocations: [],
      notificationVerificationRecipientLocations: [],
      isSelectedAllAssetReportLocations: false,
      isSelectedAllAssetReportPrefixes: false,
      isSelectedAllTicketAndAssetUpdateLocations: false,
      isSelectedAllTicketAndAssetUpdatePrefixes: false,
      isSelectedAllReorderRecipientLocations: false,
      isSelectedAllLocationReportLocations: false,
      isSelectedAllVerificationRecipientLocations: false
    },
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <UserEditWrapper>
        <UserEditForm />
      </UserEditWrapper>
    </FormProvider>
  );
}
