import { createSelector } from 'reselect';

const data = (state) => state.looseItemsAndPartsData;

// Summary
export const selectFilterData = () => createSelector([data], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([data], (state) => state.filterCriteria);

export const selectSelectedRowsData = () => createSelector([data], (state) => state.checkedItems);

export const selectSelectedColumnsData = () =>
  createSelector([data], (state) => state.customColumns);

export const selectSingleItemData = () => createSelector([data], (state) => state.currentItem);

export const selectCreationFormData = () => createSelector([data], (state) => state.creationForm);
