import { cloneObj, formatDate, getUniqueID, getUserFullName } from 'helpers/AppHelpers';

const createChip = (key, label) => ({ chipId: getUniqueID(), key, label });
const getUsedItems = (ids, items) => items.filter(({ id }) => ids.includes(id));

const handleIdsFilter = (ids, options, getLabel, key) => {
  return getUsedItems(ids, options).map((el) => ({
    ...createChip(key, getLabel(el)),
    itemId: el.id
  }));
};

const handleBooleanFilter = (key, label) => [createChip(key, label)];

const handleDateFilter = (dateFrom, dateTo) => {
  if (!dateFrom && !dateTo) return [];

  const isTwoDatesSelected = dateFrom && dateTo;

  const key = isTwoDatesSelected ? 'duration' : dateFrom ? 'dateFrom' : 'dateTo';
  const chipLabel = isTwoDatesSelected
    ? `Created ${formatDate(dateFrom)} - ${formatDate(dateTo)}`
    : dateFrom
    ? `Created from ${formatDate(dateFrom)}`
    : `Created to ${formatDate(dateTo)}`;

  return [createChip(key, chipLabel)];
};

export const generateChips = (options, filters = {}) => {
  const { locations, generalReportTemplate, projects, users, createdUserList } = options;

  const {
    dateFrom,
    dateTo,
    locationIds,
    locationJobNumberIds,
    projectIds,
    assignedUserIds,
    generalReportTemplateIds,
    showAssigned,
    showNotSubmitted,
    showWithInactiveLocations,
    createdUserIds
  } = filters;

  let newChips = [];

  const filterConfigs = [
    { ids: locationIds, options: locations, getLabel: (el) => el.siteCode, key: 'locationIds' },
    {
      ids: locationJobNumberIds,
      options: locations,
      getLabel: (el) => el.locationJobNumber,
      key: 'locationJobNumberIds'
    },
    { ids: projectIds, options: projects, getLabel: (el) => el.name, key: 'projectIds' },
    {
      ids: assignedUserIds,
      options: users,
      getLabel: (el) => getUserFullName(el),
      key: 'assignedUserIds'
    },
    {
      ids: generalReportTemplateIds,
      options: generalReportTemplate,
      getLabel: (el) => el.name,
      key: 'generalReportTemplateIds'
    },
    {
      ids: createdUserIds,
      options: createdUserList,
      getLabel: (el) => getUserFullName(el),
      key: 'createdUserIds'
    }
  ];

  filterConfigs.forEach((config) => {
    if (config?.ids?.length) {
      newChips = newChips.concat(
        handleIdsFilter(config.ids, config.options, config.getLabel, config.key)
      );
    }
  });

  if (showAssigned) {
    newChips = newChips.concat(handleBooleanFilter('showAssigned', 'Show assigned'));
  }

  if (showNotSubmitted) {
    newChips = newChips.concat(handleBooleanFilter('showNotSubmitted', 'Show only not submitted'));
  }

  if (showWithInactiveLocations) {
    newChips = newChips.concat(
      handleBooleanFilter('showWithInactiveLocations', 'Show inactive location reports only')
    );
  }

  newChips = newChips.concat(handleDateFilter(dateFrom, dateTo));

  return newChips.filter(Boolean);
};

export const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId } = deletedChip;

  switch (key) {
    case 'showAssigned':
    case 'showNotSubmitted':
      newFilters[key] = false;
      break;
    case 'showWithInactiveLocations':
      newFilters[key] = false;
      newFilters.locationIds = [];
      newFilters.locationJobNumberIds = [];
      break;
    case 'duration':
      newFilters.dateTo = null;
      newFilters.dateFrom = null;
      break;
    case 'dateFrom':
      newFilters.dateFrom = null;
      break;
    case 'dateTo':
      newFilters.dateTo = null;
      break;
    case 'createdUserIds':
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      newFilters.createdUserList = newFilters.createdUserList.filter(({ id }) => id !== itemId);
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

export const splitLocationsByStatus = (locations = []) =>
  locations.reduce(
    (acc, location) => {
      acc[location.active ? 'activeLocations' : 'inactiveLocations'].push(location);
      return acc;
    },
    { activeLocations: [], inactiveLocations: [] }
  );
