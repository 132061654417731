import { getErrorsProperties } from 'helpers/ErrorValidator';
import { useFormContext } from 'react-hook-form';
import { enqueueWarningSnackbar } from 'helpers/AppHelpers';
import { WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_MOVE_QTY } from 'constants/infoSnackbarData';
import {
  errorMessages,
  isDestinationValid,
  isMoveQuantityValid,
  isMoveByAheadAccountQuantityValid,
  checkMoveByAheadAccount,
  checkRowForWarnings
} from './helpers';

const useMoveLooseItemValidation = () => {
  const { formState, clearErrors, setError, getValues, setValue } = useFormContext();
  const { errors } = formState;

  // Destination
  const getDestinationErrorName = (rowId) => `destinationLocation-${rowId}`;
  const hasDestinationError = (rowId) => !!errors?.[`destinationLocation-${rowId}`];
  const getDestinationErrorMessage = (rowId) => errors?.[`destinationLocation-${rowId}`]?.message;

  const validateDestination = (rowData) => {
    const rowId = rowData.id;
    const errorName = getDestinationErrorName(rowId);
    const hasError = hasDestinationError(rowId);

    if (isDestinationValid(rowData)) {
      if (hasError) {
        clearErrors(errorName);
      }
    } else {
      setError(errorName, getErrorsProperties(errorMessages.destination.sameLocation));
    }
  };

  const clearDestinationError = (rowId) => {
    if (hasDestinationError(rowId)) {
      const errorName = getDestinationErrorName(rowId);
      clearErrors(errorName);
    }
  };

  // Buy Ahead Account Quantity
  const getMoveByAheadAccountQuantityErrorName = (rowId) => `moveByAheadAccount-${rowId}`;
  const hasMoveByAheadAccountQuantityError = (rowId) => !!errors?.[`moveByAheadAccount-${rowId}`];
  const getMoveByAheadAccountQuantityErrorMessage = (rowId) =>
    errors?.[`moveByAheadAccount-${rowId}`]?.message;

  const validateMoveByAheadAccount = (rowData) => {
    const rowId = rowData.id;

    const errorName = getMoveByAheadAccountQuantityErrorName(rowId);
    const hasError = hasMoveByAheadAccountQuantityError(rowId);

    if (isMoveByAheadAccountQuantityValid(rowData)) {
      if (hasError) {
        clearErrors(errorName);
      }
    } else {
      const { invalid, error } = checkMoveByAheadAccount(rowData);
      if (invalid) {
        setError(errorName, getErrorsProperties(error));
      }
    }
  };

  const clearMoveByAheadAccountError = (rowId) => {
    if (hasMoveByAheadAccountQuantityError(rowId)) {
      const errorName = getMoveByAheadAccountQuantityErrorName(rowId);
      clearErrors(errorName);
    }
  };

  // Move Quantity
  const getMoveQuantityErrorName = (rowId) => `moveQuantity-${rowId}`;
  const hasMoveQuantityError = (rowId) => !!errors?.[`moveQuantity-${rowId}`];
  const getMoveQuantityErrorMessage = (rowId) => errors?.[`moveQuantity-${rowId}`]?.message;

  const validateMoveQuantity = (rowData) => {
    const rowId = rowData.id;

    const errorName = getMoveQuantityErrorName(rowId);
    const hasError = hasMoveQuantityError(rowId);

    if (isMoveQuantityValid(rowData)) {
      if (hasError) {
        clearErrors(errorName);
      }
    } else {
      if (!hasError) {
        setError(
          errorName,
          getErrorsProperties(errorMessages.moveQuantity.exceedsAvailableQuantity)
        );
      }
    }
  };

  const clearMoveQuantityError = (rowId) => {
    if (hasMoveQuantityError(rowId)) {
      const errorName = getMoveQuantityErrorName(rowId);
      clearErrors(errorName);
    }
  };

  // Clearing
  const clearErrorsForRow = (rowId) => {
    clearDestinationError(rowId);
    clearMoveQuantityError(rowId);
    clearMoveByAheadAccountError(rowId);
  };

  // Cell Highlighting
  const getCellHighlightState = (rowId) => {
    return {
      highlightDestination: getDestinationErrorMessage(rowId),
      highlightMoveQuantity: getMoveQuantityErrorMessage(rowId),
      highlightMoveByAheadAccount: getMoveByAheadAccountQuantityErrorMessage(rowId)
    };
  };

  // Table Validation
  const validateTable = () => {
    let isValid = true;

    const tableRows = getValues('moveLooseItems');

    tableRows.forEach((row) => {
      if (!row?.destinationLocation) {
        isValid = false;
        const errorName = getDestinationErrorName(row.id);
        setError(errorName, getErrorsProperties(errorMessages.destination.empty));
      }
    });

    return isValid;
  };

  // Warnings
  const validateWarning = (index) => {
    const warnings = getValues('warnings');
    const itemId = getValues(`moveLooseItems[${index}].id`);

    if (warnings.includes(itemId)) {
      setValue(
        'warnings',
        warnings.filter((id) => id !== itemId)
      );
    }
  };

  const validateWarnings = () => {
    const rowsWithWarnings = [];
    const warnings = getValues('warnings');
    const looseItems = getValues('moveLooseItems');

    looseItems.forEach((row) => {
      if (checkRowForWarnings(row)) {
        rowsWithWarnings.push(row.id);
      }
    });

    const shouldDisplayPopup = !warnings?.length && !!rowsWithWarnings?.length;

    if (shouldDisplayPopup) {
      console.log('rowsWithWarnings', rowsWithWarnings);
      setValue('warnings', rowsWithWarnings);
      enqueueWarningSnackbar(WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_MOVE_QTY);
    }

    return shouldDisplayPopup;
  };

  return {
    getCellHighlightState,
    clearErrorsForRow,
    validateDestination,
    validateMoveQuantity,
    validateMoveByAheadAccount,
    validateTable,
    validateWarning,
    validateWarnings
  };
};

export default useMoveLooseItemValidation;
