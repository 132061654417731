import React, { useMemo } from 'react';
import styles from './LooseItems.module.scss';

import { NoDataTableRow, TableHeadComponent, TableWrapper } from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import StyledLink from 'components/StyledLink';
import { FormSearchInput } from 'components/FormComponents';
import ErrorBlock from 'components/ErrorBlock';
import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { IconButton, Table, TableBody } from '@material-ui/core';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useBatchItemUpdatesSelector } from 'hooks/BatchItemUpdates';

import { pluck } from 'helpers/AppHelpers';

import { LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';

import RemoveIcon from 'assets/images/deleteIcon.svg';

import { clsx } from 'clsx';

import { tableHeadConfig } from './tableConfig';

export function LooseItems({ selectionState, validation }) {
  const { control, getValues, setValue, watch } = useFormContext();
  const { fields, update, remove } = useFieldArray({
    control,
    name: 'moveLooseItems'
  });

  const { creationForm } = useBatchItemUpdatesSelector();
  const { looseItemLocations, locationsWithSublocations } = creationForm;

  const { isAllSelected, toggleAllSelection, isRowSelected, toggleRowSelection } = selectionState;

  const {
    validateDestination,
    validateMoveQuantity,
    validateMoveByAheadAccount,
    validateWarning,
    clearErrorsForRow,
    getCellHighlightState
  } = validation;

  const createTableConfig = (isAllSelected, toggleAllSelection, fields) => [
    {
      id: 1,
      label: '',
      renderContent: () => (
        <CustomCheckbox
          sendEvent
          isDisabled={fields.length === 0}
          value={isAllSelected()}
          onChange={(e) => toggleAllSelection(e.target.checked)}
        />
      )
    },
    ...tableHeadConfig
  ];

  const tableConfig = useMemo(
    () => createTableConfig(isAllSelected, toggleAllSelection, fields),
    [isAllSelected, toggleAllSelection, fields]
  );

  const getRowData = (index) => getValues(`moveLooseItems[${index}]`);

  const getDropdownLocations = (index) => {
    const rowData = getRowData(index);
    const locationId = rowData?.id;
    const looseItemId = rowData?.looseItem?.id;

    if (!locationId && !looseItemId) return [];

    const selectedLocationIds = pluck(getValues('moveLooseItems'), 'id');

    return looseItemLocations.filter(({ id, looseItem }) =>
      id === locationId ? true : !selectedLocationIds.includes(id) && looseItemId === looseItem.id
    );
  };

  const getSublocationsOptions = (locationId) =>
    locationsWithSublocations?.find(({ id }) => id === locationId)?.sublocations || [];

  const selectLooseItemLocation = (_, value, index) => {
    const { id } = getRowData(index);
    validateWarning(index);
    clearErrorsForRow(id);

    update(index, { ...value, moveQuantity: 0, moveByAheadAccount: 0 });
  };

  const handleChangeDestinationLocation = (name, value, index) => {
    setValue(name, value);
    setValue(`moveLooseItems[${index}].destinationSublocation`, null);
    setValue(`moveLooseItems[${index}].destinationAsset`, null);

    const rowData = getRowData(index);
    validateDestination(rowData);
  };

  const handleChangeDestinationSublocation = (name, value, index) => {
    setValue(name, value);
    setValue(`moveLooseItems[${index}].destinationAsset`, null);

    const rowData = getRowData(index);
    validateDestination(rowData);
  };

  const handleChangeDestinationAsset = (name, value, index) => {
    setValue(name, value);

    if (value?.id) {
      const { currentLocationId, currentSublocationId } = value;
      const location = locationsWithSublocations?.find(({ id }) => id === currentLocationId);

      if (!location) return;

      const sublocation =
        location.sublocations?.find(({ id }) => id === currentSublocationId) || null;
      const rowName = `moveLooseItems[${index}]`;

      setValue(`${rowName}.destinationLocation`, location);
      setValue(`${rowName}.destinationSublocation`, sublocation);
    }

    const rowData = getRowData(index);
    validateDestination(rowData);
  };

  const handleChangeMoveQuantity = (index) => {
    validateWarning(index);

    const rowData = getRowData(index);
    validateMoveQuantity(rowData);
    validateMoveByAheadAccount(rowData);
  };

  const handleChangeMoveByAheadAccount = (index) => {
    validateWarning(index);

    const rowData = getRowData(index);
    validateMoveByAheadAccount(rowData);
  };

  const removeRow = (index) => {
    if (isRowSelected(index)) {
      toggleRowSelection(index, false);
    }

    validateWarning(index);

    const { id } = getRowData(index);
    clearErrorsForRow(id);

    remove(index);
  };

  const warningsWatcher = watch('warnings');

  return (
    <TableWrapper enableHorizontalScroll>
      <Table>
        <TableHeadComponent config={tableConfig} />

        <TableBody>
          {fields?.length ? (
            fields.map((row, index) => {
              const { highlightDestination, highlightMoveQuantity, highlightMoveByAheadAccount } =
                getCellHighlightState(getValues(`moveLooseItems[${index}].id`));

              return (
                <StyledTableRow key={row.id} className={styles.table_row}>
                  <StyledTableCell className={styles.checkboxCell}>
                    <CustomCheckbox
                      sendEvent
                      value={isRowSelected(index)}
                      onChange={(e) => toggleRowSelection(index, e.target.checked)}
                    />
                  </StyledTableCell>

                  <StyledTableCell className={styles.looseItemCell}>
                    <StyledLink to={`${LOOSE_ITEM_DETAILED_PATH}/${row.looseItem.id}`}>
                      {row.looseItem.name || ''}
                    </StyledLink>
                  </StyledTableCell>

                  <StyledTableCell className={styles.looseItemLocationCell}>
                    <FormSearchInput
                      name={`moveLooseItems[${index}]`}
                      options={getDropdownLocations(index)}
                      onSelect={selectLooseItemLocation}
                      classes={styles.select_input_style}
                      disableError
                      optionType="locationWithSublocationAndContainedInAsset"
                      index={index}
                    />
                  </StyledTableCell>

                  <StyledTableCell className={styles.availableQtyCell}>
                    {row.quantity || 0}
                    {!!row?.byAheadAccount && `${' (' + row.byAheadAccount + ')'}`}
                  </StyledTableCell>

                  <StyledTableCell
                    className={clsx(
                      styles.destinationLocationCell,
                      highlightDestination && styles.invalid
                    )}>
                    <FormSearchInput
                      clearable
                      name={`moveLooseItems[${index}].destinationLocation`}
                      options={locationsWithSublocations || []}
                      onSelect={handleChangeDestinationLocation}
                      classes={styles.select_input_style}
                      disableError
                      index={index}
                    />
                    {highlightDestination && (
                      <ErrorBlock
                        className={styles.errorContainer}
                        errorText={highlightDestination}
                      />
                    )}
                  </StyledTableCell>

                  <StyledTableCell className={styles.destinationSublocationCell}>
                    <Controller
                      name={`moveLooseItems[${index}].destinationLocation`}
                      render={({ field: { value } }) => (
                        <FormSearchInput
                          clearable
                          name={`moveLooseItems[${index}].destinationSublocation`}
                          options={getSublocationsOptions(value?.id)}
                          onSelect={handleChangeDestinationSublocation}
                          classes={styles.select_input_style}
                          disableError
                          index={index}
                        />
                      )}
                    />
                  </StyledTableCell>

                  <StyledTableCell className={styles.destinationAssetCell}>
                    <FormServerAssetSearch
                      name={`moveLooseItems[${index}].destinationAsset`}
                      max={100}
                      onSelect={handleChangeDestinationAsset}
                      getLabel={(option) => option?.drCode || ''}
                      getQuery={(searchQuery) => ({
                        pagination: { limit: 39, page: 1 },
                        filters: { isDeleted: false, prefixTypes: [2, 3, 4, 5], searchQuery }
                      })}
                      className={styles.asset_input_style}
                      index={index}
                    />
                  </StyledTableCell>

                  <StyledTableCell
                    className={clsx(
                      styles.moveQtyCell,
                      highlightMoveQuantity && styles.invalid,
                      warningsWatcher.includes(getValues(`moveLooseItems[${index}].id`)) &&
                        styles.warning
                    )}>
                    <FormInputText
                      name={`moveLooseItems[${index}].moveQuantity`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        index: index,
                        disableError: true,
                        onlyNumbers: true
                      }}
                      classes={styles.quantity_input_style}
                      onInputTriggered={() => handleChangeMoveQuantity(index)}
                    />
                    {highlightMoveQuantity && <ErrorBlock errorText={highlightMoveQuantity} />}
                  </StyledTableCell>

                  <StyledTableCell
                    className={clsx(
                      styles.moveBaaCell,
                      highlightMoveByAheadAccount && styles.invalid
                    )}>
                    <FormInputText
                      name={`moveLooseItems[${index}].moveByAheadAccount`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        index: index,
                        disableError: true,
                        onlyNumbers: true
                      }}
                      classes={styles.quantity_input_style}
                      onInputTriggered={() => handleChangeMoveByAheadAccount(index)}
                    />
                    {highlightMoveByAheadAccount && (
                      <ErrorBlock errorText={highlightMoveByAheadAccount} />
                    )}
                  </StyledTableCell>

                  <StyledTableCell className={styles.removeCell}>
                    <IconButton onClick={() => removeRow(index)} className={styles.removeCell_btn}>
                      <img src={RemoveIcon} alt="remove" />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
          ) : (
            <NoDataTableRow label="No data in the table." />
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
