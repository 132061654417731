import React, { useEffect, useState } from 'react';
import styles from './LooseItemDetails.module.scss';

import { useParams } from 'react-router-dom';
import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';
import { useRowSelection } from 'hooks/useRowSelection';

import Comment from 'components/Comment';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import {
  MARK_LOOSE_ITEMS_AS_NEED_TO_BE_VERIFIED,
  MARK_LOOSE_ITEMS_AS_VERIFIED
} from 'constants/dialogPopupsData';

import LooseItemsTable from '../../components/Table/LooseItemsTable';
import GeneralDetails from '../../components/GeneralDetails';
import Header from '../../components/LooseItemDetailsHeader';
import SetVerificationStatusButton from '../../Summary/SetVerificationStatusButton';

const VERIFIABLE_STATUSES = ['Available', 'Rented'];

const isStatusVerifiable = ({ status }) => !!VERIFIABLE_STATUSES.includes(status.name);

export default function LooseItemDetails() {
  const { id } = useParams();

  const [modalData, setModalData] = useState({});

  const { currentLooseItem, filterCriteria } = useLooseItemSelector();
  const { getItemAction, getFilterCriteriaAction, setVerificationStatusAction, clearStateAction } =
    useLooseItemActions();

  const selectionState = useRowSelection({
    items: currentLooseItem?.locations || [],
    filterCondition: isStatusVerifiable
  });
  const { selectedRows, toggleAllSelection } = selectionState;

  useEffect(() => {
    if (id) {
      getItemAction(id);
    }
  }, [id]);

  useEffect(() => {
    getFilterCriteriaAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    const payload = {
      locationLooseItemIds: [...selectedRows],
      verificationAction: modalData.action
    };
    setVerificationStatusAction(payload).then(() => getItemAction(id));
    toggleAllSelection(false);
    closeModal();
  };

  const setVerificationStatus = (status) => {
    const modalsData = {
      ['NeedToBeVerified']: MARK_LOOSE_ITEMS_AS_NEED_TO_BE_VERIFIED,
      ['MarkAsVerified']: MARK_LOOSE_ITEMS_AS_VERIFIED
    };
    setModalData({ ...modalsData[status.name], isOpened: true, action: status });
  };

  if (currentLooseItem?.id !== +id) return null;

  return (
    <section className={styles.pageContainer}>
      <Header />
      <GeneralDetails />

      {!!selectedRows?.size && (
        <SetVerificationStatusButton
          menuItems={filterCriteria?.verificationActions || []}
          onMenuItemClick={setVerificationStatus}
        />
      )}

      <LooseItemsTable
        tableBodyData={currentLooseItem?.locations}
        isUnitsCostsColumnAvailable={!!currentLooseItem?.pricePerUnit}
        totalUnitsCosts={currentLooseItem.totalUnitCosts}
        totalQuantity={currentLooseItem?.quantity || 0}
        selectionState={selectionState}
        isStatusVerifiable={isStatusVerifiable}
      />

      <Comment label="[1]Buy Ahead Account" />

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
    </section>
  );
}
