import React, { useState } from 'react';
import styles from './Content.module.scss';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { AddButton } from 'components/Buttons';
import { MoveLooseItemsPopup } from 'components/Popups';
import Comment from 'components/Comment';
import { FilterButton } from 'components/FilterComponentsV2';

import CreateIcon from '@material-ui/icons/Create';

import { useBatchItemUpdatesActions, useBatchItemUpdatesSelector } from 'hooks/BatchItemUpdates';
import { useFormContext } from 'react-hook-form';
import { useRowSelection } from 'hooks/useRowSelection';

import { MOVE_LOOSE_ITEMS, MOVE_LOOSE_ITEMS_TYPE } from 'constants/dialogPopupsData';

import Footer from './Footer';
import EditSelection from './EditSelection';
import useMoveLooseItemValidation from './validation/useMoveLooseItemValidation';
import LooseItems from './LooseItems';

export function Content() {
  const [modalData, setModalData] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);

  const {
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const { creationForm } = useBatchItemUpdatesSelector();
  const { moveLooseItemsAction, getLooseItemsLocationsAction, setCreationFormAction } =
    useBatchItemUpdatesActions();

  const moveLooseItemsWatcher = watch('moveLooseItems');
  const selectionState = useRowSelection({ items: moveLooseItemsWatcher });
  const { selectedRows } = selectionState;

  const validation = useMoveLooseItemValidation();
  const {
    validateTable,
    validateDestination,
    validateMoveQuantity,
    validateMoveByAheadAccount,
    validateWarnings
  } = validation;

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    if (modalData.type === MOVE_LOOSE_ITEMS_TYPE) {
      moveLooseItemsAction(getValues());
    }
    reset();
    closeModal();
  };

  const showPopup = () => setOpenPopup(true);
  const openSelectionEditor = () => setOpenEditor(true);

  const moveLooseItems = () => {
    if (!validateTable()) return;

    if (validateWarnings()) return;

    setModalData({ isOpened: true, ...MOVE_LOOSE_ITEMS });
  };

  const addLooseItems = (looseItemLocations) => {
    const q = {
      filters: {
        statusNames: ['Available'],
        looseItemIds: looseItemLocations.map(({ looseItem }) => looseItem.id)
      }
    };
    getLooseItemsLocationsAction(q).then((res) => {
      setCreationFormAction({ ...creationForm, looseItemLocations: res });
      setValue('moveLooseItems', looseItemLocations);
    });
  };

  const applyChanges = ({ destination, quantity }) => {
    const tableData = getValues('moveLooseItems');

    const newTableData = tableData.map((row) => {
      const rowId = row.id;

      if (!selectedRows.has(rowId)) return row;

      const updates = {
        ...(destination && {
          destinationLocation: destination,
          destinationSublocation: null,
          destinationAsset: null
        }),
        ...(quantity && { moveQuantity: quantity, moveByAheadAccount: 0 })
      };

      const newValue = { ...row, ...updates };

      if (destination) {
        validateDestination(newValue, rowId);
      }

      if (quantity) {
        validateMoveQuantity(newValue, rowId);
        validateMoveByAheadAccount(newValue, rowId);
      }

      return newValue;
    });

    setValue('moveLooseItems', newTableData);
  };

  return (
    <div className={styles.content}>
      {!!selectedRows.size && (
        <FilterButton onClick={openSelectionEditor} isActive={openEditor} icon={<CreateIcon />} />
      )}

      <LooseItems selectionState={selectionState} validation={validation} />

      <Comment label="[1]Buy Ahead Account" />

      <AddButton onClick={showPopup} disabled={isFormInvalid} />

      <Footer onMove={moveLooseItems} isFormInvalid={isFormInvalid} />

      {openEditor && (
        <EditSelection open={openEditor} setOpen={setOpenEditor} onApply={applyChanges} />
      )}

      {openPopup && (
        <MoveLooseItemsPopup
          open={openPopup}
          setOpen={setOpenPopup}
          screen="Move Loose Items"
          movedItems={getValues('moveLooseItems')}
          onMove={addLooseItems}
        />
      )}

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
    </div>
  );
}
