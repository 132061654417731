import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getCategoriesApi = (query) =>
  api.get(`/LooseItemCategories/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getCustomFieldsApi = (query) =>
  api.get(`/LooseItemCustomFields?${queryToString(query)}`).then((res) => res.data);

export const getLooseItemsApi = (query) =>
  api.get(`/LooseItems/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getLooseItemsLocationsApi = (query) =>
  api.get(`/LocationLooseItems?${queryToString(query)}`).then((res) => res.data);

export const getLocationsWithSublocationsApi = (query) =>
  api.get(`/Locations/ShortedWithSublocations?${queryToString(query)}`).then((res) => res.data);

export const updateLooseItemsApi = (data) => api.put('/LooseItems', data).then((res) => res);

export const moveLooseItemsApi = (params) =>
  api.put(`/LooseItems/Move`, params).then((res) => res.data);
