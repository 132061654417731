import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useState } from 'react';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import DetailsHeader from 'components/DetailsComponents/Header';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { LOOSE_ITEM_EDIT_PATH, LOOSE_ITEMS_LIST_PATH } from 'constants/routeConstants';
import { useHistory } from 'react-router-dom';
import { useLooseItemSelector, useLooseItemActions } from 'hooks/LooseItems';
import {
  APPROVE_LOOSE_ITEM,
  APPROVE_LOOSE_ITEM_TYPE,
  DELETE_LOOSE_ITEM
} from 'constants/dialogPopupsData';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { StyledButton } from 'components/Buttons';
import styles from './Components.module.scss';

export default function LooseItemDetailsHeader() {
  const isMobile = useMobileViewport();
  const { isAdminUser, isOperationsManagerUser } = useUserConfig();
  const history = useHistory();

  const [modalData, setModalData] = useState({});

  const { currentLooseItem } = useLooseItemSelector();
  const { updateLooseItemAction, deleteItemAction } = useLooseItemActions();

  const { pendingApproval } = currentLooseItem;

  const moveBack = () => history.push(LOOSE_ITEMS_LIST_PATH);
  const editPart = () => history.push(`${LOOSE_ITEM_EDIT_PATH}/${currentLooseItem.id}`);
  const removePart = () => setModalData({ ...DELETE_LOOSE_ITEM, isOpened: true });

  const closeModal = () => setModalData({ isOpened: false });

  const agreeModal = () => {
    if (modalData.type === APPROVE_LOOSE_ITEM_TYPE) {
      updateLooseItemAction(
        { ...currentLooseItem, pendingApproval: false },
        { shouldRefreshCurrentLooseItem: true }
      );
      closeModal();
    } else {
      deleteItemAction(currentLooseItem.id);
    }
  };

  const approveLooseItem = () => setModalData({ ...APPROVE_LOOSE_ITEM, isOpened: true });

  const ApproveButton = () =>
    pendingApproval && (isAdminUser || isOperationsManagerUser) ? (
      <StyledButton
        label="Approve Creation"
        onClick={approveLooseItem}
        classes={styles.approveButton}
      />
    ) : null;

  return (
    <>
      <DetailsHeader
        breadcrumbs={[{ name: currentLooseItem.name }]}
        title={currentLooseItem?.name || ''}>
        <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
        <HeaderButton onClick={editPart}>
          <CreateIcon />
          {!isMobile && <span>Edit item</span>}
        </HeaderButton>
        {isAdminUser && (
          <HeaderButton onClick={removePart}>
            <DeleteIcon />
            {!isMobile && <span>Remove item</span>}
          </HeaderButton>
        )}
        {!isMobile && <ApproveButton />}
        {isMobile && (
          <HeaderButton onClick={moveBack}>
            <ChevronLeftIcon />
          </HeaderButton>
        )}
      </DetailsHeader>
      {isMobile && <ApproveButton />}
    </>
  );
}
