import React, { forwardRef } from 'react';
import styles from './Buttons.module.scss';

import { Button } from '@material-ui/core';
import clsx from 'clsx';

const BlueButton = forwardRef(
  ({ label, onClick, height, fullOnMobile, className, children }, ref) => {
    return (
      <Button
        style={{ height: height }}
        className={clsx(
          className && className,
          styles.blue_button,
          fullOnMobile && styles.fullOnMobile
        )}
        onClick={onClick}
        ref={ref}>
        {children}
        <span>{label}</span>
      </Button>
    );
  }
);

BlueButton.displayName = 'BlueButton';

export default BlueButton;
