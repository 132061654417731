import styles from './Components.module.scss';
import React from 'react';
import clsx from 'clsx';
import { PhotoGallery } from 'components/PhotoGallery';
import { dateTimeFormat, emptyValue, formatDate, formatPrice } from 'helpers/AppHelpers';
import { useLooseItemSelector } from 'hooks/LooseItems';
import { useUserConfig } from 'hooks/useUserConfig';
import StyledLink from 'components/StyledLink';
import { USER_LOGS_PATH } from 'constants/routeConstants';
import { useMobileViewport } from 'hooks/useMobileViewport';

export default function GeneralDetails() {
  const isMobile = useMobileViewport();
  const { isAdminUser } = useUserConfig();
  const { currentLooseItem } = useLooseItemSelector();
  const {
    resources,
    createdByUser,
    createdAtUtc,
    lastUpdatedByUser,
    lastUpdatedAtUtc,
    description,
    quantity,
    looseItemCategories,
    customFields,
    pricePerUnit,
    verificationInterval,
    id
  } = currentLooseItem;

  const generalDetailsData = [
    { label: 'Quantity', value: quantity || 0 },
    {
      label: 'Category',
      value: looseItemCategories?.map(({ name }) => name).join(', ') || ''
    },
    {
      label: 'Price per unit',
      value: pricePerUnit ? formatPrice(pricePerUnit) : ''
    },
    {
      label: 'Verification Interval',
      value: verificationInterval?.displayName || ''
    }
  ];
  if (customFields?.length) {
    customFields.forEach(({ name, value }) => {
      generalDetailsData.push({ label: name, value: value });
    });
  }

  const generateLogsUrl = () =>
    `${USER_LOGS_PATH}?entityId=${id}&historyEventTypes=MarkedAsNeedToBeVerified&historyEventTypes=MarkedAsVerified`;

  return (
    <div className={styles.mainInfoBlock}>
      <section className={styles.generalDetails}>
        <div className={styles.detailsBlock}>
          {generalDetailsData.map((details) => (
            <div key={details.label} className={styles.detailsItem}>
              <label>{details.label}:</label>
              <span>{details.value}</span>
            </div>
          ))}
        </div>
        <div className={clsx(styles.detailsItem, styles.descriptionBlock)}>
          <label>Description:</label>
          <span>{description}</span>
        </div>
      </section>
      <div className={styles.rightColumn}>
        <PhotoGallery resources={resources} />
        <div className={styles.editors}>
          {createdByUser?.firstName && (
            <p className={styles.editors__info}>
              Created {createdAtUtc ? formatDate(createdAtUtc, dateTimeFormat) : emptyValue} by{' '}
              {createdByUser.firstName} {createdByUser.lastName}
            </p>
          )}
          {lastUpdatedByUser?.firstName && (
            <p className={styles.editors__info}>
              Last Modified{' '}
              {lastUpdatedAtUtc ? formatDate(lastUpdatedAtUtc, dateTimeFormat) : emptyValue} by{' '}
              {lastUpdatedByUser.firstName} {lastUpdatedByUser.lastName}
            </p>
          )}
          {!isMobile && isAdminUser && (
            <div className={styles.editors__logs}>
              <StyledLink className={styles.editors__see_logs} to={generateLogsUrl()}>
                See Verification Logs
              </StyledLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
