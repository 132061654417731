import React, { useRef, useState } from 'react';
import styles from './SetVerificationStatusButton.module.scss';

import { BlueButton } from 'components/Buttons';
import MenuPopper from 'components/MenuPopper/MenuPopper';

import { MenuItem } from '@material-ui/core';

import { ReactComponent as NeedToVerifyIcon } from 'assets/images/toInspectIco.svg';
import { ReactComponent as MarkAsVerifiedIcon } from 'assets/images/inspectedIco.svg';

export function SetVerificationStatusButton({ menuItems = [], onMenuItemClick }) {
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  if (menuItems?.length === 0) return null;

  const handleClick = () => setOpenMenu(!openMenu);
  const closeMenu = () => setOpenMenu(false);

  const getMenuItemIcon = (name) => {
    const icons = {
      ['NeedToBeVerified']: NeedToVerifyIcon,
      ['MarkAsVerified']: MarkAsVerifiedIcon
    };

    return icons[name] || null;
  };

  const handleStatusClick = (status) => {
    onMenuItemClick(status);
    closeMenu();
  };

  return (
    <div className={styles.verification}>
      <BlueButton
        label="Set Verification Status"
        ref={anchorRef}
        onClick={handleClick}
        className={styles.verification__btn}
      />
      <MenuPopper
        open={openMenu}
        setOpen={setOpenMenu}
        anchorRef={anchorRef}
        classes={styles.verification__menu}>
        {menuItems.map((item) => (
          <MenuItem key={item.name} onClick={() => handleStatusClick(item)}>
            {React.createElement(getMenuItemIcon(item.name))}
            {item.displayName}
          </MenuItem>
        ))}
      </MenuPopper>
    </div>
  );
}
