import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentRequestOrderData } from '../selectors';
import {
  deleteEntity,
  getRequestOrder,
  updateEntities,
  updateEntity,
  updateRequestOrderStatus
} from 'actions/requestOrderActions';
import { useHistory, useParams } from 'react-router-dom';
import styles from './RequestOrderDetails.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import { REQUEST_ORDER_SUMMARY_PATH } from 'constants/routeConstants';
import {
  APPROVE_CANCELLING_REQUEST_ORDER,
  APPROVE_CANCELLING_REQUEST_ORDER_TYPE,
  DELETE_REQUEST_ORDER,
  DELETE_REQUEST_ORDER_ENTITY_TYPE,
  DELETE_REQUEST_ORDER_TYPE,
  REOPEN_REQUEST_ORDER,
  REOPEN_REQUEST_ORDER_TYPE
} from 'constants/dialogPopupsData';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import StatusButton from './components/StatusButton/StatusButton';
import DetailsSection from './components/DetailsSection/DetailsSection';
import RequestTable from './components/RequestTable/RequestTable';
import { selectUserConfig } from 'pages/commonSelectors';
import {
  isDecisionMakerUser,
  isOperationsManagerUser,
  isResponsibleForAccountUser
} from 'helpers/AppHelpers';
import { useMobileViewport } from 'hooks/useMobileViewport';

function RequestOrderDetails({
  userConfig,
  currentRequest,
  getRequestOrderAction,
  updateEntityAction,
  updateEntitiesAction,
  updateStatusAction,
  deleteEntityAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const { userRole, requestOrderRole, email } = userConfig;
  const history = useHistory();
  const breadcrumbs = useRef([{ name: `Request ${id}` }]);
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const isROCreatorUser = currentRequest?.createdByUser?.email === email;

  useEffect(() => {
    if (id) {
      getRequestOrderAction(id);
    }
  }, [id]);

  const onAgree = () => {
    if (dialogModalData.type === DELETE_REQUEST_ORDER_TYPE) {
      updateStatus({ name: 'Closed', displayName: 'Closed' });
    }
    if (dialogModalData.type === DELETE_REQUEST_ORDER_ENTITY_TYPE) {
      deleteRows(dialogModalData.deletedRows);
    }
    if (
      [APPROVE_CANCELLING_REQUEST_ORDER_TYPE, REOPEN_REQUEST_ORDER_TYPE].includes(
        dialogModalData.type
      )
    ) {
      updateStatus(dialogModalData.status);
    }
    setDialogModalData({ isOpened: false });
  };
  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };
  const isRequestOrderDeleted = () => currentRequest?.deleted;

  const updatePage = (res) => {
    if (res.id || res.status === 200) {
      getRequestOrderAction(id);
    }
  };

  const updateSingleEntity = (row) => {
    updateEntityAction(row).then((res) => updatePage(res));
  };
  const updateMultipleEntities = (rowsArray) => {
    updateEntitiesAction(rowsArray).then((res) => updatePage(res));
  };
  const deleteRows = (rowsIds) => {
    deleteEntityAction({ ids: rowsIds }).then((res) => updatePage(res));
  };
  const updateStatus = (status) => {
    const data = { id: +currentRequest.id, status: { ...status } };
    updateStatusAction(data).then((res) => updatePage(res));
  };

  const isStatus = (status) => currentRequest['status']?.name === status;
  const isCancelRequestAvailable = () =>
    isRequestOrderDeleted()
      ? false
      : (isDecisionMakerUser(requestOrderRole) ||
          isResponsibleForAccountUser(requestOrderRole) ||
          isOperationsManagerUser(userRole)) &&
        (isStatus('Pending') || isStatus('Approved'));

  const onApproveCancellingClick = (status) => {
    setDialogModalData({ ...APPROVE_CANCELLING_REQUEST_ORDER, status: status, isOpened: true });
  };

  const reopenRequestOrder = (status) => {
    setDialogModalData({ ...REOPEN_REQUEST_ORDER, status: status, isOpened: true });
  };

  return (
    <section className={styles.pageContainer}>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      {currentRequest?.id === +id && (
        <>
          {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs.current} />}
          <section className={styles.headerBlock}>
            {!isMobile && (
              <h1>{`Request ${currentRequest.id}${
                isRequestOrderDeleted() ? ' (Deleted)' : ''
              }`}</h1>
            )}
            <div className={styles.headerBlock__controls}>
              {isCancelRequestAvailable() && (
                <button
                  onClick={() => setDialogModalData({ ...DELETE_REQUEST_ORDER, isOpened: true })}>
                  <DeleteIcon />
                  {!isMobile && (
                    <span>
                      {isDecisionMakerUser(requestOrderRole)
                        ? 'Cancel request'
                        : 'Cancelation Request'}
                    </span>
                  )}
                </button>
              )}
              {isMobile && (
                <button onClick={() => history.push(REQUEST_ORDER_SUMMARY_PATH)}>
                  <ChevronLeftIcon />
                </button>
              )}
              {!isMobile && (
                <StatusButton
                  statusName={currentRequest['status']?.name || ''}
                  beforeCancelStatus={currentRequest['statusBeforeCancel'] || {}}
                  updateStatus={updateStatus}
                  userRole={userRole}
                  requestOrderRole={requestOrderRole}
                  onApproveCancellingClick={onApproveCancellingClick}
                  onReopenClick={reopenRequestOrder}
                  isRequestOrderDeleted={isRequestOrderDeleted}
                  shouldDisable={
                    currentRequest?.requestEntityDatas?.some(
                      ({ status }) => status?.name === 'InDelivery'
                    ) || false
                  }
                />
              )}
            </div>
          </section>
          {isMobile && (
            <h1>{`Request ${currentRequest.id}${isRequestOrderDeleted() ? ' (Deleted)' : ''}`}</h1>
          )}
          {isMobile && (
            <StatusButton
              statusName={currentRequest['status']?.name || ''}
              beforeCancelStatus={currentRequest['statusBeforeCancel'] || {}}
              updateStatus={updateStatus}
              userRole={userRole}
              requestOrderRole={requestOrderRole}
              onApproveCancellingClick={onApproveCancellingClick}
              onReopenClick={reopenRequestOrder}
              isRequestOrderDeleted={isRequestOrderDeleted}
              shouldDisable={currentRequest['requestEntityDatas'].some(
                ({ status }) => status?.name === 'InDelivery'
              )}
            />
          )}
          <DetailsSection requestData={currentRequest} />
          <RequestTable
            isMobile={isMobile}
            requestEntitiesData={currentRequest['requestEntityDatas'] || []}
            updateSingleEntity={updateSingleEntity}
            updateMultipleEntities={updateMultipleEntities}
            isStatus={isStatus}
            userRole={userRole}
            requestOrderRole={requestOrderRole}
            setDialogModalData={setDialogModalData}
            isRequestOrderDeleted={isRequestOrderDeleted}
            isROCreatorUser={isROCreatorUser}
          />
        </>
      )}
    </section>
  );
}

const mapStateToProps = createStructuredSelector({
  userConfig: selectUserConfig(),
  currentRequest: selectCurrentRequestOrderData()
});

const mapDispatchToProps = {
  getRequestOrderAction: getRequestOrder,
  updateEntityAction: updateEntity,
  updateEntitiesAction: updateEntities,
  updateStatusAction: updateRequestOrderStatus,
  deleteEntityAction: deleteEntity
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RequestOrderDetails);
