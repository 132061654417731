import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams } from 'react-router-dom';
import { setUnsavedFormData } from 'actions/commonActions';
import {
  getReportTemplateCreationForm,
  checkReportTemplateName,
  createReportTemplate,
  updateReportTemplate,
  getReportTemplate,
  clearActiveTemplate,
  clearReportListState
} from 'actions/reportListActions';
import {
  selectReportCreationFormData,
  selectSingleReportTemplate,
  selectActiveReportTemplate
} from '../../selectors';
import { allKeyboardLatSymbols, enrichNameToId, sortByParam } from 'helpers/AppHelpers';
import ReportConstructor from './ReportConstructor';
import { Input } from '@material-ui/core';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import { MainButton } from 'components/StyledComponents';
import SelectInput from 'components/SelectInput/SelectInput';
import { REPORT_TEMPLATE_LIST_PATH } from 'constants/routeConstants';
import {
  EXIT_WITHOUT_SAVING,
  SAVE_REPORT_TEMPLATE,
  PUBLISH_REPORT_TEMPLATE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE,
  SAVE_REPORT_TEMPLATE_TYPE,
  PUBLISH_REPORT_TEMPLATE_TYPE
} from 'constants/dialogPopupsData';
import styles from './ReportTemplateEditor.module.scss';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

const breadcrumbs = [
  {
    path: REPORT_TEMPLATE_LIST_PATH,
    name: 'Report Template List'
  },
  { name: 'Report editor' }
];

function ReportTemplateEditor({
  activeReportTemplate,
  setUnsavedFormDataAction,
  reportCreationFormData,
  getReportTemplateCreationFormAction,
  checkReportTemplateNameAction,
  reportTemplateData,
  getReportTemplateAction,
  createReportTemplateAction,
  updateReportTemplateAction,
  clearActiveTemplateAction,
  clearReportEditorAction
}) {
  const { id } = useParams();
  const [isResponseReceived, setResponseReceived] = useState(false);
  const history = useHistory();
  const [preconditionState, setPreconditionState] = useState(null);
  const [nameIsInvalid, setNameIsInvalid] = useState(false);
  const [routeUrl, setRouteUrl] = useState('');
  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const [values, setValues] = useState({});
  const [initValues, setInitValues] = useState({});

  const {
    assetCategories,
    generalReportTypes,
    locations,
    generalReportEntities,
    looseItemCategories
  } = reportCreationFormData;

  const setTemplateData = (template) => {
    setPreconditionState(!template.published);
    setValues(template);
    setInitValues(template);
  };

  useEffect(() => {
    if (!Object.keys(reportCreationFormData).length) {
      getReportTemplateCreationFormAction().then(() => setResponseReceived(true));
    }
  }, [reportCreationFormData]);

  useEffect(() => {
    if (id) {
      getReportTemplateAction(id);
    } else {
      setPreconditionState(true);
    }
  }, [id]);

  useEffect(() => {
    if (id && reportTemplateData?.id) {
      setTemplateData(reportTemplateData);
    }
  }, [reportTemplateData?.id, reportCreationFormData]);

  useEffect(() => {
    if (activeReportTemplate.reportType) {
      setTemplateData(activeReportTemplate);
    }
  }, [activeReportTemplate]);

  useEffect(() => {
    return () => {
      clearActiveTemplateAction();
      clearReportEditorAction();
    };
  }, []);

  const reportTypes = enrichNameToId(
    generalReportTypes?.map(({ generalReportType }) => generalReportType)
  );

  const isNextDisabled = () => {
    if (!values.name?.length || !values.reportType?.name) return true;

    const { reportType, assetCategories, locations, looseItemCategories, reportEntity } = values;

    switch (reportType.name) {
      case 'Asset':
        return assetCategories.length === 0;
      case 'Location':
        return locations.length === 0;
      case 'General':
        return reportEntity?.name
          ? assetCategories.length === 0 &&
              locations.length === 0 &&
              looseItemCategories.length === 0
          : false;
      default:
        return true;
    }
  };

  const getAssetPrefixes = () => {
    const categoryIdsArray = values.assetCategories.map(({ id }) => id);

    const data =
      assetCategories
        ?.filter(({ id }) => categoryIdsArray?.includes(id))
        ?.flatMap(({ assetPrefixes }) => assetPrefixes) || [];

    return sortByParam(data, 'prefix');
  };

  const getLooseItems = () => {
    const categoryIdsArray = values.looseItemCategories.map(({ id }) => id);

    const data =
      looseItemCategories
        ?.filter(({ id }) => categoryIdsArray?.includes(id))
        ?.flatMap(({ looseItems }) => looseItems) || [];

    return sortByParam(data, 'name');
  };

  const getLocationTemplates = () =>
    generalReportTypes?.find(({ generalReportType: { name } }) => name === 'Location')
      ?.generalReportFields;

  const onInput = (event) => {
    const key = event.target.name;
    let value = event.target.value;

    value = value.substr(0, 1000);
    value = value.replace(allKeyboardLatSymbols, '');

    setValues({
      ...values,
      [key]: value
    });
    setNameIsInvalid(false);
  };

  const checkTemplateName = (reportName, reportTypeName) => {
    if (reportName && reportTypeName) {
      checkReportTemplateNameAction({
        reportName,
        reportTypeName
      }).then((res) => {
        setNameIsInvalid(!res.isAvailable);
        setInitValues({ ...initValues, name: res.reportTemplateName });
      });
    }
  };

  const onSelectReportType = (selectedItem) => {
    setValues({
      ...values,
      assetCategories: [],
      assetPrefixes: [],
      locations: [],
      generalReportFields: [],
      looseItemCategories: [],
      looseItems: [],
      reportEntity: null,
      [selectedItem.name]: selectedItem.value
    });
    checkTemplateName(values?.name, selectedItem?.value?.name);
  };

  const onSelectReportEntity = ({ name, value }) => {
    setValues({
      ...values,
      locations: [],
      assetCategories: [],
      assetPrefixes: [],
      looseItemCategories: [],
      looseItems: [],
      [name]: value
    });
  };

  const filterCurrentPrefixes = (assetCategories) => {
    const prefixIdsArray = assetCategories?.flatMap(({ assetPrefixes }) =>
      assetPrefixes.map(({ id }) => id)
    );
    return values.assetPrefixes.filter(({ id }) => prefixIdsArray.includes(id));
  };
  const filterCurrentLooseItems = (categories) => {
    const looseItemIdsArray = categories?.flatMap(({ looseItems }) =>
      looseItems.map(({ id }) => id)
    );
    return values.looseItems.filter(({ id }) => looseItemIdsArray.includes(id));
  };

  const handleSelectClick = (name, value) => {
    if (name === 'assetCategories') {
      setValues({
        ...values,
        assetPrefixes: filterCurrentPrefixes(value),
        [name]: [...value?.sort((a, b) => a.id - b.id)]
      });
    }
    if (name === 'assetPrefixes') {
      setValues({
        ...values,
        [name]: [...value?.sort((a, b) => a.id - b.id)]
      });
    }
    if (name === 'locations') {
      setValues({ ...values, [name]: value });
    }
    if (name === 'looseItemCategories') {
      setValues({
        ...values,
        looseItems: filterCurrentLooseItems(value),
        [name]: [...value?.sort((a, b) => a.id - b.id)]
      });
    }
    if (name === 'looseItems') {
      setValues({
        ...values,
        [name]: [...value?.sort((a, b) => a.id - b.id)]
      });
    }
  };
  const handleCheckboxClick = (name, value) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const formIsNotChanged = JSON.stringify(initValues) === JSON.stringify(values);

  useEffect(() => {
    setUnsavedFormDataAction(!formIsNotChanged);
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [values, initValues]);

  const onSave = () => {
    setDialogModalData({ ...SAVE_REPORT_TEMPLATE, isOpened: true });
  };

  const onPublish = () => {
    if (values.published) {
      setValues({ ...values, published: false });
      setPreconditionState(true);
    } else setDialogModalData({ ...PUBLISH_REPORT_TEMPLATE, isOpened: true });
  };

  const onCancel = () => {
    if (!formIsNotChanged) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else history.goBack();
  };

  const onAgree = () => {
    const getOrderedFields = () => {
      return {
        ...values,
        generalReportFields: values.generalReportFields.map((field, index) => {
          return { ...field, id: 0, order: index };
        })
      };
    };

    switch (dialogModalData.type) {
      case SAVE_REPORT_TEMPLATE_TYPE:
        id || values.id
          ? updateReportTemplateAction(getOrderedFields()).then((res) => {
              setValues({ ...res });
              setInitValues({ ...res });
            })
          : createReportTemplateAction(getOrderedFields()).then((res) => {
              setValues({ ...res });
              setInitValues({ ...res });
            });
        setDialogModalData({ isOpened: false });
        break;
      case PUBLISH_REPORT_TEMPLATE_TYPE:
        id || values.id
          ? updateReportTemplateAction({
              ...getOrderedFields(),
              published: true
            })
          : createReportTemplateAction({ ...getOrderedFields(), published: true });
        setDialogModalData({ isOpened: false });
        break;
      case WITHOUT_SAVING_TYPE:
        history.goBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(routeUrl);
        break;
      default:
        break;
    }
  };

  const onDismiss = () => {
    setDialogModalData({ isOpened: false });
  };

  const Breadcrumbs = () => (
    <BreadcrumbsNav
      itemsArray={breadcrumbs}
      formIsChanged={!formIsNotChanged}
      setDialogModalData={setDialogModalData}
      setRouteUrl={setRouteUrl}
    />
  );

  const isDataReceived = () => isResponseReceived;

  return (
    <>
      <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
      {preconditionState === true && isDataReceived() ? (
        <section className={`${styles.pageContainer} ${styles.precondition}`}>
          <Breadcrumbs />
          <div className={styles.pageHeader}>
            <h1>Report Editor</h1>
          </div>
          <section className={styles.mainInfoBlock}>
            <section className={styles.mainInfoBlock__container}>
              <h3>Report name*</h3>
              <Input
                name="name"
                className={`${styles.textInput} ${nameIsInvalid && styles.error}`}
                variant="outlined"
                value={values.name || ''}
                onChange={onInput}
                onBlur={(e) => checkTemplateName(e.target.value, values?.reportType?.name)}
              />
              {nameIsInvalid && (
                <p className={styles.errorHint}>Report template with such name already exists</p>
              )}
              <section className={styles.formCell}>
                <label>Report type*</label>
                <div className={styles.inputWrapper}>
                  <SelectInput
                    name="reportType"
                    value={values.reportType?.name || ''}
                    menuItems={reportTypes || []}
                    onSelect={onSelectReportType}
                    disabled={values.publishedAtUtc}
                  />
                </div>
              </section>

              {values.reportType?.name === 'General' && (
                <>
                  <h3 className={styles.entityTitle}>Related Entity</h3>

                  <section className={styles.formCell}>
                    <label>Select Entity</label>
                    <div className={styles.inputWrapper}>
                      <SelectInput
                        clearable
                        name="reportEntity"
                        value={values.reportEntity?.id || ''}
                        menuItems={generalReportEntities || []}
                        onSelect={onSelectReportEntity}
                      />
                    </div>
                  </section>
                </>
              )}

              {(values.reportType?.name === 'Asset' ||
                (values.reportType?.name === 'General' &&
                  values.reportEntity?.name === 'Asset')) && (
                <>
                  <section className={styles.formCell}>
                    <label>Category*</label>
                    <div className={styles.inputWrapper}>
                      <InputSearchWithMultiselect
                        name="assetCategories"
                        defaultValue={values?.assetCategories.map(({ id }) => id) || []}
                        options={assetCategories || []}
                        onFilterSelect={handleSelectClick}
                        multiselect={true}
                      />
                      <p>Select from the list</p>
                    </div>
                  </section>

                  <section className={styles.formCell}>
                    <label>Prefixes</label>
                    <div className={styles.inputWrapper}>
                      <InputSearchWithMultiselect
                        name="assetPrefixes"
                        defaultValue={values?.assetPrefixes.map(({ id }) => id) || []}
                        options={getAssetPrefixes() || []}
                        onFilterSelect={handleSelectClick}
                        multiselect={true}
                        disabled={!values.assetCategories.length}
                      />
                      <p>Select from the list</p>
                    </div>
                  </section>
                </>
              )}

              {(values.reportType?.name === 'Location' ||
                (values.reportType?.name === 'General' &&
                  values.reportEntity?.name === 'Location')) && (
                <section className={styles.formCell}>
                  <label>Locations*</label>
                  <div className={styles.inputWrapper}>
                    <InputSearchWithMultiselect
                      name="locations"
                      defaultValue={values?.locations.map(({ id }) => id) || []}
                      options={locations || []}
                      onFilterSelect={handleSelectClick}
                      multiselect={true}
                    />
                    <p>Select from the list</p>
                  </div>
                </section>
              )}

              {values.reportType?.name === 'General' &&
                values.reportEntity?.name === 'LooseItem' && (
                  <>
                    <section className={styles.formCell}>
                      <label>Category*</label>
                      <div className={styles.inputWrapper}>
                        <InputSearchWithMultiselect
                          name="looseItemCategories"
                          defaultValue={values.looseItemCategories?.map(({ id }) => id) || []}
                          options={looseItemCategories || []}
                          onFilterSelect={handleSelectClick}
                          multiselect={true}
                        />
                        <p>Select from the list</p>
                      </div>
                    </section>

                    <section className={styles.formCell}>
                      <label>Loose Item</label>
                      <div className={styles.inputWrapper}>
                        <InputSearchWithMultiselect
                          name="looseItems"
                          defaultValue={values?.looseItems.map(({ id }) => id) || []}
                          options={getLooseItems() || []}
                          onFilterSelect={handleSelectClick}
                          multiselect={true}
                          disabled={!values.looseItemCategories.length}
                        />
                        <p>Select from the list</p>
                      </div>
                    </section>
                  </>
                )}

              {values.reportType?.name === 'Asset' && (
                <>
                  <section className={styles.checkboxCell}>
                    <label>Use as Default Report Template</label>
                    <CustomCheckbox
                      name="isDefault"
                      value={values?.isDefault || false}
                      onChange={handleCheckboxClick}
                    />
                  </section>
                  <section className={styles.checkboxCell}>
                    <label>Mark asset as inspected after submitting</label>
                    <CustomCheckbox
                      name="isMarkAsInspected"
                      value={values?.isMarkAsInspected || false}
                      onChange={handleCheckboxClick}
                    />
                  </section>
                </>
              )}

              {values.reportType?.name === 'Location' && (
                <>
                  <section className={styles.checkboxCell}>
                    <label>Use as Default Report Template</label>
                    <CustomCheckbox
                      name="isDefault"
                      value={values?.isDefault || false}
                      onChange={handleCheckboxClick}
                    />
                  </section>
                  <section className={styles.checkboxCell}>
                    <label>Available for Project Report</label>
                    <CustomCheckbox
                      name="isAvailableForProject"
                      value={values?.isAvailableForProject || false}
                      onChange={handleCheckboxClick}
                    />
                  </section>
                </>
              )}
            </section>
            <section className={styles.controlButtons}>
              <MainButton text="Cancel" action={onCancel} type="secondary" size="popup" />
              <MainButton
                text="Next"
                action={() => setPreconditionState(false)}
                type="primary"
                size="popup"
                isDisabled={isNextDisabled() || nameIsInvalid}
              />
            </section>
          </section>
        </section>
      ) : (
        preconditionState === false && (
          <ReportConstructor
            reportState={values}
            Breadcrumbs={Breadcrumbs}
            locationTemplates={getLocationTemplates()}
            setReportState={setValues}
            backClickHandle={setPreconditionState}
            onSave={onSave}
            onPublish={onPublish}
          />
        )
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  reportCreationFormData: selectReportCreationFormData(),
  activeReportTemplate: selectActiveReportTemplate(),
  reportTemplateData: selectSingleReportTemplate()
});

const mapDispatchToProps = {
  setUnsavedFormDataAction: setUnsavedFormData,
  getReportTemplateCreationFormAction: getReportTemplateCreationForm,
  checkReportTemplateNameAction: checkReportTemplateName,
  getReportTemplateAction: getReportTemplate,
  createReportTemplateAction: createReportTemplate,
  updateReportTemplateAction: updateReportTemplate,
  clearActiveTemplateAction: clearActiveTemplate,
  clearReportEditorAction: clearReportListState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReportTemplateEditor);
