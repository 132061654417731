import React from 'react';
import styles from './HeadRows.module.scss';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';

import { useTrackerSelector } from 'hooks/TeamMemberTracker';
import { formatMonth } from 'helpers/AppHelpers';
import clsx from 'clsx';

export default function MonthRow() {
  const { trackerTableData } = useTrackerSelector();
  const { header } = trackerTableData;

  const getMonths = ({ day }) => formatMonth(day);
  const data = header?.map(getMonths) || [];

  const isFirstMonthCell = (index) => !(index !== 0 && data[index] === data[index - 1]);

  const getMonthColSpan = (index, match) => {
    let finalLength = 0;
    for (let i = index; i < data.length; i += 1) {
      if (data[i] === match) finalLength += 1;
      else break;
    }
    return finalLength;
  };

  return (
    <StyledTableRow>
      <StyledTableCell className={clsx(styles.initialCell, styles.month)}></StyledTableCell>
      {data.map((cell, index) => (
        <React.Fragment key={index}>
          {isFirstMonthCell(index) && (
            <StyledTableCell className={styles.monthCell} colSpan={getMonthColSpan(index, cell)}>
              <div className={styles.monthBlock}>
                <span>{cell}</span>
              </div>
            </StyledTableCell>
          )}
        </React.Fragment>
      ))}
    </StyledTableRow>
  );
}
