import React from 'react';
import styles from './Footer.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

export function Footer({ onMove, isFormInvalid }) {
  return (
    <div className={styles.footer}>
      <MainButton text="move" type="primary" action={onMove} isDisabled={isFormInvalid} />
    </div>
  );
}
