import React, { useState } from 'react';
import styles from './styles.module.scss';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { FormProvider, useForm } from 'react-hook-form';
import { useBatchItemUpdatesActions } from 'hooks/BatchItemUpdates';

import { getErrorsProperties } from 'helpers/ErrorValidator';

import { UPDATE_LOOSE_ITEMS_BATCH_UPDATES } from 'constants/dialogPopupsData';

import Footer from './components/Footer';
import Form from './components/Form';

import { AT_LEAST_ONE_FIELD_ERROR_MESSAGE, CONNECTED_FIELD_ERROR_NAMES } from '../helpers';

export function UpdateLooseItems() {
  const [modalData, setModalData] = useState({});

  const methods = useForm({
    defaultValues: {
      looseItems: [],
      customFields: [],
      looseItemCategories: []
    },
    mode: 'onChange'
  });
  const { getValues, setError, reset } = methods;

  const { updateLooseItemsAction } = useBatchItemUpdatesActions();

  const validateForm = () => {
    let isFormValid = true;
    const values = getValues();

    if (!values?.looseItems?.length) {
      setError('looseItems', getErrorsProperties('Loose items are required'));
      isFormValid = false;
    }
    if (!values?.looseItemCategories?.length && !values?.customFields?.length) {
      CONNECTED_FIELD_ERROR_NAMES.forEach((name) =>
        setError(name, getErrorsProperties(AT_LEAST_ONE_FIELD_ERROR_MESSAGE))
      );
      isFormValid = false;
    }

    return isFormValid;
  };

  const handleSuccess = (res) => {
    res.status === 200 && reset();
    closeModal();
  };

  const closeModal = () => setModalData({});
  const agreeModal = () => updateLooseItemsAction(getValues()).then(handleSuccess);

  const handleUpdateClick = () => {
    if (!validateForm()) return;

    setModalData(UPDATE_LOOSE_ITEMS_BATCH_UPDATES);
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.container}>
        <Form />

        <Footer onUpdate={handleUpdateClick} />
      </div>

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
    </FormProvider>
  );
}
