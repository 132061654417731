const errorMessages = {
  destination: {
    sameLocation:
      'The “Destination”, “Sublocation” and “Asset” values must be different from “Current Location (Sublocation) [Asset]” value.',
    empty: 'The “Destination” value is required'
  },
  moveQuantity: {
    exceedsAvailableQuantity:
      'The “Move Total QTY” value must not exceed “Available QTY(BAA*)” value.'
  },
  moveByAheadAccount: {
    exceedsMoveQuantity: '“From BAA*” value must not exceed “Move Total QTY”',
    exceedsAvailableBaaQuantity:
      'The “From BAA* QTY” value must not exceed “Available QTY(BAA*)” value in the brackets.'
  }
};

const isDestinationValid = ({
  location = {},
  sublocation = {},
  containedInAsset = {},
  destinationLocation = {},
  destinationSublocation = {},
  destinationAsset = {}
}) => {
  const currentIds = [location?.id, sublocation?.id, containedInAsset?.id];
  const destinationIds = [
    destinationLocation?.id,
    destinationSublocation?.id,
    destinationAsset?.id
  ];

  const areAddressesIdentical = currentIds.every(
    (currentId, index) => currentId === destinationIds[index]
  );

  return !areAddressesIdentical;
};

const isMoveByAheadAccountQuantityValid = ({
  moveQuantity = 0,
  moveByAheadAccount = 0,
  byAheadAccount = 0
}) => moveByAheadAccount <= moveQuantity && moveByAheadAccount <= byAheadAccount;

const checkMoveByAheadAccount = ({
  moveQuantity = 0,
  moveByAheadAccount = 0,
  byAheadAccount = 0
}) => {
  if (moveByAheadAccount > moveQuantity) {
    return { invalid: true, error: errorMessages.moveByAheadAccount.exceedsMoveQuantity };
  } else if (moveByAheadAccount > byAheadAccount) {
    return { invalid: true, error: errorMessages.moveByAheadAccount.exceedsAvailableBaaQuantity };
  } else {
    return { invalid: false };
  }
};

const isMoveQuantityValid = ({ moveQuantity, quantity }) => moveQuantity <= quantity;

const checkRowForWarnings = ({
  moveQuantity = 0,
  moveByAheadAccount = 0,
  quantity = 0,
  byAheadAccount = 0
}) => +moveQuantity - +moveByAheadAccount > +quantity - +byAheadAccount;

export {
  errorMessages,
  isDestinationValid,
  isMoveByAheadAccountQuantityValid,
  checkMoveByAheadAccount,
  isMoveQuantityValid,
  checkRowForWarnings
};
