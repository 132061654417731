import React from 'react';
import styles from './Wrapper.module.scss';

import DetailsHeader from 'components/DetailsComponents/Header';

export function Wrapper({ children }) {
  return (
    <div className={styles.wrapper}>
      <DetailsHeader title="Loose Items Batch Update" breakWordOnly />

      {children}
    </div>
  );
}
