import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import Wrapper from './Wrapper';
import Content from './Content';

export function MoveLooseItems() {
  const methods = useForm({
    defaultValues: {
      moveLooseItems: [],
      warnings: []
    },
    mode: 'onChange'
  });

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Content />
      </Wrapper>
    </FormProvider>
  );
}
