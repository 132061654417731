import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getLooseItemDashboardConfigApi = () =>
  api.get(`/User/LooseItemDashboardsConfig`).then((res) => res.data);

export const updateLooseItemDashboardConfigApi = (data) =>
  api.put(`/User/LooseItemDashboardsConfig`, data).then((res) => res.data);

export const getLooseItemDashboardTablesDataApi = (query) =>
  api.get(`/LooseItemDashboards${queryToString(query)}`).then((res) => res.data);
