import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getLooseItemsApi = (query) =>
  api.get(`/LooseItemTentParts?${queryToString(query)}`).then((res) => res.data);

export const getLooseItemCountingApi = (query) =>
  api.get(`/LooseItems/Counting?${queryToString(query)}`).then((res) => res.data);

export const getCategoriesApi = () =>
  api.get('/LooseItemCategories/Shorted').then((res) => res.data);

export const getLooseItemCustomFieldsApi = (query) =>
  api.get(`/LooseItemCustomFields?${queryToString(query)}`).then((res) => res.data);

export const updateLooseItemTableRowApi = (params) =>
  api.put(`/LooseItemTentPart`, params).then((res) => res.data);

export const getSingleLooseItemApi = (id) => api.get(`/LooseItem?id=${id}`).then((res) => res.data);

export const createLooseItemApi = (params, query) =>
  api.post(`/LooseItemTentPart?${queryToString(query)}`, params).then((res) => res.data);

export const deleteLooseItemApi = (id) => api.delete(`/LooseItem?id=${id}`).then((res) => res.data);

export const updateLooseItemApi = (params) => api.put(`/LooseItem`, params).then((res) => res.data);

export const getFilterCriteriaApi = () =>
  api.get('LooseItems/FilterCriteria').then((res) => res.data);

export const getSummaryCsvResourceApi = (q) =>
  api.get(`/LooseItems/Serialized?${queryToString(q)}`).then((res) => res.data);

export const getCreationFormApi = () => api.get('/LooseItem/CreationForm').then((res) => res.data);

export const setVerificationStatusApi = (data) =>
  api.put('/LocationLooseItems/Verification', data).then((res) => res.data);
