const tableHeadConfig = [
  { id: 2, label: 'Loose Items' },
  { id: 3, label: 'Current Location (Sublocation) [Asset]' },
  { id: 4, label: 'Available QTY (BAA)', footnote: '[1]' },
  { id: 5, label: 'Destination' },
  { id: 6, label: 'Sublocation' },
  { id: 7, label: 'Contained In' },
  { id: 8, label: 'Move Total QTY' },
  { id: 9, label: 'From BAA', footnote: '[1]' },
  { id: 10, label: '' }
];

export { tableHeadConfig };
