import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import styles from '../Components.module.scss';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { ASSET_DETAILED_PATH, LOCATION_DETAILED_PATH } from 'constants/routeConstants';
import React, { useMemo } from 'react';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { formatPrice } from 'helpers/AppHelpers';
import clsx from 'clsx';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { tableHeadConfig } from '../../tableConfigs';
import { TableHeadComponent } from 'components/SummaryComponents';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import { useUserConfig } from 'hooks/useUserConfig';

export default function LooseItemsTable({
  tableBodyData,
  isUnitsCostsColumnAvailable,
  totalQuantity,
  totalUnitsCosts,
  selectionState,
  isStatusVerifiable
}) {
  const { isBasicRoleUser, isPersonnelManagerUser } = useUserConfig();
  const isSelectionAvailable = !isBasicRoleUser && !isPersonnelManagerUser;

  const { isAllSelected, toggleAllSelection, isRowSelected, toggleRowSelection } = selectionState;

  const canDisplayCell = (rowId, rowIndex) =>
    !rowId ||
    rowIndex === 0 ||
    !(tableBodyData[rowIndex]?.location?.id === tableBodyData[rowIndex - 1]?.location?.id);

  const getColumnSpan = (rowLocationId) =>
    tableBodyData.filter(({ location }) => location.id === rowLocationId).length;

  const createTableConfig = (isAllSelected, toggleAllSelection) => {
    let config = [...tableHeadConfig];

    if (isSelectionAvailable) {
      config = [
        {
          label: '',
          renderContent: () => (
            <CustomCheckbox
              sendEvent
              isDisabled={tableBodyData.length === 0}
              value={isAllSelected()}
              onChange={(e) => toggleAllSelection(e.target.checked)}
            />
          )
        },
        ...config
      ];
    }

    if (isUnitsCostsColumnAvailable) {
      const insertIndex = config.findIndex((col) => col.label === 'Current QTY') + 1;
      config = [
        ...config.slice(0, insertIndex),
        { id: 11, label: 'Units Costs' },
        ...config.slice(insertIndex)
      ];
    }

    return config;
  };

  const tableConfig = useMemo(
    () => createTableConfig(isAllSelected, toggleAllSelection),
    [isAllSelected, toggleAllSelection, isSelectionAvailable]
  );

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHeadComponent config={tableConfig} />
        <TableBody>
          {tableBodyData?.length ? (
            <>
              {tableBodyData.map((rowData, rowIndex) => (
                <StyledTableRow key={rowData.id}>
                  {isSelectionAvailable && (
                    <StyledTableCell className={styles.checkboxCell}>
                      {isStatusVerifiable(rowData) && (
                        <CustomCheckbox
                          sendEvent
                          value={isRowSelected(rowIndex)}
                          onChange={(e) => toggleRowSelection(rowIndex, e.target.checked)}
                        />
                      )}
                    </StyledTableCell>
                  )}
                  <StyledTableCell className={styles.locationCell}>
                    <LinkComponent
                      path={`${LOCATION_DETAILED_PATH}/${rowData.location?.id}`}
                      name={rowData.location?.siteCode}
                      isRedirectAvailable={!rowData.location?.deleted}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={styles.sublocationCell}>
                    {rowData?.sublocation?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.containedInCell}>
                    <LinkComponent
                      path={`${ASSET_DETAILED_PATH}/${rowData.containedInAsset?.id}`}
                      name={rowData.containedInAsset?.drCode}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={styles.noteCell}>
                    {rowData?.notes || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.statusCell}>
                    {rowData?.status?.displayName || ''}
                    {rowData?.status?.displayName === 'In Picklist' &&
                      ` (${rowData?.picklist?.name})`}
                    {rowData?.status?.displayName === 'In Leavelist' &&
                      ` (${rowData?.leavelist?.name})`}
                  </StyledTableCell>
                  <StyledTableCell className={styles.minQtyCell}>
                    {rowData?.minimumQuantity || 0}
                  </StyledTableCell>
                  <StyledTableCell className={styles.byAheadAccountCell}>
                    {rowData?.byAheadAccount || 0}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      styles.currentQtyCell,
                      rowData?.needsVerification && styles.warning
                    )}>
                    <div className={styles.qtyContent}>
                      {rowData.quantity || 0}
                      {rowData?.needsVerification && <InfoTooltip text="Needs to be verified" />}
                    </div>
                  </StyledTableCell>
                  {isUnitsCostsColumnAvailable && (
                    <StyledTableCell className={styles.unitsCostsCell}>
                      {formatPrice(rowData.unitCosts)}
                    </StyledTableCell>
                  )}
                  {canDisplayCell(rowData.id, rowIndex) && (
                    <StyledTableCell
                      rowSpan={rowData?.location?.id && getColumnSpan(rowData.location.id)}
                      className={clsx(styles.locationQtyCell, styles.merged)}>
                      {rowData.locationQuantity || 0}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableCell
                  colSpan={isSelectionAvailable ? 9 : 8}
                  style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  Totals:
                </StyledTableCell>
                {isUnitsCostsColumnAvailable && (
                  <StyledTableCell className={styles.unitsCostsCell} style={{ fontWeight: 'bold' }}>
                    {formatPrice(totalUnitsCosts)}
                  </StyledTableCell>
                )}
                <StyledTableCell className={styles.locationQtyCell} style={{ fontWeight: 'bold' }}>
                  {totalQuantity || 0}
                </StyledTableCell>
              </StyledTableRow>
            </>
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={10} className={styles.emptyTable}>
                No available data in the table.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
