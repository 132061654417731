import {
  CLEAR_BATCH_ITEMS_UPDATES_STATE,
  SET_BATCH_ITEMS_UPDATES_CREATION_FORM,
  SET_BATCH_ITEMS_UPDATES_SELECTED_TAB
} from 'constants/reduceConstants';

const initialState = {
  creationForm: {},
  selectedTab: ''
};

const batchItemUpdatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BATCH_ITEMS_UPDATES_CREATION_FORM:
      return { ...state, creationForm: payload };
    case SET_BATCH_ITEMS_UPDATES_SELECTED_TAB:
      return { ...state, selectedTab: payload };
    case CLEAR_BATCH_ITEMS_UPDATES_STATE:
      return { ...initialState, formState: state.formState };
    default:
      return state;
  }
};

export default batchItemUpdatesReducer;
