import {
  CLEAR_LOOSE_ITEMS_STATE,
  SET_CURRENT_SINGLE_ITEM,
  SET_LOAD_MORE_FLAG,
  SET_LOOSE_ITEMS_FILTER,
  SET_CHECKED_LOOSE_ITEMS,
  SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS,
  SET_LOOSE_ITEMS_FILTER_CRITERIA,
  SET_LOOSE_ITEMS_CREATION_FORM
} from '../constants/reduceConstants';
import api from '../api';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  LOOSE_ITEM_REQUIRES_APPROVAL,
  SUCCESS_CREATE_LOOSE_ITEM,
  SUCCESS_DELETE_LOOSE_ITEM,
  SUCCESS_UPDATE_LOOSE_ITEM
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { LOOSE_ITEM_DETAILED_PATH, LOOSE_ITEMS_LIST_PATH } from '../constants/routeConstants';

// Summary
export const getLooseItems = (query) => (dispatch) =>
  api.looseItem
    .getLooseItemsApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getLooseItemCounting = (query) => () =>
  api.looseItem
    .getLooseItemCountingApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getFilterCriteria = () => (dispatch) =>
  api.looseItem
    .getFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_LOOSE_ITEMS_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const updateLooseItemTableRow = (data) => () =>
  api.looseItem.updateLooseItemTableRowApi(data).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const setCheckedItems = (payload) => (dispatch) => {
  dispatch({ type: SET_CHECKED_LOOSE_ITEMS, payload });
};

export const setCustomColumns = (payload) => (dispatch) => {
  dispatch({ type: SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS, payload });
};

export const setFilter = (filter) => (dispatch) => {
  dispatch({ type: SET_LOOSE_ITEMS_FILTER, payload: filter });
};

export const getSummaryCsvResource = (q) => () =>
  api.looseItem
    .getSummaryCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

// Details
export const getLooseItem = (id) => (dispatch) =>
  api.looseItem
    .getSingleLooseItemApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_SINGLE_ITEM, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(LOOSE_ITEMS_LIST_PATH);
    });

export const deleteLooseItem = (id) => () =>
  api.looseItem.deleteLooseItemApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_LOOSE_ITEM);
      browserHistory.push(LOOSE_ITEMS_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

// Create/Edit Screen
export const getCreationForm = () => (dispatch) =>
  api.looseItem
    .getCreationFormApi()
    .then((res) => dispatch({ type: SET_LOOSE_ITEMS_CREATION_FORM, payload: res }))
    .catch((err) => console.log(err));

export const createLooseItem = (data, query) => () =>
  api.looseItem.createLooseItemApi(data, query).then(
    (res) => {
      browserHistory.push(`${LOOSE_ITEM_DETAILED_PATH}/${res.id}`);
      if (res.pendingApproval) {
        enqueueSuccessSnackbar(LOOSE_ITEM_REQUIRES_APPROVAL);
      } else {
        enqueueSuccessSnackbar(SUCCESS_CREATE_LOOSE_ITEM);
      }
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const updateLooseItem =
  (data, { shouldRefreshCurrentLooseItem = false } = {}) =>
  (dispatch) =>
    api.looseItem.updateLooseItemApi(data).then(
      (res) => {
        enqueueSuccessSnackbar(SUCCESS_UPDATE_LOOSE_ITEM);
        browserHistory.push(`${LOOSE_ITEM_DETAILED_PATH}/${res.id}`);
        if (shouldRefreshCurrentLooseItem) {
          dispatch({ type: SET_CURRENT_SINGLE_ITEM, payload: res });
        }
      },
      (err) => {
        const errors = JSON.parse(err.request.response);
        if (errors?.errors && 'Id' in errors.errors) {
          enqueueErrorSnackbar('', errors);
        }
        return errors;
      }
    );

// Common
export const getCustomFields = (q) => () =>
  api.looseItem
    .getLooseItemCustomFieldsApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setVerificationStatus = (data) => () =>
  api.looseItem
    .setVerificationStatusApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_LOOSE_ITEMS_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};
