export const ROOT_PATH = '/';
export const NOT_FOUND_PATH = '/not_found';
export const SANDBOX_PATH = '/sandbox';
export const HOME_PATH = '/home';
export const RELEASE_NOTES_PATH = '/release_notes';

export const LOGIN_PATH = '/login';

export const SEARCH_PATH = '/search';

export const DASHBOARD_PATH = '/dashboard';
export const ASSETS_VERIFICATION_AND_INSPECTION_DASHBOARDS_PATH = '/assets_verification_inspection';

export const CATEGORY_LIST_PATH = '/category_list';
export const CATEGORY_DETAILED_PATH = '/category_detailed';
export const CATEGORY_EDIT_PATH = '/category_edit';
export const PREFIX_DETAILED_PATH = '/prefix_detailed';
export const PREFIX_EDIT_PATH = '/prefix_edit';

export const LOCATIONS_LIST_PATH = '/locations_list';
export const LOCATION_DETAILED_PATH = '/location_detailed';
export const LOCATION_EDIT_PATH = '/location_edit';

export const CLIENT_LIST_PATH = '/client_list';
export const CLIENT_DETAILED_PATH = '/client_detailed';
export const CLIENT_EDIT_PATH = '/client_edit';

export const PROJECT_LIST_PATH = '/project_list';
export const PROJECT_DETAILED_PATH = '/project_detailed';
export const PROJECT_EDIT_PATH = '/project_edit';
export const MANAGE_LOOSE_ITEMS_PATH = '/manage_loose_items';

export const ACTIVE_LOCATIONS_MAP_PATH = '/active_locations_map';
export const BUILD_ROUTE_MAP_PATH = '/build_route_map';

export const ASSETS_LIST_PATH = '/assets_list';
export const ASSET_DETAILED_PATH = '/asset_detailed';
export const ASSET_EDIT_PATH = '/asset_edit';

export const BATCH_OF_ASSETS_CREATION_PATH = '/batch_of_assets_creation';

export const TICKETS_LIST_PATH = '/tickets_list';
export const TICKET_DETAILED_PATH = '/ticket_detailed';
export const TICKET_EDIT_PATH = '/ticket_edit';

export const LOCATION_REPORT_LIST_PATH = '/location_reports';
export const LOCATION_REPORT_EDIT_PATH = '/location_report_edit';
export const ASSET_REPORT_LIST_PATH = '/asset_reports';
export const ASSET_REPORT_EDIT_PATH = '/asset_report_edit';
export const GENERAL_REPORT_LIST_PATH = '/general_reports';
export const GENERAL_REPORT_EDIT_PATH = '/general_report_edit';
export const COMPARE_REPORTS_PATH = '/compare_reports';
export const REPORT_TEMPLATE_LIST_PATH = '/report_template_list';
export const REPORT_TEMPLATE_EDITOR = '/report_template_editor';

export const BATCH_ASSET_UPDATES_PATH = '/batch_asset_updates';
export const ADD_ASSET_TICKET_PATH = `${BATCH_ASSET_UPDATES_PATH}?tab=addAssetTicket`;
export const BATCH_LOOSE_ITEM_UPDATES_PATH = '/batch_loose_item_updates';
export const MOVE_LOOSE_ITEMS_PATH = `${BATCH_LOOSE_ITEM_UPDATES_PATH}?tab=moveLooseItems`;

export const USER_LIST_PATH = '/user_list';
export const USER_DETAILED_PATH = '/user_detailed';
export const USER_EDIT_PATH = '/user_edit';
export const USER_LOGS_PATH = '/user_logs';
export const NOTIFICATION_CENTER_PATH = '/notification_center';

export const TICKET_ROOT_CAUSES_PATH = '/ticket_root_causes';
export const TICKET_TYPES_PATH = '/ticket_types';
export const TICKET_TYPE_EDIT_PATH = '/ticket_type_edit';
export const TICKET_ROOT_CAUSE_EDIT_PATH = '/root_cause_edit';

export const LOOSE_ITEMS_LIST_PATH = '/loose_items';
export const LOOSE_ITEM_DETAILED_PATH = '/loose_item_detailed';
export const LOOSE_ITEM_EDIT_PATH = '/loose_item_edit';
export const LOOSE_ITEM_DASHBOARD = '/loose_items_dashboard';

export const BOM_CONFIGURATIONS_LIST_PATH = '/bom_configurations';
export const BOM_CONFIGURATION_DETAILED_PATH = '/bom_configuration_detailed';
export const BOM_CONFIGURATION_EDIT_PATH = '/bom_configuration_edit';

export const ASSEMBLED_BOM_LIST_PATH = '/assembled_bom_list';
export const ASSEMBLE_EDIT_PATH = '/assembly_edit';
export const ASSEMBLED_BOM_DETAILED_PATH = '/assembly_detailed';

export const PICKLIST_SUMMARY_PATH = '/picklist';
export const PICKLIST_EDIT_PATH = '/picklist_edit';
export const PICKLIST_DETAILED_PATH = '/picklist_detailed';
export const ADD_BOM_TO_PICKLIST_PATH = '/add_bom_to_picklist';

export const LEAVELIST_SUMMARY_PATH = '/leavelist';
export const LEAVELIST_EDIT_PATH = '/leavelist_edit';
export const LEAVELIST_DETAILED_PATH = '/leavelist_detailed';

export const OVERVIEW_REPORT_PATH = '/overview_report';

export const TEAM_MEMBER_LIST_PATH = '/team_member_list';
export const TEAM_MEMBER_EDIT_PATH = '/team_member_edit';
export const TEAM_MEMBER_DETAILED_PATH = '/team_member_detailed';
export const TEAM_MEMBER_TRACKER_PATH = '/team_member_tracker';
export const TEAM_MEMBER_DASHBOARD_PATH = '/team_member_dashboard';
export const TEAM_MEMBER_HISTORY_PATH = '/team_member_history';
export const TRAININGS_AND_CERTIFICATIONS_PATH = '/trainings_and_certifications';

export const PERSONNEL_PROFILE_PATH = '/personal_profile';

export const REQUEST_ORDER_SUMMARY_PATH = '/request_orders';
export const REQUEST_ORDER_EDIT_PATH = '/request_order_edit';
export const REQUEST_ORDER_DETAILED_PATH = '/request_order_detailed';

export const BILL_OF_LADING_SUMMARY_PATH = '/bill_of_lading_summary';
export const BILL_OF_LADING_EDIT_PATH = '/bill_of_lading_edit';
export const BILL_OF_LADING_DETAILS_PATH = '/bill_of_lading_detailed';

export const TICKETS_DASHBOARD_PATH = '/tickets_dashboard';

export const BILL_OF_LADING_LOGS_PATH = '/bill_of_lading_logs';
export const BILL_OF_LADING_LOG_EDIT_PATH = '/bill_of_lading_log_edit';
export const UPLOAD_BOL_LOGS_FROM_CSV_PATH = '/upload_bill_of_lading_logs';
