import React, { useEffect, useRef, useState } from 'react';
import styles from './BillOfLadingDetails.module.scss';

import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import DialogPopup from 'components/DialogPopup/DialogPopup';

import SectionTitle from '../commonComponents/SectionTitle';
import DetailsHeader from './components/DetailsHeader';
import EditButton from './components/EditButton';
import RemoveButton from './components/RemoveButton';
import CommonDetails from './components/CommonDetails';
import { isElementAvailable, prepareData } from './helpers';
import OriginLocationDestinationDetails from './components/OriginLocationDestinationDetails';
import VehicleInstructionsDetails from './components/VehicleInstructionsDetails';
import ItemsForDeliveryDetails from './components/ItemsForDeliveryDetails';
import ResponsibleDetails from './components/ResponsibleForDelivery/ResponsibleDetails';
import DownloadPdfButton from './components/DownloadPdfButton';

import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearState,
  deleteBOL,
  getCsv,
  getSingleBillOfLading,
  updateBOL
} from 'actions/billOfLadingActions';
import { selectCurrentBillOfLadingData } from '../selectors';
import { BILL_OF_LADING_EDIT_PATH, BILL_OF_LADING_SUMMARY_PATH } from 'constants/routeConstants';
import {
  DELETE_BILL_OF_LADING,
  DELETE_BOL_SIGNATURE,
  DELETE_BOL_TYPE
} from 'constants/dialogPopupsData';
import { fileDownload } from 'helpers/AppHelpers';
import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';

function BillOfLadingDetails() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const { id } = useParams();
  const { isAdminUser, isOperationsManagerUser, isFieldTechUser, isEntityCreatorUser } =
    useUserConfig();
  const title = `Bill of Lading ${id}`;

  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const currentBillOfLading = useSelector(selectCurrentBillOfLadingData());
  const dispatch = useDispatch();
  const breadcrumbs = useRef([{ name: title }]);

  const { common, location, destination, vehicle, deliveryItems, responsible } = prepareData(
    currentBillOfLading
  );

  useEffect(() => {
    if (id) {
      dispatch(getSingleBillOfLading(id));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const handleBackClick = () => history.push(BILL_OF_LADING_SUMMARY_PATH);
  const handleEditClick = () => history.push(`${BILL_OF_LADING_EDIT_PATH}/${id}`);
  const handleDeleteClick = () => setDialogModalData({ ...DELETE_BILL_OF_LADING, isOpened: true });
  const closeModal = () => setDialogModalData({ isOpened: false });

  const handleUpdate = (data) =>
    dispatch(updateBOL({ ...currentBillOfLading, ...data }, { shouldUpdateEntity: true }));
  const handleDownloadClick = () =>
    dispatch(getCsv({ id })).then((file) => fileDownload(file));
  const handleSignDelete = (param) =>
    setDialogModalData({ ...DELETE_BOL_SIGNATURE, isOpened: true, param });
  const handleAgree = () =>
    dialogModalData.type === DELETE_BOL_TYPE ? dispatch(deleteBOL(id)) : handleDeleteSignature();

  const handleDeleteSignature = () => {
    handleUpdate({ [dialogModalData.param]: null });
    closeModal();
  };

  const areHeaderControlsAvailable = () =>
    isElementAvailable(currentBillOfLading, {
      isAdminUser,
      isOperationsManagerUser,
      isFieldTechUser,
      isEntityCreatorUser
    });

  return (
    <section className={styles.wrapper}>
      <DialogPopup data={dialogModalData} onAgree={handleAgree} onDissmiss={closeModal} />
      {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs.current} />}
      {currentBillOfLading?.id === +id && (
        <>
          <DetailsHeader title={title} onBack={handleBackClick} isMobile={isMobile}>
            {areHeaderControlsAvailable() && (
              <>
                <DownloadPdfButton onClick={handleDownloadClick} />
                <EditButton title="Edit BoL" onClick={handleEditClick} />
                <RemoveButton title="Remove BoL" onClick={handleDeleteClick} />
              </>
            )}
          </DetailsHeader>
          {isMobile && <h1 className={styles.title}>{title}</h1>}
          <CommonDetails isMobile={isMobile} data={common} />
          {!isMobile && <SectionTitle title="Origin & Destination Location" />}
          <div className={styles.flexible}>
            <OriginLocationDestinationDetails isMobile={isMobile} data={location} label="Origin" />
            <OriginLocationDestinationDetails
              isMobile={isMobile}
              data={destination}
              label="Destination"
            />
          </div>
          {!isMobile && <SectionTitle title="Vehicle & Instructions" />}
          <VehicleInstructionsDetails isMobile={isMobile} data={vehicle} />
          {!isMobile && <SectionTitle title="Items for Delivery" />}
          <ItemsForDeliveryDetails isMobile={isMobile} data={deliveryItems} />
          {!isMobile && <SectionTitle title="Responsible for Delivery" />}
          <ResponsibleDetails
            isMobile={isMobile}
            data={responsible}
            onUpdate={handleUpdate}
            onDelete={handleSignDelete}
          />
        </>
      )}
    </section>
  );
}

export default BillOfLadingDetails;
