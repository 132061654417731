import React, { useEffect, useState } from 'react';

import { useBatchItemUpdatesActions, useBatchItemUpdatesSelector } from 'hooks/BatchItemUpdates';
import { useSearchParams } from 'hooks/useSearchParams';
import { useUserConfig } from 'hooks/useUserConfig';

import Wrapper from './components/Wrapper';
import Tabs from './components/Tabs';
import UpdateLooseItems from './UpdateLooseItems';
import MoveLooseItems from './MoveLooseItems';
import { defaultTab, tabs } from './helpers';

export default function BatchLooseItemUpdates() {
  const [tabState, setTabState] = useState({ active: false });
  const { tab } = useSearchParams(['tab']);

  const { isConfigReceived, isAdminUser, isOperationsManagerUser } = useUserConfig();

  const { selectedTab } = useBatchItemUpdatesSelector();
  const { setSelectedTabAction, getCreationFormAction, clearStateAction } =
    useBatchItemUpdatesActions();

  useEffect(() => {
    if (!isConfigReceived) return;

    const shouldActivateTab = isAdminUser || isOperationsManagerUser;

    setTabState({ active: shouldActivateTab });
    setSelectedTabAction(
      shouldActivateTab ? tab || selectedTab || defaultTab : tabs.looseItemsUpdate
    );
  }, [isConfigReceived, selectedTab]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <Wrapper>
      {tabState.active && <Tabs />}

      {tabs.looseItemsUpdate === selectedTab && <UpdateLooseItems />}

      {tabs.moveLooseItems === selectedTab && <MoveLooseItems />}
    </Wrapper>
  );
}
