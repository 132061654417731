import { ERROR_MOVED_LOOSE_ITEMS } from 'constants/infoSnackbarData';
import api from '../api';
import { enqueueErrorSnackbar } from '../helpers/AppHelpers';

export const getLooseItems = (query) => () =>
  api.moveLooseItemsPopup
    .getLooseItemsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLocations = (query) => () =>
  api.moveLooseItemsPopup
    .getLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const moveItems = (data) => () =>
  api.moveLooseItemsPopup.moveItemsApi(data).then(
    (res) => {
      if (!res.moveLooseItems?.length) {
        enqueueErrorSnackbar(ERROR_MOVED_LOOSE_ITEMS);
        return [];
      }

      return res.moveLooseItems;
    },
    (err) => console.log(err)
  );
