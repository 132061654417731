export const tabs = {
  looseItemsUpdate: 'looseItemsUpdate',
  moveLooseItems: 'moveLooseItems'
};

export const defaultTab = tabs.looseItemsUpdate;

export const TOGGLE_BUTTONS = [
  { id: 1, label: 'Categories and Fields Updates', value: tabs.looseItemsUpdate },
  { id: 2, label: 'Move Loose Items', value: tabs.moveLooseItems }
];

export const AT_LEAST_ONE_FIELD_ERROR_NAME = 'no_items_update_options';

export const AT_LEAST_ONE_FIELD_ERROR_MESSAGE = 'At least one field is required';

export const CONNECTED_FIELD_ERROR_NAMES = [
  AT_LEAST_ONE_FIELD_ERROR_NAME,
  'looseItemCategories',
  'customFields'
];
