import { useDispatch } from 'react-redux';
import {
  getLooseItems,
  getLooseItemCounting,
  setFilter,
  getFilterCriteria,
  clearState,
  createLooseItem,
  deleteLooseItem,
  getCustomFields,
  getLooseItem,
  setCustomColumns,
  updateLooseItem,
  setCheckedItems,
  updateLooseItemTableRow,
  getSummaryCsvResource,
  getCreationForm,
  setVerificationStatus
} from 'actions/looseItemActions';

import { setUnsavedFormData } from 'actions/commonActions';

export function useLooseItemActions() {
  const dispatch = useDispatch();

  const getLooseItemsAction = (q) => dispatch(getLooseItems(q));
  const getCountingAction = (q) => dispatch(getLooseItemCounting(q));

  const selectRowAction = (data) => dispatch(setCheckedItems(data));

  const setFilterAction = (data) => dispatch(setFilter(data));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const getItemAction = (id) => dispatch(getLooseItem(id));
  const createItemAction = (data, q) => dispatch(createLooseItem(data, q));
  const updateLooseItemAction = (data, options) => dispatch(updateLooseItem(data, options));
  const deleteItemAction = (id) => dispatch(deleteLooseItem(id));
  const clearStateAction = () => dispatch(clearState());

  const updateLooseItemTableRowAction = (data) => dispatch(updateLooseItemTableRow(data));

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const getLooseItemFieldsAction = (q) => dispatch(getCustomFields(q));

  const getCreationFormAction = () => dispatch(getCreationForm());

  const setCustomColumnsAction = (arr) => dispatch(setCustomColumns(arr));

  const getSummaryCsvResourceAction = (q) => dispatch(getSummaryCsvResource(q));

  const setVerificationStatusAction = (data) => dispatch(setVerificationStatus(data));

  return {
    getLooseItemsAction,
    getCountingAction,
    selectRowAction,
    setFilterAction,
    getFilterCriteriaAction,
    updateLooseItemTableRowAction,
    getItemAction,
    createItemAction,
    updateLooseItemAction,
    deleteItemAction,
    clearStateAction,
    setUnsavedFormDataAction,
    getLooseItemFieldsAction,
    setCustomColumnsAction,
    getSummaryCsvResourceAction,
    getCreationFormAction,
    setVerificationStatusAction
  };
}
