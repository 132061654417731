export const formatVersion = (version, options = {}) => {
  const { forBackend = false } = options;
  const parts = version.split('.');

  if (forBackend) {
    return parts.slice(0, 3).join('.');
  }

  const env = process.env.REACT_APP_ENVIRONMENT || 'local';
  const isFullVersion = ['local', 'dev', 'qa'].includes(env);

  return isFullVersion ? version : parts.slice(0, 3).join('.');
};
