import React, { useEffect, useRef, useState } from 'react';
import styles from './BillOfLadingEdit.module.scss';

import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import MainButton from 'components/StyledComponents/MainButton';
import BackButton from 'components/BackButton/BackButton';
import DialogPopup from 'components/DialogPopup/DialogPopup';

import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import { getBackendErrors } from 'helpers/AppHelpers';
import { BILL_OF_LADING_DETAILS_PATH, BILL_OF_LADING_SUMMARY_PATH } from 'constants/routeConstants';

import CommonDetailsForm from './components/CommonDetailsForm';
import SectionTitle from '../commonComponents/SectionTitle';
import OriginLocationForm from './components/OriginLocationForm';
import DestinationForm from './components/DestinationForm';
import VehicleInstructionsForm from './components/VehicleInstructionsForm';
import ItemsForDeliveryForm from './components/ItemsForDeliveryForm';
import ResponsibleForDeliveryForm from './components/ResponsibleForDeliveryForm';

import { tableValidationRules, validationRules } from './components/formConfigs';

import { transformPrefilledData } from './helpers';

import {
  clearState,
  createBOL,
  getCreationForm,
  getSingleBillOfLading,
  updateBOL
} from 'actions/billOfLadingActions';
import { setUnsavedFormData } from 'actions/commonActions';
import { selectCreationFormData, selectCurrentBillOfLadingData } from '../selectors';

import {
  CREATE_BILL_OF_LADING,
  CREATE_BOL_TYPE,
  EXIT_WITHOUT_SAVING,
  SOME_ITEMS_DONT_HAVE_WEIGHT,
  SOME_ITEMS_DONT_HAVE_WEIGHT_TYPE,
  UPDATE_BILL_OF_LADING,
  UPDATE_BOL_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { selectUnsavedFormData } from 'pages/commonSelectors';

export default function BillOfLadingEdit() {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const [dialogModalData, setDialogModalData] = useState({});

  const currentBillOfLading = useSelector(selectCurrentBillOfLadingData());
  const creationForm = useSelector(selectCreationFormData());
  const unsavedFormData = useSelector(selectUnsavedFormData());

  const breadcrumbs = useRef([{ name: 'Create Bill of Lading' }]);
  const methods = useForm({
    defaultValues: {
      cellPhone: '',
      dateOfShipment: null,
      deficienciesUponDelivery: '',
      destinationLocation: '',
      destinationLocationItem: {},
      destinationCompanyName: '',
      destinationAddress: '',
      destinationAddress2: '',
      destinationCity: '',
      destinationZIP: '',
      destinationState: '',
      destinationPointOfContact: '',
      destinationPOCPhone: '',
      driverName: '',
      driverDate: null,
      driverPhoneNumber: '',
      freightBill: '',
      jobNumber: '',
      nameOFCarrierOrDOTNumber: '',
      originLocation: '',
      originLocationItem: {},
      originCompanyName: '',
      originAddress: '',
      originAddress2: '',
      originCity: '',
      originZIP: '',
      originState: '',
      originPointOfContact: '',
      originPOCPhone: '',
      otherVehicle: '',
      receivedBy: '',
      receivedDate: null,
      receivedPhoneNumber: '',
      shippedBy: '',
      shippedDate: null,
      shippedPhoneNumber: '',
      specialInstructions: '',
      truckNumber: '',
      truck: null,
      typeOfVehicle: {},
      billOfLadingItems: [],
      totalWeight: 0
    },
    mode: 'onChange'
  });
  const { getValues, setError, formState, reset } = methods;
  const { isDirty, errors } = formState;

  useEffect(() => {
    if (id) {
      dispatch(getSingleBillOfLading(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && currentBillOfLading?.id) {
      breadcrumbs.current = [
        {
          path: `${BILL_OF_LADING_DETAILS_PATH}/${id}`,
          name: 'Bill of Lading ' + currentBillOfLading.id
        },
        { name: 'Edit Bill of Lading' }
      ];
      reset(currentBillOfLading);
    }
  }, [currentBillOfLading]);

  useEffect(() => {
    if (!creationForm?.['typeOfVehicles']?.length) {
      dispatch(
        getCreationForm({ billOfLadingId: currentBillOfLading?.id || id || null })
      );
    }
  }, [creationForm]);

  useEffect(() => {
    if (
      !id &&
      !!creationForm?.['typeOfVehicles']?.length &&
      (creationForm?.['picklist']?.id || creationForm?.['leavelist']?.id)
    ) {
      reset(transformPrefilledData(creationForm));
    }
  }, [creationForm]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      dispatch(setUnsavedFormData(true));
    }
    return () => {
      dispatch(setUnsavedFormData(false));
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;
  const validateForm = () => {
    let isFormValid = true;
    validationRules.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    if (getValues('typeOfVehicle').displayName === 'Other' && !getValues('otherVehicle')) {
      setError('otherVehicle', getErrorsProperties('This field is required'), {
        shouldFocus: true
      });
      isFormValid = false;
    }
    if (getValues('billOfLadingItems')?.length) {
      tableValidationRules.forEach(({ name, inputType, errorMessage }) => {
        getValues('billOfLadingItems').forEach((row, index) => {
          if (isParameterInvalid(row[name], inputType)) {
            setError(`billOfLadingItems[${index}].${name}`, getErrorsProperties(errorMessage), {
              shouldFocus: true
            });
            isFormValid = false;
          }
        });
      });
    } else {
      setError(`billOfLadingItems`, getErrorsProperties('This field is required'));
      isFormValid = false;
    }
    return isFormValid;
  };

  const hasEmptyWeightValues = () => {
    return getValues('billOfLadingItems').some((item) => !+item.weight);
  };

  const onCreate = () => {
    if (!validateForm()) return;

    if (hasEmptyWeightValues()) {
      setDialogModalData({ ...SOME_ITEMS_DONT_HAVE_WEIGHT, isOpened: true });
      return;
    }

    if (id) {
      setDialogModalData({ ...UPDATE_BILL_OF_LADING, isOpened: true });
    } else {
      setDialogModalData({ ...CREATE_BILL_OF_LADING, isOpened: true });
    }
  };

  const onCancel = () => {
    if (isDirty) {
      setDialogModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  const closeModal = () => setDialogModalData({ isOpened: false });
  const getBack = () =>
    history.push(id ? `${BILL_OF_LADING_DETAILS_PATH}/${id}` : BILL_OF_LADING_SUMMARY_PATH);

  const handleErrors = (err) => {
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
    containerRef.current.scrollIntoView();
  };

  const submitBillOfLading = (action) => {
    closeModal();
    dispatch(action(getValues())).then((err) => err?.errors && handleErrors(err));
  };

  const agreeModal = () => {
    switch (dialogModalData.type) {
      case CREATE_BOL_TYPE:
        submitBillOfLading(createBOL);
        break;
      case UPDATE_BOL_TYPE:
        submitBillOfLading(updateBOL);
        break;
      case SOME_ITEMS_DONT_HAVE_WEIGHT_TYPE:
        submitBillOfLading(id ? updateBOL : createBOL);
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(dialogModalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  return (
    <section className={styles.wrapper} ref={containerRef}>
      <DialogPopup data={dialogModalData} onAgree={agreeModal} onDissmiss={closeModal} />
      {isMobile && (
        <div className={styles.header}>
          <h1>{id ? 'Edit' : 'Create'} BOL</h1>
          <BackButton onCancel={onCancel} />
        </div>
      )}
      {!isMobile && (
        <BreadcrumbsNav
          itemsArray={breadcrumbs.current}
          setDialogModalData={setDialogModalData}
          formIsChanged={isDirty}
        />
      )}
      <FormProvider {...methods}>
        {!isMobile && <SectionTitle title="Common Details" />}
        <CommonDetailsForm />

        {!isMobile && <SectionTitle title="Origin & Destination Location" />}
        <div className={styles.flexible}>
          <OriginLocationForm />
          <DestinationForm />
        </div>

        {!isMobile && <SectionTitle title="Vehicle & Instructions" />}
        <VehicleInstructionsForm />

        {!isMobile && <SectionTitle title="Items for Delivery" />}
        <ItemsForDeliveryForm />

        {!isMobile && <SectionTitle title="Responsible for Delivery" />}
        <ResponsibleForDeliveryForm />
      </FormProvider>
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={onCancel} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={onCreate}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}
