import React from 'react';
import styles from './Details.module.scss';

import { useUserSelector } from 'hooks/UserManagement';

import DetailedRow from 'components/DetailsComponents/DetailedRow';
import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';

export default function UserScheduleInfo() {
  const { currentUser } = useUserSelector();
  const { departments, isTeamMember, userRole, isSelectedAllDepartments } = currentUser;

  const areProjectSchedulesAvailable =
    !isTeamMember &&
    (userRole?.role === 'Basic user' ||
      userRole?.role === 'Field tech' ||
      userRole?.role === 'Super user');

  return (
    <div className={styles.details}>
      {!!departments?.length && userRole?.role === 'Personnel manager' && (
        <DetailedRow
          label="Department"
          value={
            isSelectedAllDepartments
              ? 'All Selected'
              : departments?.map((el) => el.name)?.join(', ') || ''
          }
          disableFlex
        />
      )}
      <div className={styles.details__checkbox}>
        <label>Is a team member</label>
        <StyledCheckbox disabled={true} checked={!!isTeamMember} />
      </div>
      {!!departments?.length && areProjectSchedulesAvailable && (
        <DetailedRow
          label="Read-only Project Schedules"
          value={
            isSelectedAllDepartments
              ? 'All Selected'
              : departments?.map((el) => el.name)?.join(', ') || ''
          }
          disableFlex
        />
      )}
    </div>
  );
}
