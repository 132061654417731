import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { SnackbarProvider } from 'notistack';
import { ErrorTemplate, SuccessTemplate, WarningTemplate } from './Templates';

const useStyles = makeStyles(() => ({
  snackbarRoot: { alignItems: 'flex-end', padding: '0 10px' }
}));

export default function SnackbarWrapper({ children }) {
  const classes = useStyles();

  const isMobile = useMobileViewport();

  return (
    <SnackbarProvider
      classes={{ containerRoot: isMobile ? classes.snackbarRoot : '' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={10000}
      Components={{ error: ErrorTemplate, success: SuccessTemplate, warning: WarningTemplate }}>
      {children}
    </SnackbarProvider>
  );
}
