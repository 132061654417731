import React, { useEffect } from 'react';
import styles from './styles.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormLabel, FormRow, FormSearchInput } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { FormProvider, useForm } from 'react-hook-form';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useBatchItemUpdatesSelector } from 'hooks/BatchItemUpdates';

export function EditSelection({ open, setOpen, onApply, selectedRows }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: { destination: {}, quantity: 0 },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const { creationForm } = useBatchItemUpdatesSelector();
  const { locationsWithSublocations } = creationForm;

  useEffect(() => {
    if (open) {
      reset({ destination: {}, quantity: 0 });
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    const { destination, quantity } = getValues();

    if (destination?.id || quantity) {
      onApply({ destination, quantity, selectedRows });
    }

    closePopup();
  };

  const selectDestination = (name, value) => setValue(name, value);

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Change Loose Items" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__cell}>
              <FormRow>
                <FormLabel>Destination</FormLabel>
                <FormSearchInput
                  clearable
                  name="destination"
                  options={locationsWithSublocations || []}
                  onSelect={selectDestination}
                />
              </FormRow>
            </div>
            <div className={styles.form__cell}>
              <FormRow>
                <FormLabel>QTY</FormLabel>
                <FormInputText name="quantity" options={{ focus: true, type: 'quantity' }} />
              </FormRow>
            </div>
          </div>
        </FormProvider>
        <FilterActions onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
