import { useSelector } from 'react-redux';

import {
  selectSelectedTabData,
  selectCreationFormData
} from 'pages/BatchUpdates/LooseItem/selectors';

export function useBatchItemUpdatesSelector() {
  const creationForm = useSelector(selectCreationFormData());

  const selectedTab = useSelector(selectSelectedTabData());

  return {
    creationForm,
    selectedTab
  };
}
