import React from 'react';
import styles from './ClearFilterButton.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

export default function ClearFilterButton({ onClick, hideLabel = false }) {
  const handleClearClick = () => onClick();
  return (
    <IconButton className={clsx(styles.clear, hideLabel && styles.increased)} onClick={handleClearClick}>
      {!hideLabel && 'Clear all'}
      <CloseIcon />
    </IconButton>
  );
}
