import React from 'react';
import { tabs } from '../../../helpers';

export const commonTableConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'firstName' },
  { id: 2, label: 'Email', isSortable: false },
  { id: 3, label: 'Role', isSortable: true, parameter: 'userRole.role' },
  { id: 4, label: 'Location', isSortable: false },
  { id: 5, label: 'Prefix', isSortable: false },
  { id: 6, label: '', isSortable: false }
];

export const generalReportTableConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'firstName' },
  { id: 2, label: 'Email', isSortable: false },
  { id: 3, label: 'Role', isSortable: true, parameter: 'userRole.role' },
  { id: 4, label: '', isSortable: false }
];

export const looseItemsTableConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'firstName' },
  { id: 2, label: 'Email', isSortable: false },
  { id: 3, label: 'Role', isSortable: true, parameter: 'userRole.role' },
  { id: 4, label: 'BAA Recipient', isSortable: false, wrapText: true },
  { id: 5, label: 'Approve Recipient', isSortable: false, wrapText: true },
  { id: 6, label: 'Re-order Recipient', isSortable: false, wrapText: true },
  { id: 7, label: 'Verification Recipient', isSortable: false, wrapText: true },
  { id: 8, label: '', isSortable: false }
];

export const requestOrderTableConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'firstName' },
  { id: 2, label: 'Email', isSortable: false },
  { id: 3, label: `User's Role`, isSortable: true, parameter: 'userRole.role' },
  { id: 4, label: 'Request Order Role', isSortable: true, parameter: 'requestOrderRole' },
  { id: 5, label: 'Location', isSortable: false },
  { id: 6, label: '', isSortable: false }
];

export const locationReportTableConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'firstName' },
  { id: 2, label: 'Email', isSortable: false },
  { id: 3, label: 'Role', isSortable: true, parameter: 'userRole.role' },
  { id: 4, label: 'Location', isSortable: false },
  { id: 5, label: '', isSortable: false }
];

export const teamMemberStatusTableConfig = [
  { id: 1, label: 'Name', isSortable: true, parameter: 'firstName' },
  { id: 2, label: 'Email', isSortable: false },
  { id: 3, label: 'Role', isSortable: false },
  { id: 4, label: 'Department', isSortable: false },
  { id: 5, label: '', isSortable: false }
];

export const NON_DRAT_USER_NAME = 'Non-Drat User';

export const getTableConfig = (tab) => {
  const params = {
    [tabs.generalReport]: generalReportTableConfig,
    [tabs.looseItems]: looseItemsTableConfig,
    [tabs.requestOrder]: requestOrderTableConfig,
    [tabs.locationReport]: locationReportTableConfig,
    [tabs.teamMemberStatus]: teamMemberStatusTableConfig,
    byDefault: commonTableConfig
  };

  return params[tab] || params.byDefault;
};

export const getPopupHintText = (tab) => {
  const params = {
    [tabs.looseItems]: 'To assign Re-order notification recipient, Location should be selected.',
    [tabs.locationReport]: 'To assign notification recipient, Location should be selected.',
    [tabs.requestOrder]: (
      <>
        {'To assign notification recipient, select both parameters.'}
        <br />
        {`If the email doesn't belong to user of the Drat, then the Request order role will be "Responsible for account".`}
      </>
    ),
    [tabs.assetReport]: 'To assign notification recipient, select both parameters.',
    [tabs.ticketAndAssetTab]: 'To assign notification recipient, select both parameters.',
    byDefault: 'To assign notification recipient, select both location and prefix parameters.'
  };

  return params[tab] || params.byDefault;
};

export const getLocationFieldLabel = (tab) => {
  const params = {
    [tabs.requestOrder]: 'Notify by Location',
    [tabs.looseItems]: 'Re-order Recipient',
    byDefault: 'Location'
  };

  return params[tab] || params.byDefault;
};
