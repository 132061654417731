import {
  CLEAR_LOOSE_ITEMS_STATE,
  SET_CURRENT_SINGLE_ITEM,
  SET_LOOSE_ITEMS_FILTER,
  SET_CHECKED_LOOSE_ITEMS,
  SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS,
  SET_LOOSE_ITEMS_FILTER_CRITERIA,
  SET_LOOSE_ITEMS_CREATION_FORM
} from 'constants/reduceConstants';

const initialState = {
  // Summary
  filter: { responseReceived: false },
  filterCriteria: {},
  checkedItems: [],
  customColumns: [],
  // Others
  looseItems: [],
  currentItem: {},
  creationForm: {}
};

const looseItemsAndPartsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOOSE_ITEMS_FILTER:
      return { ...state, filter: payload };
    case SET_LOOSE_ITEMS_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case SET_CURRENT_SINGLE_ITEM:
      return { ...state, currentItem: payload };
    case SET_CHECKED_LOOSE_ITEMS:
      return { ...state, checkedItems: payload };
    case SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS:
      return { ...state, customColumns: payload };
    case SET_LOOSE_ITEMS_CREATION_FORM:
      return { ...state, creationForm: payload };
    case CLEAR_LOOSE_ITEMS_STATE:
      return {
        ...initialState,
        filter: { ...state.filter, responseReceived: false },
        checkedItems: state.checkedItems,
        customColumns: state.customColumns
      };
    default:
      return state;
  }
};

export default looseItemsAndPartsReducer;
