import React from 'react';
import styles from './Details.module.scss';

import { useUserSelector } from 'hooks/UserManagement';

import StyledCheckbox from 'components/StyledComponents/StyledCheckbox';
import Separator from 'components/Separator/Separator';
import DetailedRow from 'components/DetailsComponents/DetailedRow';

import SectionTitle from './Title';

import { joinWithComma, pluck } from 'helpers/AppHelpers';

import clsx from 'clsx';

const allSelectedLabel = 'All Selected';

export default function UserNotificationsInfo() {
  const { currentUser } = useUserSelector();
  const {
    userRole,
    isGeneralReportRecipient,
    isBAARecipient,
    isLooseItemApproveRecipient,
    isTeamMemberStatusesRecipient,
    notificationAssetReportPrefixes,
    notificationAssetReportLocations,
    notificationTicketAndAssetUpdateLocations,
    notificationTicketAndAssetUpdatePrefixes,
    notificationReorderRecipientLocations,
    notificationLocationReportLocations,
    notificationVerificationRecipientLocations,
    isSelectedAllAssetReportLocations,
    isSelectedAllAssetReportPrefixes,
    isSelectedAllLocationReportLocations,
    isSelectedAllTicketAndAssetUpdateLocations,
    isSelectedAllTicketAndAssetUpdatePrefixes,
    isSelectedAllReorderRecipientLocations,
    isSelectedAllVerificationRecipientLocations
  } = currentUser;

  const userRoleIsAdmin = userRole?.role === 'Admin';
  const userRoleIsSuperUser = userRole?.role === 'Super user';
  const userRoleIsFieldTech = userRole?.role === 'Field tech';

  return (
    <div className={styles.details}>
      <SectionTitle>Asset Report</SectionTitle>
      <div className={styles.details__block}>
        <DetailedRow
          label="Location"
          value={
            isSelectedAllAssetReportLocations
              ? allSelectedLabel
              : joinWithComma(pluck(notificationAssetReportLocations, 'siteCode')) || ''
          }
          disableFlex
        />
        <DetailedRow
          label="Prefix"
          value={
            isSelectedAllAssetReportPrefixes
              ? allSelectedLabel
              : joinWithComma(pluck(notificationAssetReportPrefixes, 'prefix')) || ''
          }
          disableFlex
        />
      </div>

      <Separator />

      <SectionTitle>Location Report</SectionTitle>
      <div className={clsx(styles.details__block, styles.onlyChild)}>
        <DetailedRow
          label="Location"
          value={
            isSelectedAllLocationReportLocations
              ? allSelectedLabel
              : joinWithComma(pluck(notificationLocationReportLocations, 'siteCode')) || ''
          }
          disableFlex
        />
      </div>

      <Separator />

      <SectionTitle>General Report</SectionTitle>
      <div className={styles.details__checkbox}>
        <label>Is general report recipient</label>
        <StyledCheckbox disabled checked={!!isGeneralReportRecipient} />
      </div>

      <Separator />

      <SectionTitle>Ticket & Asset Update</SectionTitle>
      <div className={styles.details__block}>
        <DetailedRow
          label="Location"
          value={
            isSelectedAllTicketAndAssetUpdateLocations
              ? allSelectedLabel
              : joinWithComma(pluck(notificationTicketAndAssetUpdateLocations, 'siteCode')) || ''
          }
          disableFlex
        />
        <DetailedRow
          label="Prefix"
          value={
            isSelectedAllTicketAndAssetUpdatePrefixes
              ? allSelectedLabel
              : joinWithComma(pluck(notificationTicketAndAssetUpdatePrefixes, 'prefix')) || ''
          }
          disableFlex
        />
      </div>

      <Separator />

      <SectionTitle>Loose Items</SectionTitle>
      <div className={styles.details__block}>
        <div className={styles.details__cell}>
          <div className={styles.details__checkbox}>
            <label>Is Buy Ahead Account recipient</label>
            <StyledCheckbox disabled checked={!!isBAARecipient} />
          </div>
          {(userRoleIsAdmin || userRoleIsSuperUser) && (
            <div className={styles.details__checkbox}>
              <label>Notify on new loose item creation</label>
              <StyledCheckbox disabled checked={!!isLooseItemApproveRecipient} />
            </div>
          )}
        </div>

        <div className={styles.details__cell}>
          <DetailedRow
            label="Re-order Recipient"
            value={
              isSelectedAllReorderRecipientLocations
                ? allSelectedLabel
                : joinWithComma(pluck(notificationReorderRecipientLocations, 'siteCode')) || ''
            }
            disableFlex
          />
          {(userRoleIsAdmin || userRoleIsSuperUser || userRoleIsFieldTech) && (
            <DetailedRow
              label="Verification Recipient"
              value={
                isSelectedAllVerificationRecipientLocations
                  ? allSelectedLabel
                  : joinWithComma(pluck(notificationVerificationRecipientLocations, 'siteCode')) ||
                    ''
              }
              disableFlex
            />
          )}
        </div>
      </div>

      {['Personnel manager', 'Admin'].includes(currentUser?.userRole?.role) && (
        <>
          <Separator />

          <SectionTitle>Team Members</SectionTitle>
          <div className={styles.details__checkbox}>
            <label>Is Team member status recipient</label>
            <StyledCheckbox disabled checked={isTeamMemberStatusesRecipient} />
          </div>
        </>
      )}
    </div>
  );
}
