import React, { useState } from 'react';
import styles from './components.module.scss';
import { Button, IconButton, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { itemsForDeliveryTableHeadConfig } from './formConfigs';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import DeleteIcon from '@material-ui/icons/Delete';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import { allKeyboardLatSymbols } from 'helpers/AppHelpers';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import clsx from 'clsx';
import { getTotalWeight, originFieldNames } from '../helpers';
import TotalCount from './TotalCount';
import { AddDeliveryItemsPopup } from 'components/Popups';

const tableParameter = 'billOfLadingItems';

export default function DeliveryItemsTable() {
  const [openPopup, setOpenPopup] = useState(false);

  const { control, getValues, formState, clearErrors, setValue } = useFormContext();
  const { errors } = formState;
  const { fields, remove, append } = useFieldArray({
    control,
    name: tableParameter
  });

  const isTableInvalid = () => tableParameter in errors;
  const handleAddItemClick = () => setOpenPopup(true);

  const handleAddItems = (items) => {
    append(items);
    clearErrors(tableParameter);
    calculateTotal();
  };

  const isCreationViaPicklist = !!getValues('picklist')?.id;

  const calculateTotal = () => {
    if (formState?.touchedFields?.totalWeight) return;
    setValue('totalWeight', getTotalWeight(getValues(tableParameter)));
  };

  const validateOriginLocation = (index) => {
    const deletedItemLocationId = getValues(tableParameter)[index]?.originLocationId;
    if (getValues('originLocationItem')?.id === deletedItemLocationId) {
      originFieldNames.forEach((name) => setValue(name, ''));
      setValue('originLocationItem', {});
    }
  };

  const handleDeleteClick = (index) => {
    ['description', 'qty'].forEach((name) => clearErrors(`${tableParameter}[${index}].${name}`));
    isCreationViaPicklist && validateOriginLocation(index);
    remove(index);
    calculateTotal();
  };

  const getBoLType = () => {
    if (getValues('picklist')?.id) return 'PICKLIST';
    if (getValues('leavelist')?.id) return 'LEAVELIST';
    return 'CUSTOM';
  };

  const popupData = {
    tableState: getValues(tableParameter),
    bolType: getBoLType(),
    bolId: getValues('id') || null
  };

  return (
    <>
      {openPopup && (
        <AddDeliveryItemsPopup
          open={openPopup}
          setOpen={setOpenPopup}
          onAdd={handleAddItems}
          data={popupData}
        />
      )}

      {!!fields.length && (
        <div className={styles.table_wrapper}>
          <TableContainer className={styles.table} component={Paper}>
            <Table>
              <TableHeadComponent config={itemsForDeliveryTableHeadConfig} />
              <TableBody>
                {fields.map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell
                      className={clsx(
                        errors?.billOfLadingItems?.[index]?.description?.message && styles.error
                      )}>
                      <FormTextArea
                        name={`${tableParameter}[${index}].description`}
                        options={{
                          rule: allKeyboardLatSymbols,
                          max: 1000,
                          minRows: 1,
                          disableCounter: true,
                          focus: true,
                          disableError: true
                        }}
                        classes={styles.input__description}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      className={clsx(
                        errors?.billOfLadingItems?.[index]?.qty?.message && styles.error
                      )}>
                      <FormInputText
                        name={`${tableParameter}[${index}].qty`}
                        options={{
                          focus: true,
                          type: 'quantity',
                          disableError: true
                        }}
                        classes={styles.input__field}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormInputText
                        name={`${tableParameter}[${index}].weight`}
                        options={{
                          focus: true,
                          type: 'fraction',
                          disableError: true,
                          extraAction: calculateTotal
                        }}
                        classes={styles.input__field}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {fields.length !== 1 && (
                        <IconButton onClick={() => handleDeleteClick(index)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isTableInvalid() && <span className={styles.table_error}>Data is required</span>}
        </div>
      )}

      <div className={styles.tableFooter}>
        <div className={styles.tableFooter__left}>
          <Button className={styles.addItemButton} onClick={handleAddItemClick}>
            Add Item
          </Button>
          {isTableInvalid() && !fields.length && (
            <span className={styles.button_error}>Add at least one item</span>
          )}
        </div>

        {!!fields.length && <TotalCount />}
      </div>
    </>
  );
}
