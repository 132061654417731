import React, { useState } from 'react';
import styles from './Layout.module.scss';
import PreviewPopup from './PreviewPopup/PreviewPopup';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import { FILES_LIMIT } from '../../UploaderConfig';
import { Input } from '../InputComponent/InputComponent';
import { cloneObj } from 'helpers/AppHelpers';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

export default function Layout({ input, images, onChange, previews, dropzoneProps, index, limit }) {
  const [editorOpen, setEditorOpen] = useState(false);
  const [editorData, setEditorData] = useState(false);

  const filesLimit = limit || FILES_LIMIT;

  const handleDeleteClick = ({ id }) => {
    const filtered = images.filter((resource) => resource.id !== id);
    onChange(filtered, index);
  };

  const handleEditClick = (image) => {
    setEditorData(image);
    setEditorOpen(true);
  };

  const handleImageUpdate = (image) => {
    const newImagesArray = cloneObj(images);
    const updatedImageIndex = newImagesArray.findIndex(({ id }) => id === image.id);
    newImagesArray[updatedImageIndex] = image;
    onChange(newImagesArray);
  };

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <div
        className={clsx(
          styles.container,
          (!!images?.length || !!previews?.length) && styles.topSpace
        )}>
        {children}
      </div>
    );
  });

  const SortableItem = sortableElement(({ image }) => (
    <div className={styles.container__image}>
      <img
        src={image.link}
        className={clsx(styles.container__image_preview)}
        style={{ transform: `rotate(${image.rotationAngle}deg)` }}
        alt=""
      />
      <div className={styles.container__image_controls}>
        <IconButton className={styles.delete_button} onClick={() => handleDeleteClick(image)}>
          <CloseIcon />
        </IconButton>
        <IconButton className={styles.edit_button} onClick={() => handleEditClick(image)}>
          <CreateIcon />
        </IconButton>
      </div>
    </div>
  ));

  const dragEnd = ({ oldIndex, newIndex }) => {
    const result = Array.from(images);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);
    onChange(result);
  };

  return (
    <div className={styles.layoutWrapper}>
      <PreviewPopup
        open={editorOpen}
        setOpen={setEditorOpen}
        data={editorData}
        onSave={handleImageUpdate}
      />
      <div
        className={clsx(images.length >= filesLimit && styles.deprecatedInput)}
        {...dropzoneProps}>
        {images.length < filesLimit ? (
          input
        ) : (
          <>
            <label className={clsx(styles.inputLabel, styles.deprecatedInput)}>{Input}</label>
            <p>Upload limit exceeded</p>
          </>
        )}
      </div>

      <SortableContainer
        onSortEnd={dragEnd}
        axis="xy"
        distance={1}
        useWindowAsScrollContainer={true}>
        {!!images.length &&
          images.map((image, index) => <SortableItem key={image.id} index={index} image={image} />)}

        {previews}
      </SortableContainer>
    </div>
  );
}
