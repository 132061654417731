import React from 'react';
import styles from './styles.module.scss';

import ToggleBar from 'components/ToggleBars/ToggleBar';

import { TOGGLE_BUTTONS } from '../../helpers';

import { useBatchItemUpdatesActions, useBatchItemUpdatesSelector } from 'hooks/BatchItemUpdates';

export function Tabs() {
  const { selectedTab } = useBatchItemUpdatesSelector();
  const { setSelectedTabAction } = useBatchItemUpdatesActions();

  const clickTab = (tabValue) => tabValue && setSelectedTabAction(tabValue);

  return (
    <div className={styles.tabs}>
      <ToggleBar
        buttons={TOGGLE_BUTTONS}
        onChange={clickTab}
        selectedTab={selectedTab}
        classes={styles.tabs__bar}
      />
    </div>
  );
}
