export const tableHeadConfig = [
  { id: 2, label: 'Location' },
  { id: 3, label: 'Sublocation' },
  { id: 4, label: 'Contained In' },
  { id: 5, label: 'Note' },
  { id: 6, label: 'Status' },
  { id: 7, label: 'Min QTY' },
  { id: 8, label: 'BAA', footnote: '[1]' },
  { id: 9, label: 'Current QTY' },
  { id: 10, label: 'Location QTY' }
];
