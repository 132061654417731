import React from 'react';
import styles from './Footer.module.scss';

import MainButton from 'components/StyledComponents/MainButton';

import { useFormContext } from 'react-hook-form';
import { AT_LEAST_ONE_FIELD_ERROR_MESSAGE, AT_LEAST_ONE_FIELD_ERROR_NAME } from '../../../helpers';

export function Footer({ onUpdate }) {
  const { formState } = useFormContext();
  const { errors } = formState;

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;

  return (
    <div className={styles.footer}>
      <MainButton text="update" type="primary" action={onUpdate} isDisabled={isFormInvalid()} />
      {errors?.[AT_LEAST_ONE_FIELD_ERROR_NAME] && <span>{AT_LEAST_ONE_FIELD_ERROR_MESSAGE}</span>}
    </div>
  );
}
