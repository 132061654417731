import React, { useCallback, useEffect, useState } from 'react';
import styles from './AddColumnButton.module.scss';
import AddIcon from '@material-ui/icons/Add';
import AddColumnModal from 'components/AddColumnModal';
import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';
import { BlueButton } from 'components/Buttons';

export function AddColumnButton() {
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState([]);

  const { getLooseItemFieldsAction, setCustomColumnsAction } = useLooseItemActions();
  const { customColumns } = useLooseItemSelector();

  useEffect(() => {
    if (open) {
      getLooseItemFieldsAction({ showOnSummary: true }).then((res) => setFields(res));
    }
  }, [open]);

  const addColumns = useCallback(() => setOpen(true), [setOpen]);

  const saveColumns = useCallback(
    (selected) => setCustomColumnsAction(selected),
    [setCustomColumnsAction]
  );

  const shouldDisplaySeparator = (currentOption, options) => {
    const currentOptionIndex = options.findIndex(({ id }) => id === currentOption.id);
    const nextOption = options[currentOptionIndex + 1];
    return currentOption?.id < 0 && nextOption?.id > 0;
  };

  return (
    <>
      <BlueButton label="Add column" onClick={addColumns} className={styles.addColumnButton}>
        <AddIcon />
      </BlueButton>

      {open && !!fields?.length && (
        <AddColumnModal
          open={open}
          setOpen={setOpen}
          items={fields}
          onApply={saveColumns}
          selected={customColumns}
          separatorCondition={shouldDisplaySeparator}
        />
      )}
    </>
  );
}
