import React, { useEffect, useRef, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import {
  selectLeaveListAssetStatuses,
  selectLocationListData,
  selectSingleLeaveListData,
  selectStatusesData,
  selectTruckOrTrailers
} from '../selectors';
import {
  clearLeaveListState,
  deleteLeaveList,
  getSingleLeaveList,
  getAssets,
  getLeaveListAssetStatuses,
  updateLeaveList,
  getCsvResource,
  getLocations,
  getStatuses,
  searchTruckOrTrailer,
  checkBillOfLadingItems
} from 'actions/leaveListActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './LeaveListDetailed.module.scss';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  DELETE_ASSETS_FROM_THE_LEAVE_LIST,
  DELETE_ASSETS_FROM_THE_LEAVE_LIST_TYPE,
  DELETE_LEAVELIST,
  DELETE_LEAVELIST_ASSET,
  DELETE_LEAVELIST_ASSET_TYPE,
  LEAVELIST_COMPLETED_STATUS,
  LEAVELIST_COMPLETED_STATUS_TYPE
} from 'constants/dialogPopupsData';
import DialogPopup from 'components/DialogPopup/DialogPopup';
import {
  allKeyboardLatSymbols,
  enrichNameToId,
  emptyValue,
  wait,
  fileDownload
} from 'helpers/AppHelpers';
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import {
  ASSET_DETAILED_PATH,
  BILL_OF_LADING_DETAILS_PATH,
  LEAVELIST_SUMMARY_PATH,
  LOCATION_DETAILED_PATH,
  LOOSE_ITEM_DETAILED_PATH,
  MANAGE_LOOSE_ITEMS_PATH
} from 'constants/routeConstants';
import BasketIcon from 'assets/images/busketIcon.svg';
import CheckIcon from '@material-ui/icons/Check';
import SelectInput from 'components/SelectInput/SelectInput';
import RemoveIcon from 'assets/images/deleteIcon.svg';
import CloseIcon from '@material-ui/icons/Close';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import SearchInputWithTags from 'components/SearchInputWithTags/SearchInputWithTags';
import LeaveListDetailedEditor from './components/Editor/LeaveListDetailedEditor';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SortableBlock from 'components/SortableBlock/SortableBlock';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import LeavelistGeneralDetails from './components/LeavelistGeneralDetails';
import ChangeStatusButton from './components/ChangeStatusButton';
import clsx from 'clsx';
import { useUserConfig } from 'hooks/useUserConfig';
import { getGeneralDetailsData } from './components/helpers';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { AddLeavelistAssetPopup } from 'components/Popups';
import { useManageLooseItemsActions } from 'hooks/ManageLooseItems';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import csvIcon from 'assets/images/csvIcon.svg';
import MenuPopper from 'components/MenuPopper/MenuPopper';
import { MenuItem } from '@material-ui/core';
import HeaderButton from 'components/DetailsComponents/HeaderButton';
import CreateIcon from '@material-ui/icons/Create';

const LEAVELIST_STATUSES = {
  open: 'Open',
  delivery: 'In delivery',
  completed: 'Completed'
};

const RECEIVED_AT_YARD_STATUS = 'Received at Yard';
const RECEIVED_AT_JOB_SITE_STATUS = 'Received at Job Site';

function LeaveListDetailed({
  currentLeaveList,
  getSingleLeaveListAction,
  deleteLeaveListAction,
  getAssetsAction,
  leaveListAssetStatuses,
  getLeaveListAssetStatusesAction,
  updateLeaveListAction,
  locationList,
  getLocationsAction,
  statusList,
  getStatusListAction,
  truckOrTrailers,
  searchTruckOrTrailerAction,
  checkBillOfLadingItemsAction,
  getCsvResourceAction,
  clearStateAction
}) {
  const isMobile = useMobileViewport();
  const { id } = useParams();
  const history = useHistory();

  const anchorRef = useRef(null);

  const { setEntityDataAction } = useManageLooseItemsActions();

  const {
    isAdminUser,
    isOperationsManagerUser,
    isFieldTechUser,
    isEntityCreatorUser,
    isLocationInConfig
  } = useUserConfig();

  const locationId = +currentLeaveList?.location?.id;
  const createdByEmail = currentLeaveList?.createdByUser?.email;

  const isOwnLeavelist = () => isAdminUser || (!isAdminUser && isEntityCreatorUser(createdByEmail));
  const isStatus = (status) => currentLeaveList?.status?.displayName === status;

  const isStatusButtonAvailable = () => {
    if (isAdminUser) return true;
    else if (isOperationsManagerUser)
      return isEntityCreatorUser(createdByEmail) || isLocationInConfig(locationId);
    else return isEntityCreatorUser(createdByEmail);
  };
  const areBlocksAvailable = () => {
    if (isAdminUser) return true;
    else if (isOperationsManagerUser || isFieldTechUser)
      return isEntityCreatorUser(createdByEmail) || isLocationInConfig(locationId);
    else return isEntityCreatorUser(createdByEmail);
  };
  const isCellEditable = () => {
    if (isAdminUser) return true;
    else if (isOperationsManagerUser || isFieldTechUser)
      return isEntityCreatorUser(createdByEmail) || isLocationInConfig(locationId);
    else return isEntityCreatorUser(createdByEmail);
  };
  const isDeleteAvailable = () => {
    if (isAdminUser) return true;
    else if (isOperationsManagerUser)
      return isEntityCreatorUser(createdByEmail) || isLocationInConfig(locationId);
    else return isEntityCreatorUser(createdByEmail);
  };
  const isCreateBoLAvailable = () => {
    return (
      isAdminUser ||
      isOperationsManagerUser ||
      isFieldTechUser ||
      isEntityCreatorUser(createdByEmail)
    );
  };
  const isCheckboxAvailable = () => {
    return isCreateBoLAvailable() || isDeleteAvailable();
  };

  const [isDisabled, setDisabled] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [tableState, setTableState] = useState([]);
  const [key, setKey] = useState(2);
  const [openEditor, setOpenEditor] = useState(false);
  const [truckOrTrailerOptions, setTruckOrTrailerOptions] = useState([]);
  const [filter, setFilter] = useState({
    filters: { leavelistIds: [id] },
    sortRules: []
  });

  const { sortRules } = filter;

  const [notesEditArray, setNotesEditArray] = useState([]);
  const [statusEditArray, setStatusEditArray] = useState([]);
  const [locationEditArray, setLocationEditArray] = useState([]);
  const [sublocationEditArray, setSublocationEditArray] = useState([]);
  const [truckEditArray, setTruckEditArray] = useState([]);
  const [checkedArray, setCheckedArray] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);

  const [dialogModalData, setDialogModalData] = useState({
    isOpened: false
  });

  const getAssets = (inputFilter) =>
    getAssetsAction(inputFilter || filter).then((res) => {
      setTableState(res || []);
    });

  useEffect(() => {
    if (id) {
      getSingleLeaveListAction(id);
      getAssets(filter);
    }
  }, [id]);

  useEffect(() => {
    if (currentLeaveList) {
      setBreadcrumbs([{ name: currentLeaveList.name }]);
    }
  }, [currentLeaveList]);

  useEffect(() => {
    getLeaveListAssetStatusesAction();
  }, []);

  useEffect(() => {
    if (truckOrTrailers?.items?.length) {
      setTruckOrTrailerOptions(truckOrTrailers?.items);
    }
    return () => {
      setTruckOrTrailerOptions([]);
    };
  }, [truckOrTrailers]);

  useEffect(() => {
    getLocationsAction({ filters: { active: true, includeUserCheck: true } });
  }, []);

  useEffect(() => {
    getStatusListAction();
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const getFilteredArray = (array, removedIds, param) =>
    [...array].filter((el) => removedIds.indexOf(param === 'byElem' ? el.id : el) === -1);
  const filterArrays = (ids) => {
    setCheckedArray(getFilteredArray(checkedArray, ids, 'byId'));
    setStatusEditArray(getFilteredArray(statusEditArray, ids, 'byElem'));
    setNotesEditArray(getFilteredArray(notesEditArray, ids, 'byElem'));
  };

  const onAgree = () => {
    switch (dialogModalData.type) {
      case DELETE_LEAVELIST_ASSET_TYPE: {
        setDialogModalData({ isOpened: false });
        const arrayWithRowId = [dialogModalData.deletedRowId];
        updateLeaveListAction(
          {
            ...currentLeaveList,
            assets: [...getFilteredArray(tableState, arrayWithRowId, 'byElem')]
          },
          true,
          false
        ).then((res) => {
          if (res.id) {
            setTableState(res?.assets || []);
            filterArrays(arrayWithRowId);
          }
        });
        break;
      }
      case DELETE_ASSETS_FROM_THE_LEAVE_LIST_TYPE: {
        setDialogModalData({ isOpened: false });
        updateLeaveListAction(
          {
            ...currentLeaveList,
            assets: [...getFilteredArray(tableState, checkedArray, 'byElem')]
          },
          true,
          false
        ).then((res) => {
          if (res.id) {
            setTableState(res?.assets || []);
            filterArrays(checkedArray);
          }
        });
        break;
      }
      case LEAVELIST_COMPLETED_STATUS_TYPE: {
        setDialogModalData({ isOpened: false });
        updateLeaveListAction(
          {
            ...currentLeaveList,
            status: { ...statusList.find((el) => el.displayName === LEAVELIST_STATUSES.completed) },
            assets: [...tableState]
          },
          true,
          false
        ).then((res) => {
          if (res.id) {
            setTableState(res?.assets || []);
            filterArrays(checkedArray);
          }
        });
        break;
      }
      default:
        deleteLeaveListAction(currentLeaveList.id);
        break;
    }
  };

  const onDismiss = () => setDialogModalData({ isOpened: false });

  const onSortApply = (rowName) => {
    const currFilter = { ...filter, sortRules: rowName };
    setFilter(currFilter);
    getAssets(currFilter);
  };

  const updateAssetInRow = (row) => {
    const initArray = [...tableState];
    initArray[initArray.findIndex((item) => item.id === row.id)] = row;
    updateLeaveListAction(
      {
        ...currentLeaveList,
        assets: [...initArray]
      },
      true,
      false
    ).then((res) => {
      if (res.id) {
        setTableState(res?.assets || []);
      }
    });
  };

  const onNotesInput = (event, id) => {
    const notesArray = [...notesEditArray];
    const index = notesEditArray.findIndex((item) => item.id === id);
    let value = event.target.value;
    value = value.substr(0, 1000);
    value = value.replace(allKeyboardLatSymbols, '');
    notesArray[index].value = value;
    setNotesEditArray(notesArray);
  };

  const handleNotesEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        notes: notesEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (notesEditArray.find((item) => item.id === row.id)) {
      setNotesEditArray(notesEditArray.filter((item) => item.id !== row.id));
    } else {
      setNotesEditArray([...notesEditArray, { id: row.id, value: row.notes }]);
    }
  };

  const handleSublocationEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        sublocation: sublocationEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (param === 'decline') {
      const asset = { ...row, sublocation: null };
      updateAssetInRow(asset);
    }
    if (sublocationEditArray.find((item) => item.id === row.id)) {
      setSublocationEditArray(sublocationEditArray.filter((item) => item.id !== row.id));
    } else {
      setSublocationEditArray([...sublocationEditArray, { id: row.id, value: row?.sublocation }]);
    }
  };

  const handleLocationEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        location: locationEditArray.find((item) => item.id === row.id)?.value,
        sublocation: null
      };
      updateAssetInRow(asset);
    }
    if (param === 'decline') {
      const asset = { ...row, location: null, sublocation: null };
      updateAssetInRow(asset);
    }
    if (locationEditArray.find((item) => item.id === row.id)) {
      setLocationEditArray(locationEditArray.filter((item) => item.id !== row.id));
    } else {
      setLocationEditArray([...locationEditArray, { id: row.id, value: row.location }]);
    }
  };

  const handleAllCheckbox = () => {
    if (checkedArray.length) {
      setCheckedArray([]);
    } else {
      setCheckedArray([...tableState.map(({ id }) => id)]);
    }
  };

  const handleCheckbox = (e, rowId) => {
    let newCheckedArray = [...checkedArray];
    if (e.target.checked) {
      newCheckedArray.push(rowId);
    } else {
      newCheckedArray = newCheckedArray.filter((id) => id !== rowId);
    }
    setCheckedArray(newCheckedArray);
  };

  const onRemoveRow = (rowId) =>
    setDialogModalData({ ...DELETE_LEAVELIST_ASSET, isOpened: true, deletedRowId: rowId });

  const onStatusSelect = (data) => {
    const statusArray = [...statusEditArray];
    const index = statusEditArray.findIndex((item) => item.id === data.name);
    statusArray[index].value = data.value;
    setStatusEditArray(statusArray);
  };

  const onLocationSelect = (name, value) => {
    const locationArray = [...locationEditArray];
    const index = locationEditArray.findIndex((item) => item.id === name);
    locationArray[index].value = value;
    setLocationEditArray(locationArray);
  };

  const onSublocationSelect = (_, value, id) => {
    const sublocationArray = [...sublocationEditArray];
    const i = sublocationArray.findIndex((item) => item.id === id);
    sublocationArray[i].value = value;
    setSublocationEditArray(sublocationArray);
  };

  const onTruckSelect = (value, rowId) => {
    const truckArray = [...truckEditArray];
    const index = truckEditArray.findIndex((item) => item.id === rowId);
    truckArray[index].value = value;
    setTruckEditArray(truckArray);
    setKey(new Date().getTime() + Math.random());
  };

  const handleStatusEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        status: statusEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (statusEditArray.find((item) => item.id === row.id)) {
      setStatusEditArray(statusEditArray.filter((item) => item.id !== row.id));
    } else {
      setStatusEditArray([...statusEditArray, { id: row.id, value: row.status }]);
    }
  };

  const handleTruckEditUpdate = (row, param) => {
    if (param === 'accept') {
      const asset = {
        ...row,
        truck: truckEditArray.find((item) => item.id === row.id)?.value
      };
      updateAssetInRow(asset);
    }
    if (param === 'decline') {
      const asset = { ...row, truck: null };
      updateAssetInRow(asset);
    }
    if (truckEditArray.find((item) => item.id === row.id)) {
      setTruckEditArray(truckEditArray.filter((item) => item.id !== row.id));
    } else {
      setTruckEditArray([...truckEditArray, { id: row.id, value: row.truck }]);
    }
  };

  const onApply = (data) => {
    const getLocation = (el) =>
      data?.location?.id
        ? shouldDisableCell(el)
          ? el?.location || null
          : data.location
        : el?.location || null;

    const getStatus = (el) => (data?.status?.name ? data.status : el.status);

    const newArray = [...tableState].map((el) =>
      checkedArray.includes(el.id)
        ? {
            ...el,
            status: getStatus(el),
            location: getLocation(el),
            sublocation: data?.location?.id ? null : el?.sublocation || null
          }
        : { ...el }
    );
    updateLeaveListAction({ ...currentLeaveList, assets: [...newArray] }, true).then((res) => {
      if (res.id) {
        setTableState(res?.assets || []);
        setStatusEditArray(
          [...statusEditArray].map((el) =>
            checkedArray.indexOf(el.id) === -1
              ? el
              : { ...el, value: data?.status?.name === '' ? el.status : data.status }
          )
        );
        setLocationEditArray(
          [...locationEditArray].map((el) =>
            checkedArray.indexOf(el.id) === -1
              ? el
              : { ...el, value: data?.location?.id ? el.location : data.location }
          )
        );
      }
    });
  };

  const onDeleteSelected = () =>
    setDialogModalData({ ...DELETE_ASSETS_FROM_THE_LEAVE_LIST, isOpened: true });

  const enrichLocationOptions = (options, location) => {
    if (options?.length && location?.id && !options.map(({ id }) => id).includes(location.id)) {
      return options.concat({ ...location }).sort((a, b) => a.id > b.id);
    } else return options;
  };

  const onChangeStatus = () => {
    if (currentLeaveList?.status?.displayName === LEAVELIST_STATUSES.open) {
      updateLeaveListAction(
        {
          ...currentLeaveList,
          status: { ...statusList.find((el) => el.displayName === LEAVELIST_STATUSES.delivery) },
          assets: [...tableState]
        },
        true,
        false
      ).then((res) => {
        if (res.id) {
          setTableState(res?.assets || []);
          filterArrays(checkedArray);
        }
      });
    } else {
      setDialogModalData({ ...LEAVELIST_COMPLETED_STATUS, isOpened: true });
    }
  };

  const shouldDisableCell = (row) =>
    row?.status?.displayName === RECEIVED_AT_JOB_SITE_STATUS ||
    row?.status?.displayName === RECEIVED_AT_YARD_STATUS;

  const onTruckOrTrailerFilterChange = (searchQuery) => {
    const value = searchQuery ? searchQuery.replace(allKeyboardLatSymbols, '') : '';
    if (value !== '') {
      const currFilter = {
        pagination: { limit: 39, page: 1 },
        filters: {
          isDeleted: false,
          includeThirdPartyTruck: true,
          equipmentIds: [9, 10],
          searchQuery
        }
      };
      searchTruckOrTrailerAction(currFilter);
    }
  };

  const getSublocations = (locationId) =>
    locationList?.find(({ id }) => id === locationId)?.sublocations || [];

  const handleAddAssetsClick = () => setOpenPopup(true);

  const handleAddLooseItemsClick = (row) => {
    const locationLooseItemDetails = row?.locationLooseItem?.id
      ? {
          id: row.locationLooseItem.id,
          moveQuantity: row?.moveQuantity || 0,
          moveByAheadAccount: row?.moveByAheadAccount || 0,
          leavelistAssetId: row?.id
        }
      : {};

    setEntityDataAction({
      locationLooseItemDetails,
      activeEntity: currentLeaveList,
      isPicklist: false
    });
    history.push(MANAGE_LOOSE_ITEMS_PATH);
  };

  const handleCreateBOLClick = () => {
    checkBillOfLadingItemsAction({ leavelistAssetIds: checkedArray });
  };

  const handleMenuClick = () => setOpenMenu(!openMenu);

  const downloadCsv = () => {
    getCsvResourceAction(filter).then((file) => file?.link && fileDownload(file));
    setDisabled(true);
    wait(15000).then(() => setDisabled(false));
  };

  return (
    <>
      <section className={styles.pageContainer}>
        <DialogPopup data={dialogModalData} onAgree={onAgree} onDissmiss={onDismiss} />
        {openPopup && (
          <AddLeavelistAssetPopup
            open={openPopup}
            setOpen={setOpenPopup}
            leavelist={{ id: currentLeaveList.id, name: currentLeaveList.name }}
            onSuccess={getAssets}
          />
        )}
        {currentLeaveList?.id === +id && (
          <div>
            {!isMobile && <BreadcrumbsNav itemsArray={breadcrumbs} />}
            <section className={styles.headerBlock}>
              {!isMobile && <h1>{currentLeaveList.name}</h1>}
              <div className={styles.headerBlock__controls}>
                {isMobile && isStatus(LEAVELIST_STATUSES.open) && isOwnLeavelist() && (
                  <HeaderButton
                    onClick={() => setDialogModalData({ ...DELETE_LEAVELIST, isOpened: true })}>
                    <DeleteIcon />
                  </HeaderButton>
                )}
                {isMobile && (
                  <HeaderButton onClick={() => history.push(LEAVELIST_SUMMARY_PATH)}>
                    <ChevronLeftIcon />
                  </HeaderButton>
                )}
                {!isMobile && (
                  <div className={styles.controls}>
                    {!isStatus(LEAVELIST_STATUSES.completed) && (
                      <Button className={styles.add_button} onClick={handleAddAssetsClick}>
                        <span>Add assets</span>
                      </Button>
                    )}
                    {!isStatus(LEAVELIST_STATUSES.completed) && areBlocksAvailable() && (
                      <Button className={styles.add_button} onClick={handleAddLooseItemsClick}>
                        <span>Add loose items</span>
                      </Button>
                    )}
                    {isStatusButtonAvailable() && (
                      <ChangeStatusButton
                        isStatus={isStatus}
                        onChangeStatus={onChangeStatus}
                        statuses={LEAVELIST_STATUSES}
                      />
                    )}

                    {((isStatus(LEAVELIST_STATUSES.open) && isOwnLeavelist()) ||
                      areBlocksAvailable()) && (
                      <>
                        <Button
                          ref={anchorRef}
                          className={styles.actions}
                          onClick={handleMenuClick}>
                          <MoreHorizIcon />
                        </Button>
                        <MenuPopper
                          open={openMenu}
                          setOpen={setOpenMenu}
                          anchorRef={anchorRef}
                          classes={styles.menu}
                          placement="bottom-end">
                          {isStatus(LEAVELIST_STATUSES.open) && isOwnLeavelist() && (
                            <MenuItem
                              onClick={() =>
                                setDialogModalData({ ...DELETE_LEAVELIST, isOpened: true })
                              }>
                              <DeleteIcon />
                              Remove leavelist
                            </MenuItem>
                          )}
                          {areBlocksAvailable() && (
                            <MenuItem
                              onClick={downloadCsv}
                              className={clsx(isDisabled && styles.disabled)}>
                              <img src={csvIcon} alt="" />
                              Download CSV file
                            </MenuItem>
                          )}
                        </MenuPopper>
                      </>
                    )}
                  </div>
                )}
              </div>
            </section>
            <section className={styles.formWrapper}>
              {isMobile && <h1>{currentLeaveList.name}</h1>}
              {isMobile && (
                <div className={styles.controls}>
                  {!isStatus(LEAVELIST_STATUSES.completed) && (
                    <Button className={styles.add_button} onClick={handleAddAssetsClick}>
                      <span>Add assets</span>
                    </Button>
                  )}
                  {!isStatus(LEAVELIST_STATUSES.completed) && areBlocksAvailable() && (
                    <Button className={styles.add_button} onClick={handleAddLooseItemsClick}>
                      <span>Add loose items</span>
                    </Button>
                  )}
                  {isStatusButtonAvailable() && (
                    <ChangeStatusButton
                      isStatus={isStatus}
                      onChangeStatus={onChangeStatus}
                      statuses={LEAVELIST_STATUSES}
                    />
                  )}
                </div>
              )}
              <LeavelistGeneralDetails
                isMobile={isMobile}
                isStatus={isStatus}
                generalDetailsData={getGeneralDetailsData(currentLeaveList)}
              />
            </section>
            {!!checkedArray.length && (
              <section className={styles.tableHelpers}>
                <LeaveListDetailedEditor
                  open={openEditor}
                  setOpen={setOpenEditor}
                  onApply={onApply}
                  isMobile={isMobile}
                  isOpenStatus={isStatus(LEAVELIST_STATUSES.open)}
                />
                <div className={styles.tableHelpers__controls}>
                  {isCreateBoLAvailable() && (
                    <Button className={styles.create_bol_button} onClick={handleCreateBOLClick}>
                      <span>Create Bill of Lading</span>
                    </Button>
                  )}
                  {isDeleteAvailable() && (
                    <Button className={styles.delete_button} onClick={onDeleteSelected}>
                      <span>Delete All</span>
                    </Button>
                  )}
                </div>
              </section>
            )}

            {!(isMobile && openEditor) && (
              <TableContainer className={styles.tableContainer} component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {!!tableState.length && isCheckboxAvailable() && (
                        <StyledTableCell className={styles.centered}>
                          <Checkbox
                            classes={{ root: styles.checkbox, checked: styles.checked }}
                            onChange={handleAllCheckbox}
                            value={'selectAll'}
                            checked={checkedArray.length === tableState.length}
                          />
                        </StyledTableCell>
                      )}
                      <StyledTableCell>
                        {!isStatus(LEAVELIST_STATUSES.open) ? (
                          <SortableBlock
                            label="DR-ID or Loose Item(QTY)"
                            parameter="asset.drCode"
                            sortRules={sortRules}
                            onSortApply={onSortApply}
                          />
                        ) : (
                          'DR-ID or Loose Item(QTY)'
                        )}
                      </StyledTableCell>
                      <StyledTableCell>Leavelist Notes</StyledTableCell>
                      <StyledTableCell>
                        {!isStatus(LEAVELIST_STATUSES.open) ? (
                          <SortableBlock
                            label="Destination"
                            parameter="location.siteCode"
                            sortRules={sortRules}
                            onSortApply={onSortApply}
                          />
                        ) : (
                          'Destination'
                        )}
                      </StyledTableCell>
                      <StyledTableCell>Sublocation</StyledTableCell>
                      <StyledTableCell>
                        {!isStatus(LEAVELIST_STATUSES.open) ? (
                          <SortableBlock
                            label="Status of item"
                            parameter="status.name"
                            sortRules={sortRules}
                            onSortApply={onSortApply}
                          />
                        ) : (
                          'Status of item'
                        )}
                      </StyledTableCell>
                      <StyledTableCell>Freight Bill</StyledTableCell>
                      <StyledTableCell>
                        {!isStatus(LEAVELIST_STATUSES.open) ? (
                          <SortableBlock
                            label="Truck or Trailer"
                            parameter="truck.drCode"
                            sortRules={sortRules}
                            onSortApply={onSortApply}
                          />
                        ) : (
                          'Truck or Trailer'
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{emptyValue}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableState?.length ? (
                      tableState.map((row) => (
                        <StyledTableRow key={row.id}>
                          {isCheckboxAvailable() && (
                            <StyledTableCell className={styles.checkboxCell}>
                              <Checkbox
                                classes={{ root: styles.checkbox, checked: styles.checked }}
                                onChange={(e) => handleCheckbox(e, row.id)}
                                value={row.id}
                                checked={!!checkedArray?.find((id) => id === row.id)}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell className={styles.idCell}>
                            <LinkComponent
                              path={
                                row?.locationLooseItem?.id
                                  ? `${LOOSE_ITEM_DETAILED_PATH}/${row.locationLooseItem.looseItem.id}`
                                  : `${ASSET_DETAILED_PATH}/${row.asset.id}`
                              }
                              name={row?.itemName || ''}
                              isRedirectAvailable={
                                row?.locationLooseItem?.id ? true : !!row?.asset?.id
                              }
                            />
                            {!!row?.locationLooseItem?.id && (
                              <span>{' (' + (row.moveQuantity || 0) + ') '}</span>
                            )}
                            {!!(
                              row?.status?.displayName !== RECEIVED_AT_YARD_STATUS &&
                              row?.status?.displayName !== RECEIVED_AT_JOB_SITE_STATUS &&
                              row?.locationLooseItem?.id &&
                              areBlocksAvailable()
                            ) && (
                              <IconButton
                                className={styles.pencil}
                                onClick={() => handleAddLooseItemsClick(row)}>
                                <CreateIcon />
                              </IconButton>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className={styles.notesCell}>
                            {!notesEditArray.find((item) => item.id === row.id) ? (
                              <section
                                className={clsx(
                                  styles.notesBlock,
                                  isCellEditable() && styles.pointer
                                )}
                                onClick={() => isCellEditable() && handleNotesEditUpdate(row)}>
                                {row?.notes || '\u2000'}
                              </section>
                            ) : (
                              <section className={styles.notesBlock}>
                                <TextField
                                  name={row.id}
                                  className={styles.textInput}
                                  variant="outlined"
                                  value={
                                    notesEditArray?.find((item) => item.id === row.id)?.value || ''
                                  }
                                  onChange={(event) => onNotesInput(event, row.id)}
                                />
                                <div className={styles.helpers}>
                                  <Button
                                    onClick={() => handleNotesEditUpdate(row, 'decline')}
                                    className={styles.alert}>
                                    <CloseIcon />
                                  </Button>
                                  <Button
                                    onClick={() => handleNotesEditUpdate(row, 'accept')}
                                    className={styles.success}>
                                    <CheckIcon />
                                  </Button>
                                </div>
                              </section>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className={styles.locationCell}>
                            {!locationEditArray.find((item) => item.id === row.id) ? (
                              <section
                                className={clsx(
                                  styles.locationBlock,
                                  isCellEditable() && !shouldDisableCell(row) && styles.pointer
                                )}
                                onClick={() => {
                                  !shouldDisableCell(row) &&
                                    isCellEditable() &&
                                    handleLocationEditUpdate(row);
                                }}>
                                <LinkComponent
                                  name={row.location?.siteCode || ''}
                                  path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                                  isRedirectAvailable={
                                    !(row.location?.deleted || row.location?.id === 0)
                                  }
                                />
                              </section>
                            ) : (
                              <section className={styles.locationBlock}>
                                <InputSearchWithMultiselect
                                  name={row.id}
                                  defaultValue={
                                    locationEditArray?.find((item) => item.id === row.id)?.value
                                      .id || ''
                                  }
                                  options={enrichLocationOptions(locationList, row.location) || []}
                                  onFilterSelect={onLocationSelect}
                                  multiselect={false}
                                  specialView={true}
                                />

                                <div className={styles.helpers}>
                                  <Button
                                    onClick={() => handleLocationEditUpdate(row, 'decline')}
                                    className={styles.alert}>
                                    <img src={BasketIcon} alt="basket-icon" />
                                  </Button>
                                  <Button
                                    onClick={() => handleLocationEditUpdate(row, 'accept')}
                                    className={styles.success}>
                                    <CheckIcon />
                                  </Button>
                                </div>
                              </section>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className={styles.sublocationCell}>
                            {!sublocationEditArray.find((item) => item.id === row.id) ? (
                              <section
                                className={clsx(
                                  styles.sublocationBlock,
                                  isCellEditable() &&
                                    !shouldDisableCell(row) &&
                                    row?.location?.id &&
                                    styles.pointer
                                )}
                                onClick={() => {
                                  !shouldDisableCell(row) &&
                                    isCellEditable() &&
                                    row?.location?.id &&
                                    handleSublocationEditUpdate(row);
                                }}>
                                {row?.sublocation?.name || ''}
                              </section>
                            ) : (
                              <section className={styles.sublocationBlock}>
                                <InputSearchWithMultiselect
                                  name="sublocation"
                                  defaultValue={
                                    sublocationEditArray?.find((item) => item.id === row.id)?.value
                                      .id || ''
                                  }
                                  options={getSublocations(row?.location?.id) || []}
                                  onFilterSelect={onSublocationSelect}
                                  multiselect={false}
                                  specialView={true}
                                  index={row.id}
                                />

                                <div className={styles.helpers}>
                                  <Button
                                    onClick={() => handleSublocationEditUpdate(row, 'decline')}
                                    className={styles.alert}>
                                    <img src={BasketIcon} alt="basket-icon" />
                                  </Button>
                                  <Button
                                    onClick={() => handleSublocationEditUpdate(row, 'accept')}
                                    className={styles.success}>
                                    <CheckIcon />
                                  </Button>
                                </div>
                              </section>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className={styles.statusCell}>
                            {!statusEditArray?.find((item) => item.id === row.id) ? (
                              <section
                                className={clsx(
                                  styles.statusBlock,
                                  !isStatus(LEAVELIST_STATUSES.open) &&
                                    row?.status?.displayName !== RECEIVED_AT_YARD_STATUS &&
                                    row?.status?.displayName !== RECEIVED_AT_JOB_SITE_STATUS &&
                                    isCellEditable() &&
                                    styles.pointer
                                )}
                                onClick={() => {
                                  !isStatus(LEAVELIST_STATUSES.open) &&
                                    isCellEditable() &&
                                    row?.status?.displayName !== RECEIVED_AT_YARD_STATUS &&
                                    row?.status?.displayName !== RECEIVED_AT_JOB_SITE_STATUS &&
                                    handleStatusEditUpdate(row);
                                }}>
                                {row?.status?.displayName || ''}
                              </section>
                            ) : (
                              <section className={styles.statusBlock}>
                                <SelectInput
                                  name={row.id}
                                  value={
                                    statusEditArray?.find((item) => item.id === row.id)?.value
                                      .name || ''
                                  }
                                  menuItems={enrichNameToId(leaveListAssetStatuses) || []}
                                  onSelect={(value) => onStatusSelect(value)}
                                />

                                <div className={styles.helpers}>
                                  <Button
                                    onClick={() => handleStatusEditUpdate(row, 'decline')}
                                    className={styles.alert}>
                                    <CloseIcon />
                                  </Button>
                                  <Button
                                    onClick={() => handleStatusEditUpdate(row, 'accept')}
                                    className={styles.success}>
                                    <CheckIcon />
                                  </Button>
                                </div>
                              </section>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className={styles.freightBillCell}>
                            <LinkComponent
                              path={`${BILL_OF_LADING_DETAILS_PATH}/${row?.billOfLading?.id}`}
                              name={row?.billOfLading?.freightBill || ''}
                            />
                          </StyledTableCell>
                          <StyledTableCell className={styles.truckCell}>
                            {!truckEditArray?.find((item) => item.id === row.id) ? (
                              <section
                                className={clsx(
                                  styles.truckBlock,
                                  isCellEditable() && styles.pointer
                                )}
                                onClick={() => isCellEditable() && handleTruckEditUpdate(row)}>
                                <LinkComponent
                                  name={row?.truck?.drCode || ''}
                                  path={`${ASSET_DETAILED_PATH}/${row.truck.id}`}
                                  isRedirectAvailable={row.truck?.id && row.truck?.id !== -1}
                                />
                              </section>
                            ) : (
                              <section className={styles.truckBlock}>
                                <SearchInputWithTags
                                  displayName="drCode"
                                  defaultInputValue={
                                    truckEditArray?.find((item) => item.id === row.id)?.value || {}
                                  }
                                  options={truckOrTrailerOptions}
                                  multiple={false}
                                  onInputChange={onTruckOrTrailerFilterChange}
                                  onSelect={onTruckSelect}
                                  index={row.id}
                                  key={key}
                                  specialView={true}
                                />

                                <div className={styles.helpers}>
                                  <Button
                                    onClick={() => handleTruckEditUpdate(row, 'decline')}
                                    className={styles.alert}>
                                    <img src={BasketIcon} alt="basket-icon" />
                                  </Button>
                                  <Button
                                    onClick={() => handleTruckEditUpdate(row, 'accept')}
                                    className={styles.success}>
                                    <CheckIcon />
                                  </Button>
                                </div>
                              </section>
                            )}
                          </StyledTableCell>
                          <StyledTableCell className={styles.lastCell}>
                            {!shouldDisableCell(row) && isDeleteAvailable() && (
                              <div
                                className={styles.imageBlock}
                                onClick={() => onRemoveRow(row.id)}>
                                <img src={RemoveIcon} alt="remove" />
                              </div>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={10} className={styles.emptyResponseTable}>
                          Add assets to the leavelist.
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
      </section>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  currentLeaveList: selectSingleLeaveListData(),
  leaveListAssetStatuses: selectLeaveListAssetStatuses(),
  statusList: selectStatusesData(),
  truckOrTrailers: selectTruckOrTrailers(),
  locationList: selectLocationListData()
});

const mapDispatchToProps = {
  getSingleLeaveListAction: getSingleLeaveList,
  deleteLeaveListAction: deleteLeaveList,
  getAssetsAction: getAssets,
  getStatusListAction: getStatuses,
  getLeaveListAssetStatusesAction: getLeaveListAssetStatuses,
  updateLeaveListAction: updateLeaveList,
  getLocationsAction: getLocations,
  searchTruckOrTrailerAction: searchTruckOrTrailer,
  checkBillOfLadingItemsAction: checkBillOfLadingItems,
  getCsvResourceAction: getCsvResource,
  clearStateAction: clearLeaveListState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LeaveListDetailed);
