import api from '../api';
import {
  SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA,
  SET_LOOSE_ITEM_DASHBOARD_CONFIG,
  CLEAR_LOOSE_ITEM_DASHBOARD_STATE
} from 'constants/reduceConstants';

export const getLooseItemDashboardConfig = () => (dispatch) => {
  return api.looseItemDashboard
    .getLooseItemDashboardConfigApi()
    .then((res) => {
      dispatch({ type: SET_LOOSE_ITEM_DASHBOARD_CONFIG, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getLooseItemDashboardTablesData = (query) => (dispatch) => {
  return api.looseItemDashboard
    .getLooseItemDashboardTablesDataApi(query)
    .then((res) => {
      dispatch({ type: SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const updateLooseItemDashboardConfig = (data) => () =>
  api.looseItemDashboard
    .updateLooseItemDashboardConfigApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));

export const clearLooseItemDashboardData = () => (dispatch) => {
  dispatch({ type: CLEAR_LOOSE_ITEM_DASHBOARD_STATE });
};
