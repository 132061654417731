import { useDispatch } from 'react-redux';

import {
  getLooseItems,
  getCategories,
  getCustomFields,
  updateLooseItems,
  clearState,
  setSelectedTab,
  setCreationForm,
  getLooseItemsLocations,
  getLocationsWithSublocations,
  moveLooseItems
} from 'actions/batchItemUpdatesActions';

export function useBatchItemUpdatesActions() {
  const dispatch = useDispatch();

  const setCreationFormAction = (data) => dispatch(setCreationForm(data));
  const getLooseItemsLocationsAction = (q) => dispatch(getLooseItemsLocations(q));
  const getCreationFormAction = () =>
    Promise.all([
      dispatch(getLooseItems()),
      dispatch(getCategories()),
      dispatch(getCustomFields()),
      dispatch(getLocationsWithSublocations({ filters: { active: true, includeUserCheck: true } }))
    ]).then(([looseItems, categories, customFields, locationsWithSublocations]) => {
      const criteria = {
        looseItems,
        categories,
        customFields,
        locationsWithSublocations,
        looseItemLocations: []
      };
      setCreationFormAction(criteria);
      return criteria;
    });

  const setSelectedTabAction = (tab) => dispatch(setSelectedTab(tab));

  const updateLooseItemsAction = (data) => dispatch(updateLooseItems(data));

  const moveLooseItemsAction = (data) => dispatch(moveLooseItems(data));

  const clearStateAction = () => dispatch(clearState());

  return {
    getLooseItemsLocationsAction,
    setCreationFormAction,
    getCreationFormAction,
    setSelectedTabAction,
    updateLooseItemsAction,
    moveLooseItemsAction,
    clearStateAction
  };
}
