import React from 'react';

import { Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import InputSearchWithMultiselect from 'components/InputSearchWithMultiselect/InputSearchWithMultiselect';
import SelectInput from 'components/SelectInput/SelectInput';

import { ASSET_DETAILED_PATH, LOCATION_DETAILED_PATH } from 'constants/routeConstants';

import { isTeamMemberUser } from 'helpers/AppHelpers';

import { enrichLocationOptions, getSublocations } from '../../utils/locations';

import styles from './AssetsTable.module.scss';

const InAssemblyStatus = 'InAssembly';
const AssembledStatus = 'Assembled';
const DisassembledStatus = 'Disassembled';
const ClosedStatus = 'Closed';

const AssetsTable = ({
  assetsTable,
  setAssetsTable,
  values,
  setValues,
  locationList,
  isStatus,
  userRole,
  isOwnAssembly
}) => {
  const isFirstTableView = () => isStatus(InAssemblyStatus) || isStatus(AssembledStatus);

  const onSelect = (name, value, index) => {
    const newArray = [...assetsTable];
    newArray[index][name] = value;
    if (name === 'newLocation') {
      newArray[index].newSublocation = null;
    }
    setAssetsTable(newArray);
    if (values.active) setValues({ active: false, isDisabled: false });
  };

  const onSublocationSelect = ({ name, value }, index) => {
    const newArray = [...assetsTable];
    newArray[index][name] = value;
    setAssetsTable(newArray);
  };

  const getLocationName = (row) => {
    return `${row?.location?.siteCode} ${
      row?.sublocation?.name ? `(${row?.sublocation?.name})` : ''
    }`;
  };

  const isFirstNameCell = (index) => {
    return !(index !== 0 && assetsTable[index]?.prefixId === assetsTable[index - 1]?.prefixId);
  };

  const getCellNumber = (rowId) => {
    return assetsTable.filter(({ prefixId }) => prefixId === rowId)?.length;
  };

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Prefix</StyledTableCell>
            <StyledTableCell>
              {!isFirstTableView() ? 'Released QTY' : 'Required QTY'}
            </StyledTableCell>
            <StyledTableCell>Assets</StyledTableCell>
            <StyledTableCell>Point of origin (Sublocation)</StyledTableCell>
            {!isFirstTableView() && (
              <>
                <StyledTableCell>Point of destination</StyledTableCell>
                <StyledTableCell>Sublocation</StyledTableCell>
              </>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {assetsTable.map((row, index) => (
            <StyledTableRow key={row.assetId}>
              {isFirstNameCell(index) && (
                <>
                  <StyledTableCell
                    rowSpan={getCellNumber(row.prefixId)}
                    className={styles.prefixCell}>
                    {row.prefix}
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={getCellNumber(row.prefixId)}
                    className={styles.quantityCell}>
                    {row.requiredQuantity}
                  </StyledTableCell>
                </>
              )}
              <StyledTableCell className={styles.assetCell}>
                <LinkComponent path={`${ASSET_DETAILED_PATH}/${row.assetId}`} name={row.drCode} />
              </StyledTableCell>
              <StyledTableCell className={styles.locationCell}>
                {isStatus(AssembledStatus) ||
                isStatus(DisassembledStatus) ||
                isStatus(ClosedStatus) ? (
                  <>{row?.pointOfOrigin}</>
                ) : (
                  <LinkComponent
                    path={`${LOCATION_DETAILED_PATH}/${row?.location?.id}`}
                    name={getLocationName(row)}
                  />
                )}
              </StyledTableCell>
              {!isFirstTableView() && (
                <>
                  <StyledTableCell className={styles.destinationCell}>
                    {isStatus(DisassembledStatus) &&
                    !isTeamMemberUser(userRole) &&
                    isOwnAssembly() ? (
                      <InputSearchWithMultiselect
                        name="newLocation"
                        defaultValue={row?.newLocation?.id || ''}
                        options={enrichLocationOptions(locationList, row?.newLocation) || []}
                        onFilterSelect={onSelect}
                        multiselect={false}
                        tableView
                        index={index}
                      />
                    ) : (
                      <>{row?.location?.siteCode || ''}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={styles.sublocationCell}>
                    {isStatus(DisassembledStatus) &&
                    !isTeamMemberUser(userRole) &&
                    isOwnAssembly() ? (
                      <SelectInput
                        clearable
                        name="newSublocation"
                        value={row?.newSublocation?.id || ''}
                        menuItems={getSublocations(locationList, row?.newLocation?.id)}
                        onSelect={onSublocationSelect}
                        tableView
                        index={index}
                      />
                    ) : (
                      <>{row?.newSublocation?.name || ''}</>
                    )}
                  </StyledTableCell>
                </>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetsTable;
