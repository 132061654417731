import React from 'react';
import styles from './FilterButton.module.scss';

import { IconButton } from '@material-ui/core';

import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';

import clsx from 'clsx';

export default function FilterButton({ onClick, isActive, icon }) {
  return (
    <IconButton className={clsx(styles.filter, isActive && styles.active)} onClick={onClick}>
      {icon || <FilterIcon />}
    </IconButton>
  );
}
